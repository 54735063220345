import React, { useMemo } from 'react';
import { constants } from '../../../constants';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { ChartGrouping, ChartTypes, ChartUnits } from '../../../types/dashboard/AmrChart';
import { DropDownList } from '../../controls/DropDownList';
import { FilterPanel, FilterSection, FilterSwitch } from '../../filters';

export interface UsBslEuCompliantDealsChartFilter {
    transactionType: TransactionType | string;
    units: ChartUnits;
    grouping: ChartGrouping;
}

interface UsBslEuCompliantDealsChartFilterProps extends UsBslEuCompliantDealsChartFilter {
    onChange: (value: UsBslEuCompliantDealsChartFilter) => void;
}

export function UsBslEuCompliantDealsFilter({
    onChange,
    ...values
}: UsBslEuCompliantDealsChartFilterProps) {
    const handleValueChange = (prop: keyof UsBslEuCompliantDealsChartFilter) => (value: any) => {
        onChange({
            ...values,
            [prop]: value,
        });
    };

    const chartTypes = useMemo(() => {
        const all = {
            value: constants.allListItem,
            text: 'All',
            selected: values.transactionType === constants.allListItem,
        };

        return [
            all,
            ...ChartTypes.map(({ value, text }) => ({
                value,
                text,
                selected: values.transactionType === value,
            })),
        ];
    }, [values.transactionType]);

    return (
        <FilterPanel>
            <FilterSection>
                <DropDownList
                    items={chartTypes}
                    onChange={({ value }) => handleValueChange('transactionType')(value)}
                    className="custom-drop-down-ghost"
                    prefix="Type: "
                />
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[ChartUnits.Volume, ChartUnits.Count].map(unit =>
                        <FilterSwitch
                            key={unit}
                            caption={unit}
                            selected={values.units === unit}
                            disabled={false}
                            onClick={() => handleValueChange('units')(unit)}
                        />
                    )}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[
                        ChartGrouping.M,
                        ChartGrouping.Q,
                        ChartGrouping.Y,
                    ].map(grouping =>
                        <FilterSwitch
                            key={grouping}
                            caption={grouping}
                            selected={values.grouping === grouping}
                            disabled={false}
                            onClick={() => handleValueChange('grouping')(grouping)}
                    />)}
                </div>
            </FilterSection>
        </FilterPanel>
    );
}
