import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { AppState } from '../../../types/state/AppState';
import { chartUtils, isRequesting } from '../../../utils';
import { HeatMapScheduledBwics } from './HeatmapScheduledBwics';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { RatingGroup, ratingGroupValues } from '../../../types/dashboard/RatingGroup';
import {
    currency, dateFrom, dateTo, ratings, statuses
} from '../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../utils/filtering/query-string-serializer';
import { constants, roles, routes } from '../../../constants';
import { user } from '../../../user';
import { dashboardChartHeatMapConstants } from '../../../constants/dashboard';
import { ShowFor } from '../../access/ShowFor';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { QueryStringArgumentSerializer } from '../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { useNextBusinessDates } from '../../../effects/data-accessors/useNextBusinessDates';
import { useScheduledBwics } from '../hooks/useScheduledBwics';
import { BwicStatus } from '../../../types/enums/BwicStatus';

export function HeatMapScheduledBwicsWidget() {
    const history = useHistory();

    const filter = useSelector((s: AppState) => s.dashboard.filter);
    const { requestStateFetchNextBusinessDates, nextBusinessDates } = useNextBusinessDates(moment());
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);
    const { bwics, requestStateFetchBwics } = useScheduledBwics();

    const { selectedRatings, selectedCurrencies } = filter;

    const filteredBwics = useMemo(() => bwics.map(bwic => ({
        ...bwic,
        securities: bwic.securities
            .filter(security => (selectedRatings.length
                ? (
                    selectedRatings.some(r => r === security.rating) ||
                    (
                        selectedRatings.some(r => r === RatingGroup.OTHER) &&
                        ratingGroupValues[RatingGroup.OTHER].some(r => r === security.rating)
                    )
                )
                : true
            ) &&
                (selectedCurrencies.length ? selectedCurrencies.some(currency => currency === security.currency) : true)
            )
    })), [bwics, selectedCurrencies, selectedRatings]);

    const onHeatMapCellClick = useCallback((date: string, hour: number, cellRatings: string[]) => {
        const { minHourForRedirect } = dashboardChartHeatMapConstants;
        const momentDate = moment(String(date), constants.dashboardDateFormat);
        if (cellRatings.length) {
            const date = momentDate.format();
            const searchParams = {
                ratings: chartUtils.getRatingsForSearchUrl(cellRatings),
                statuses: [BwicStatus.scheduled, BwicStatus.bidding, BwicStatus.finished],
                dateFrom: date,
                dateTo: date,
                currency: selectedCurrencies.length === 2 ? '' : selectedCurrencies[0]
            };
            const serializers: QueryStringArgumentSerializer<any>[] = [ratings(), statuses(), currency(selectedCurrencies), dateFrom(), dateTo()];
            const queryString = queryStringSerializer.serialize(searchParams, serializers);
            history.push({
                pathname: routes.allBWICs,
                search: queryString,
            });
        } else if (user.hasRoles(roles.SellerTrader)) {
            momentDate.set({ hour: Number(hour), minute: 0, second: 0, millisecond: 0 });
            momentDate.tz('EST');
            if (momentDate.hours() < minHourForRedirect) {
                momentDate.set({ hour: minHourForRedirect, minute: 0, second: 0 })
            }
            history.push({
                pathname: routes.newBWIC,
                state: { dateTime: momentDate.format() }
            });
        }
    }, [history, selectedCurrencies]);

    const renderHeatMap = useMemo(() => (
        <HeatMapScheduledBwics
            bwics={filteredBwics}
            nextBusinessDates={nextBusinessDates}
            onHeatMapCellClick={onHeatMapCellClick}
        />
    ), [filteredBwics, nextBusinessDates, onHeatMapCellClick]);

    const title = "BWICs Heatmap";

    return (
        <DashboardWidget title={title} filterActive={filterActive} className="heatmap">
            <DashboardSkeleton
                inProgress={
                    isRequesting(requestStateFetchBwics) ||
                    isRequesting(requestStateFetchNextBusinessDates)
                }
            >
                <DashboardWidgetHeader>
                    <h3>{title}</h3>
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestStateFetchBwics}>
                    {renderHeatMap}
                    <div className="description">
                        <ShowFor
                            roles={[...roles.bd(), roles.Administrator, roles.Viewer, roles.DataEntry]}>
                            Plan out your bidding schedule all in one place. Filter by rating and click on any time
                            slot to view the most relevant listings.
                        </ShowFor>
                        <ShowFor roles={[...roles.seller(), roles.SellerViewer]}>
                            Find the ideal time to run your next BWIC; data suggest that intra-day competition can
                            affect execution.
                        </ShowFor>
                    </div>
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
