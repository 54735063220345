import moment from 'moment';
import { PlotMouseEvent } from 'plotly.js';
import { mainConfig, Plot } from '../../common/charts';
import { dashboardChartHeatMapConstants } from '../../../constants/dashboard';
import { user } from '../../../user';
import { constants, roles } from '../../../constants';
import { RatingGroup, ratingGroupValues, ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';
import { Rating } from '../../../types/enums/Rating';
import { ScheduledBwic } from '../../../types/dashboard/ScheduledBwic';
import { chartUtils } from '../../../utils';

interface ZItemSize {
    [currency: string]: number;
}

interface ZItem {
    totalSize?: ZItemSize;
    count: number;
    size: ZItemSize;
    ratings: { [rating: string]: ZItemSize };
    securities: number;
}

interface HeatMapScheduledBwicsProps {
    bwics: ScheduledBwic[];
    nextBusinessDates: Date[];
    onHeatMapCellClick: (date: string, hour: number, ratings: string[]) => void;
}

export function HeatMapScheduledBwics({ bwics, nextBusinessDates, onHeatMapCellClick }: HeatMapScheduledBwicsProps) {
    const ratingGroupOtherValues = ratingGroupValues[RatingGroup.OTHER];
    const {
        colorScale, whiteColorScale, lineColor, tickColor, tickFont, margin, linkFontColor, textColor, annotationFontColors, lineWidth, firstTimeSlot, maxColumns, hoverlabel
    } = dashboardChartHeatMapConstants;

    const getMoney = (size: ZItemSize) => {
        let result = '';
        if (size.USD && Number(size.USD)) {
            result = chartUtils.getMoneyString(size.USD, '$', false, true)
        }
        if (size.EUR && Number(size.EUR)) {
            result = `${result ? result + '/' : ''}${chartUtils.getMoneyString(size.EUR, '€', false, true)}`
        }

        if (result) {
            result += 'MM';
        }

        return result
    };

    const getAnnotationText = (zItem: ZItem) => {
        if (zItem.totalSize) {
            return getMoney(zItem.totalSize) || '―';
        }
        if (zItem.count === 0 && user.hasRoles(...roles.seller())) {
            return `<span style="color: ${user.hasRoles(roles.SellerTrader, roles.BrokerDealerTrader) ? linkFontColor : textColor}; font-weight: 200" class="href">New BWIC</span>`
        }
        if (zItem.count === 0 && user.hasRoles(roles.Viewer, ...roles.bd())) {
            return `<span style="color: ${textColor};"> ― </span>`
        }
        if (!zItem.count) {
            return `<span style="color: ${textColor};">0MM</span>`
        }
        return `${getMoney(zItem.size)}`
    };

    const getTimeValues = () => {
        let resultTimeValues = [];
        let resultYTick = [];
        const { hours, yLabels, workingTimeInterval } = dashboardChartHeatMapConstants;
        const userTimeOffsetInMin = moment().utcOffset();
        const from = ((firstTimeSlot - -userTimeOffsetInMin) / 60) - 1;
        let to = from + workingTimeInterval;
        if (from < 0) {
            resultTimeValues = hours.slice(hours.length - -from, hours.length);
            resultTimeValues = resultTimeValues.concat(hours.slice(0, to));
            resultYTick = yLabels.slice(yLabels.length - -from, yLabels.length);
            resultYTick = resultYTick.concat(yLabels.slice(0, to))
        } else if (hours.length - from < workingTimeInterval) {
            const toItem = to - hours.length;
            resultTimeValues = hours.slice(from, to);
            resultTimeValues = resultTimeValues.concat(hours.slice(0, toItem));
            resultYTick = yLabels.slice(from, to);
            resultYTick = resultYTick.concat(yLabels.slice(0, toItem))
        } else {
            resultTimeValues = hours.slice(from, to);
            resultYTick = yLabels.slice(from, to)
        }
        const timeValues = resultTimeValues.reverse();
        timeValues.unshift('-1');
        const yTick = resultYTick.reverse();
        yTick.unshift(`<b style="color:#4a4a4a;">Total</b>`);
        return { timeValues, yTick };
    };

    const { timeValues, yTick } = getTimeValues();

    const getInitialValues = () => {
        const x: string[] = [];
        const y = timeValues.map((item, index) => index);
        const z: ZItem[][] = y.map(() => []);
        nextBusinessDates.forEach(day => {
            if (x.length < maxColumns) {
                x.push(moment.utc(day).startOf('day').format(constants.dashboardDateFormat));
                z.map((item: ZItem[], index: number) => {
                    let value: ZItem = { size: { USD: 0, EUR: 0 }, count: 0, ratings: {}, securities: 0 };
                    if (index === 0) {
                        value.totalSize = { USD: 0, EUR: 0 }
                    }
                    return item.push(value);
                });
            }
        });
        return { x, y, z };
    };

    const getChartData = () => {
        const initialValues = getInitialValues();
        let maxValue = 0;
        let minValue = 0;
        const { x, y, z } = initialValues;
        bwics.forEach(bwic => {
            if (bwic && bwic.bidsDueDateUtc && x.length <= maxColumns) {
                const date = moment.utc(bwic.bidsDueDateUtc).local().format(constants.dashboardDateFormat);
                const time = moment.utc(bwic.bidsDueDateUtc).local().format('HH');
                const dateIndex = x.indexOf(date);
                let timeIndex = timeValues.indexOf(time);
                if (bwic.securities && bwic.securities.length) {
                    const { securities } = bwic;
                    securities.forEach(security => {
                        if (timeIndex === -1) {
                            if (timeValues.indexOf("24") === -1) {
                                if (time < timeValues[timeValues.length - 1]) {
                                    timeIndex = timeValues.indexOf(timeValues[timeValues.length - 1]);
                                }
                                if (time > timeValues[1]) {
                                    timeIndex = timeValues.indexOf(timeValues[1]);
                                }
                            } else {
                                if (time > timeValues[timeValues.length - 1]) {
                                    timeIndex = timeValues.indexOf(timeValues[timeValues.length - 1]);
                                }
                                if (time < timeValues[1]) {
                                    timeIndex = timeValues.indexOf(timeValues[1]);
                                }
                            }
                        }
                        if (timeIndex > -1) {
                            const zItem: ZItem = z[timeIndex][dateIndex];
                            const zItemTotal: ZItem = z[0][dateIndex];
                            if (zItem) {
                                zItem.size[security.currency] += security.size;
                                zItem.count += 1;
                                let securityRating: Rating | RatingGroup = security.rating;
                                if (ratingGroupOtherValues.indexOf(security.rating) > -1) {
                                    securityRating = RatingGroup.OTHER;
                                }
                                if (!zItem.ratings[securityRating]) {
                                    zItem.ratings[securityRating] = { USD: 0, EUR: 0, count: 0 };
                                }
                                zItem.ratings[securityRating][security.currency] += security.size;
                                zItem.ratings[securityRating].count += 1;
                                z[timeIndex][dateIndex] = zItem;

                                if (maxValue < (zItem.size.USD + zItem.size.EUR)) {
                                    maxValue = zItem.size.USD + zItem.size.EUR
                                }
                                if (!minValue) {
                                    minValue = zItem.size.USD + zItem.size.EUR
                                }
                                if ((zItem.size.USD + zItem.size.EUR) < minValue) {
                                    minValue = (zItem.size.USD + zItem.size.EUR)
                                }
                            }
                            if (zItemTotal && zItemTotal.totalSize) {
                                zItemTotal.totalSize[security.currency] += security.size;
                                zItemTotal.count += 1;
                            }
                        }
                    })
                }
            }
        });
        return { x, y, z, maxValue, minValue }
    };

    const { x, y, z, minValue, maxValue } = getChartData();

    const getAnnotationsFontColor = (zValue: ZItem) => {
        if ((zValue.size.USD + zValue.size.EUR) > 0) {
            if (maxValue === minValue) {
                return annotationFontColors[annotationFontColors.length - 1]
            }
            const byOnePercent = (maxValue - minValue) / 100;
            const valueInPercent = ((zValue.size.USD + zValue.size.EUR) - minValue) / byOnePercent;
            const colorIndex = Math.ceil(valueInPercent / (100 / annotationFontColors.length));
            if (colorIndex > annotationFontColors.length) {
                return annotationFontColors[annotationFontColors.length - 1]
            }
            return annotationFontColors[(colorIndex - 1 >= 0 ? colorIndex - 1 : 0)]
        }
        return annotationFontColors[0];
    };

    const getYRange = (x: string[] = []) => {
        const xLength = x.length;
        switch (xLength) {
            case 5:
                return [-0.5, 4.5];
            case 4:
                return [-0.5, 3.5];
            case 3:
                return [-0.5, 2.5];
            case 2:
                return [-0.5, 1.5];
            case 1:
                return [-0.5, 0.5];
            default:
                return [-0.5, 4.5]
        }
    };

    const getWarningText = (cellData: ZItem) => {
        if (
            cellData.count === 0 &&
            !cellData.totalSize &&
            user.hasRoles(roles.SellerViewer)) {
            return "You have limited access to the KTX ATS Platform.";
        }
    };

    const getCellText = (cellData: ZItem) => {
        const cellRatings = Object.keys(cellData.ratings);
        return cellRatings.length
            ? cellRatings
                .sort((a, b) => ratingWithGroupValues.indexOf(a as Rating) - ratingWithGroupValues.indexOf(b as Rating))
                .map((key: string) =>
                    `${key === RatingGroup.OTHER ? 'Other' : key} ${getMoney(cellData.ratings[key])} - ${cellData.ratings[key].count} securities &nbsp;&nbsp;`
                ).join('<br>')
            : getWarningText(cellData)
    };

    const getChartText = () => {
        return z
            .map((columnCollection) => columnCollection
                .map((cellData) => getCellText(cellData))
            )
    };

    const layout = {
        plot_bgcolor: lineColor,
        annotations: z.map((i1, index1) => i1.map((i2, index2) => {
            return {
                x: x[index2],
                y: y[index1],
                text: getAnnotationText(i2),
                font: {
                    family: 'Roboto Medium, Arial, Helvetica, sans- serif',
                    size: 10,
                    color: getAnnotationsFontColor(i2),
                },
                showarrow: false,
            }
        })).reduce((n, current) => {
            return n.concat(current)
        }, []),
        hoverlabel,
        xaxis: {
            linecolor: lineColor,
            linewidth: lineWidth,
            range: getYRange(x),
            fixedrange: true,
            mirror: true,
            tickcolor: tickColor,
            tickfont: tickFont,
        },
        yaxis: {
            linecolor: lineColor,
            linewidth: lineWidth,
            mirror: true,
            fixedrange: true,
            tickvals: y,
            ticktext: yTick,
            tickcolor: tickColor,
            tickfont: tickFont,
            zeroline: false
        },
        margin
    };

    const heatMap = [{
        x: x,
        y: y,
        z: z.map((i1) => i1.map((i2) => i2.size.USD + i2.size.EUR)),
        type: 'heatmap',
        showscale: false,
        hoverongaps: true,
        colorscale: maxValue ? colorScale : whiteColorScale,
        hoverinfo: 'text',
        text: getChartText(),
        xgap: .6,
        ygap: .6
    }];

    const handleOnHeatMapCellClick = (e: PlotMouseEvent) => {
        const point: any = e && e.points && e.points[0];
        const [columnIndex, cellIndex] = point.pointIndex;
        const cellData = z[columnIndex][cellIndex];
        const hour = Number(timeValues[point.y]);
        const date = String(point.x);
        const ratings = Object.keys(cellData.ratings);
        if (point.y !== 0) {
            onHeatMapCellClick(date, hour, ratings)
        }
    };

    return (
        <Plot
            onClick={handleOnHeatMapCellClick}
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            data={heatMap}
            layout={layout}
            config={mainConfig}
        />
    )
}
