import React from 'react';
import { mainConfig, Plot } from '../../common/charts';
import { chartDtcSellingBuyingConstants } from '../../../constants/dashboard/chartDtcSellingBuying';
import { chartUtils, numericUtils, moneyUtils } from '../../../utils';

interface ChartData {
    x: string;
    y: number;
}

interface DtcSellingBuyingChartProps {
    data: ChartData[]
    chartDiv?: string;
}

export function DtcSellingBuyingChart({ data, chartDiv }: DtcSellingBuyingChartProps) {

    const formatChartMoney = (value: number) => value > 0 ?
        `$${moneyUtils.money(value)}MM` :
        `-$${moneyUtils.money(-value)}MM`;

    const getYLineValues = () => {
        let currentYValue = 0;
        return data.map(value => {
            currentYValue = currentYValue + value.y;
            return currentYValue
        });
    };

    const createPlotData = () => {
        const { positiveBarColor, negativeBarColor, lineColor } = chartDtcSellingBuyingConstants;
        const x = data.map(d => d.x);
        const y = data.map(d => d.y);
        const colors = data.map(d => d.y > 0 ? positiveBarColor : negativeBarColor);
        return [
            {
                x,
                y,
                marker: { color: colors },
                type: 'bar',
                name: 'Bars',
                hoverinfo: 'text',
                text: data.map(d => `Dealer Flow: ${formatChartMoney(d.y)}`),
                textposition: 'none',
            },
            {
                x,
                y: getYLineValues(),
                mode: 'lines',
                name: 'Line',
                hoverinfo: 'text',
                text: getYLineValues().map(d => `Cumulative Flow: ${formatChartMoney(d)}`),
                textposition: 'none',
                line: { dash: 'dash', width: 2, color: lineColor, shape: 'spline' },
            }
        ]
    };

    const plotData = createPlotData();

    const getYAxesRange = () => {
        const { yAxisDTick, yAxisMargin } = chartDtcSellingBuyingConstants;
        let maxValue = 0;
        let minValue = 0;
        data.forEach(d => {
            if (d.y > maxValue) {
                maxValue = d.y
            }
            if (d.y < minValue) {
                minValue = d.y
            }
        });
        getYLineValues().forEach(d => {
            if (d > maxValue) {
                maxValue = d
            }
            if (d < minValue) {
                minValue = d
            }
        });

        const minRange = minValue < 0 ? minValue - numericUtils.getPercentage(yAxisDTick, yAxisMargin) : minValue;
        const maxRange = maxValue + numericUtils.getPercentage(maxValue, yAxisMargin);
        return [minRange, maxRange];
    };

    const annotations = data.map((d) => {
        const { annotationMargin, annotationsFont } = chartDtcSellingBuyingConstants;
        const range = getYAxesRange();
        const totalRangeValue = Math.abs(range[0] - range[1]);
        const margin = totalRangeValue / annotationMargin;
        return {
            x: d.x,
            y: d.y > 0 ? d.y + margin : d.y - margin,
            text: moneyUtils.money(d.y),
            arrowhead: 0,
            ax: 0,
            ay: 0,
            font: annotationsFont,
        }
    });

    const layout = {
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        annotations,
        margin: chartDtcSellingBuyingConstants.margin,
        hoverlabel: chartDtcSellingBuyingConstants.hoverlabel,
        xaxis: {
            tickcolor: chartDtcSellingBuyingConstants.tickColor,
            tickfont: chartDtcSellingBuyingConstants.tickFont,
            tickangle: 0,
        },
        yaxis: {
            separatethousands: true,
            zerolinecolor: chartDtcSellingBuyingConstants.tickColor,
            range: getYAxesRange(),
            dtick: chartDtcSellingBuyingConstants.yAxisDTick,
            gridcolor: chartDtcSellingBuyingConstants.tickColor,
            ticks: 'inside',
            tickcolor: chartDtcSellingBuyingConstants.tickColor,
            tickfont: chartDtcSellingBuyingConstants.tickFont,
        },
    };

    return (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={plotData}
            layout={layout}
            config={mainConfig}
        />
    )
}

DtcSellingBuyingChart.defaultProps = {
    chartDiv: 'dtc-selling-buying-chart'
};
