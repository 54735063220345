import { useSelector } from "react-redux";
import { AppState } from "../../../types/state/AppState";
import { KWatchNewsWidget } from './KWatchNewsWidget';

export const DashboardKWatchNewsWidget = () => {
    const kWatchNews = useSelector((s: AppState) => s.dashboard.kWatchNews);
    const requestStateFetchKWatchNews = useSelector(
        (s: AppState) => s.dashboard.requestStateFetchKWatchNews
    );

    return (
        <KWatchNewsWidget news={kWatchNews} requestState={requestStateFetchKWatchNews} />
    );
};
