import { constants } from '../../../constants';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { Currency } from '../../../types/enums/Currency';

export const TopIssuersTransactionTypes = [
    { value: constants.allListItem, text: 'All' },
    { value: TransactionType.newIssue, text: 'New Issue' },
    { value: TransactionType.refi, text: 'Refinancing' },
    { value: TransactionType.reset, text: 'Reset' },
];

export const TopIssuersCurrencyTypes = [
    {
        text: "All",
        value: constants.allListItem,
    },
    {
        text: Currency.USD,
        value: Currency.USD,
    },
    {
        text: Currency.EUR,
        value: Currency.EUR,
    },
];
