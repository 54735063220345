import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { InputField } from '../forms';
import { AutoCompleteInput, FormError, Popup, PopupBody, PopupFooter } from '../controls';
import { constants, errorMessages } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { stringUtils } from '../../utils';
import { yup } from '../../validation/yup';
import { SaveContactModel } from '../../types/company/Contact';

const initialFormState: SaveContactModel = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    dealerName: '',
};

export const EditContactPanel: React.FC = () => {
    const dispatch = useDispatch();
    const editableContact = useSelector((state: AppState) => state.dealerList.editableContact);
    const brokerDealerList = useSelector((state: AppState) => state.entities.brokerDealerList.items);
    const brokerDealerContactList = useSelector((state: AppState) => state.entities.brokerDealerContactList.items);
    const companyNames = useMemo(() => brokerDealerList.map(b => b.name), [brokerDealerList]);

    const initialFormData = useMemo(() => editableContact.contact
            ? {
                id: editableContact.contact.id,
                dealerName: editableContact.dealerName,
                firstName: editableContact.contact.firstName,
                lastName: editableContact.contact.lastName,
                email: editableContact.contact.email,
                role: editableContact.contact.role || '',
                phone: editableContact.contact.phone || '',
            }
            : initialFormState,
        [editableContact.contact, editableContact.dealerName]
    );

    if (!editableContact.contact) {
        return null
    }

    const handleSubmit = (contact: SaveContactModel) => {
        dispatch(dealerListActions.editableContactSave({ ...contact, id: editableContact.contact?.id || 0 }));
    };

    const handleBrokerDealerCustomValidate = (dealerName: string | null | undefined) =>
        brokerDealerList
            .map(c => c.name)
            .some(n => n.localeCompare(String(dealerName), undefined, { sensitivity: 'accent' }) === 0);

    const handleEmailCustomValidate = (email: string) => {
        if (!stringUtils.isNullOrEmpty(email)) {
            return !brokerDealerContactList.some(contact => {
                    return (
                        contact.email.localeCompare(email, undefined, { sensitivity: 'accent' }) === 0
                        && (editableContact.contact?.id === 0 || (editableContact.contact?.id !== contact.id))
                    )
                }
            );
        }
        return true;
    };

    const handleCustomValidate = (email: string | null | undefined) => handleEmailCustomValidate(email || '');

    const validationSchema = yup.object().shape({
        firstName: yup.string().trim().matches(constants.name).max(64),
        lastName: yup.string().trim().matches(constants.name).max(64),
        email: yup.string().trim().required().email().max(256).test('email', errorMessages.duplicateDealerEmail, handleCustomValidate),
        role: yup.string().trim().max(256, errorMessages.maxLength(256)),
        phone: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
        dealerName: yup.string().required().test('dealerName', errorMessages.invalidValue, handleBrokerDealerCustomValidate)
    });

    const renderError = (error: string | undefined, isTouched: boolean | undefined) => (
        isTouched && <FormError message={error} />
    );

    const handleSetDealerName = (name: string, setFieldValue: (field: string, value: string) => void) => {
        dispatch(dealerListActions.editableContactUpdateDealer(name));
        setFieldValue('dealerName', name);
    };

    const renderContent = () => (
        <Formik
            initialValues={initialFormData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form noValidate>
                    <PopupBody>
                        <div className="form-row">
                            <label className="form-label" htmlFor="participantBrokerDealer">
                                Broker-dealer<span className="text-red">&nbsp;*</span>
                            </label>
                            <div className="form-control-wrapper">
                                <AutoCompleteInput
                                    className="form-control form-control-search"
                                    name="participantBrokerDealer"
                                    required={true}
                                    maxLength={256}
                                    sourceItems={companyNames}
                                    value={editableContact.dealerName}
                                    onChange={(dealerName: string) => handleSetDealerName(dealerName, setFieldValue)}
                                    placeholder="Choose Company"
                                    isInvalid={touched.dealerName && errors.dealerName}
                                />
                                {renderError(Array.isArray(errors.dealerName) ? errors.dealerName?.join(',') : errors.dealerName, touched.dealerName)}
                            </div>
                        </div>

                        <div className="form-row form-row-inline">
                            <div className="form-item">
                                <InputField
                                    label="First Name"
                                    placeholder="First Name"
                                    markRequired={false}
                                    name="firstName"
                                    pattern={constants.name.source}
                                    loading={false}
                                    maxLength={64}
                                />
                            </div>
                            <div className="form-item">
                                <InputField
                                    label="Last Name"
                                    placeholder="Last Name"
                                    markRequired={false}
                                    name="lastName"
                                    pattern={constants.name.source}
                                    loading={false}
                                    maxLength={64}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-item">
                                <InputField
                                    label="Email"
                                    placeholder="Email"
                                    markRequired={true}
                                    name="email"
                                    pattern={constants.email.source}
                                    loading={false}
                                    maxLength={64}
                                />
                            </div>
                        </div>
                        <div className="form-row form-row-inline">
                            <div className="form-item">
                                <InputField
                                    label="Title"
                                    placeholder="Title"
                                    markRequired={false}
                                    name="role"
                                    pattern={constants.email.source}
                                    loading={false}
                                    maxLength={256}
                                />
                            </div>
                            <div className="form-item">
                                <InputField
                                    label="Phone"
                                    placeholder="Phone Number"
                                    markRequired={false}
                                    name="phone"
                                    type="tel"
                                    loading={false}
                                    maxLength={20}
                                />
                            </div>
                        </div>
                    </PopupBody>
                    <PopupFooter>
                        <button
                            className="btn btn-ghost"
                            type="button"
                            onClick={() => dispatch(dealerListActions.resetEditableContact())}
                        >
                            cancel
                        </button>
                        <button
                            disabled={editableContact.updateRequestStatus}
                            className="btn btn-main"
                            type="submit"
                            id="saveContact"
                        >
                            save
                        </button>
                    </PopupFooter>
                </Form>
            )}
        </Formik>
    )

    return (
        <Popup
            modalClass="modal-participants-form"
            onClose={() => dispatch(dealerListActions.resetEditableContact())}
            title={editableContact.contact?.id ? 'Edit Contact' : 'Add Contact'}
        >
            {renderContent()}
        </Popup>
    )
};
