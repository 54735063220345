import React, { FC } from 'react';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { WidgetDescription } from '../WidgetDescription';
import { KIndicatorChart } from './KIndicatorChart';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequesting } from '../../../utils';

const title = "K-Indicator";

export const KIndicatorWidget: FC = () => {
   const dataKIndicator = useSelector((s: AppState) => s.dashboard.kIndicator);
   const requestStateFetchKIndicator = useSelector((s: AppState) => s.dashboard.requestStateFetchKIndicator);
   
    return (
        <DashboardWidget title={title} className="k-indicator">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchKIndicator)}>
                <DashboardWidgetHeader>
                    <h3>{title}</h3>
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestStateFetchKIndicator}>
                    <KIndicatorChart data={dataKIndicator} />
                    <WidgetDescription
                        id="percent-of-trace-clamp"
                        lines={2}
                        text="The KopenTech K-Indicator incorporates a variety of proprietary alternative metrics that measure secondary CLO market liquidity, transparency, and sentiment. It is given by the equal-weighted average of the normalized values of five metrics: DNT Rate, Color Rate, AAA Average Px Talk Deviation from Color, Same Day BWIC Rate, and Avg Traded AAA Price Level.
                                <br/>Values close to zero indicate the market is neutral, with lower values indicating more stress and higher values indicating less stress.
                                <br/>Values below -1 correspond to a very stressed market, and values below -2 imply an extremely stressed market.
                                <br/>Values above 1 correspond to a very well-functioning market, and values above 2 imply near-perfect liquidity."
                    />
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    )
};
