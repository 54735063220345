import React from 'react';
import { TabList, TabItem } from '../../bidding/common/tab-list/index';
import { DashboardSellBuyTabs } from '../../../types/dashboard/SellBuyTabs';

interface TabsProps {
    activeTab: DashboardSellBuyTabs;
    onTabClick: (tab: DashboardSellBuyTabs) => void;
}

export function Tabs({ activeTab, onTabClick }: TabsProps) {
    return (
        <TabList>
            <TabItem
                onClick={() => onTabClick(DashboardSellBuyTabs.Buy)}
                active={activeTab === DashboardSellBuyTabs.Buy}
                title="Buy"
            />
            <TabItem
                onClick={() => onTabClick(DashboardSellBuyTabs.Sell)}
                active={activeTab === DashboardSellBuyTabs.Sell}
                title="Sell"
            />
        </TabList>
    )
}
