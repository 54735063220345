import { useMemo } from 'react';
import { PlotMouseEvent } from 'plotly.js';
import moment, { Moment } from 'moment';
import { first } from 'lodash';
import { Plot, mainConfig } from '../../common/charts';
import { chartShortLongDatedDeals, constants } from '../../../constants';
import { numericUtils } from '../../../utils';
import { arrayUtils } from '../../../utils/array.utils';
import { ChartGrouping, ChartUnits, ChartView } from '../../../types/dashboard/AmrChart';
import { ShortLongDatedDealsChartFilter } from './ShortLongDatedDealsFilter';
import { ShortLongDatedVolume } from '../../../types/dashboard/ShortLongDatedVolume';
import { ShortOrLongDated } from '../../../types/amr-pipeline/enums/ShortOrLongDated';
import { Currency } from '../../../types/enums/Currency';
import { ShortLongChartClickHandler } from './types';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { amrChartUtils, GroupedBy, Range } from '../../../utils/amr-chart.utils';
import { chartUtils } from '../../../utils/chart.utils';

interface ShortLongDatedDealsChartProps {
    deals: ShortLongDatedVolume[];
    filter: ShortLongDatedDealsChartFilter;
    periodsCount?: number;
    divId?: string;
    onClick?: ShortLongChartClickHandler;
}

interface ShortLongDatedVolumeWithDate extends Omit<ShortLongDatedVolume, 'year' | 'month'> {
    date: Moment;
}

const CurrencyTickPrefix = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
};

const GroupingTickFormat: Partial<Record<ChartGrouping, any>> = {
    [ChartGrouping.M]: {
        tickformat: '%b `%y',
        dtick: 'M1'
    },
    [ChartGrouping.Q]: {
        tickformat: '%qQ `%y',
        dtick: 'M3'
    },
    [ChartGrouping.Y]: {
        tickformat: '%Y',
        dtick: 'M12'
    },
};

const ChartRangeFormat = 'YYYY-MM-DD';
const MonthDateFormat = 'YYYY-MM';
const YearDateFormat = 'YYYY';
const ChartLegends = [
    'Long-Dated Deals',
    'Short-Dated Deals',
];

export function ShortLongDatedDealsChart({
    deals,
    filter,
    periodsCount = 12,
    divId,
    onClick,
}: ShortLongDatedDealsChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        barColors,
        zeroLineColor,
        hoverlabel,
    } = chartShortLongDatedDeals;

    const dealsWithDate = useMemo(() => deals.map(({ year, month, ...rest }) => ({
        ...rest,
        date: moment.utc([year, month - 1]),
    } as ShortLongDatedVolumeWithDate)), [deals]);

    const isPercentMode = [ChartUnits.PercentByCount, ChartUnits.PercentByVolume].includes(filter.units);

    const handlePlotClick = (e: PlotMouseEvent) => {
        if (!onClick) {
            return;
        }

        if (!e.points.length) {
            return;
        }

        const [point] = e.points;

        const { x, data } = point;

        const startPeriod = moment.utc(x);
        const endPeriod = startPeriod.clone();

        switch (filter.grouping) {
            case ChartGrouping.M:
                endPeriod.endOf('month').startOf('day');
                break;
            case ChartGrouping.Q:
                endPeriod.endOf('quarter').startOf('day');
                break;
            case ChartGrouping.Y:
                endPeriod.endOf('year').startOf('day');
                break;
        }

        const shortOrLongDated = first(data.customdata as number[]) === 0
            ? ShortOrLongDated.Long
            : ShortOrLongDated.Short;

        onClick({
            shortOrLongDated,
            startPeriod: startPeriod.toDate(),
            endPeriod: endPeriod.toDate(),
            transactionType: filter.transactionType === constants.allListItem
                ? undefined
                : filter.transactionType as TransactionType,
            currencyCode: filter.currencyCode,
        });
    };

    const getActualDataRange = () => {
        const valueGetter = (row: ShortLongDatedVolume) => moment.utc([row.year, row.month - 1]);
        const minDateRow = arrayUtils.min(deals, valueGetter);
        const maxDateRow = arrayUtils.max(deals, valueGetter);

        const toMoment = (value?: ShortLongDatedVolume) => moment.utc([
            value ? value.year : 1,
            value ? value.month - 1 : 0
        ]);

        return {
            min: toMoment(minDateRow),
            max: toMoment(maxDateRow),
        };
    };

    const getChartRange = ({ min, max }: Range<Moment>) => {
        let chartRangeMin: Moment;
        let chartRangeMax: Moment;

        switch (filter.grouping) {
            case ChartGrouping.M:
                chartRangeMax = max.clone().add(15, 'days');
                chartRangeMin = max.clone()
                    .subtract(periodsCount, 'months')
                    .add(15, 'days');
                break;
            case ChartGrouping.Q:
                chartRangeMax = max
                    .clone()
                    .startOf('quarter')
                    .add(45, 'days');

                chartRangeMin = max
                    .clone()
                    .subtract(periodsCount, 'quarters');
                break;
            default:
                chartRangeMin = min
                    .clone()
                    .startOf('year')
                    .subtract(6, 'months');

                chartRangeMax = max
                    .clone()
                    .startOf('year')
                    .add(6, 'months');
        }

        return {
            min: chartRangeMin,
            max: chartRangeMax,
        };
    };

    const getDataRange = (actualDataRange: Range<Moment>, chartRange: Range<Moment>) => {
        return {
            min: actualDataRange.min.isBefore(chartRange.min) ? actualDataRange.min : chartRange.min,
            max: chartRange.max,
        }
    };

    const getDimension = (date: Moment) => {
        switch (filter.grouping) {
            case ChartGrouping.Y:
                return date.format(YearDateFormat);
            case ChartGrouping.Q:
                // Group by first month of each quarter
                return `${date.year()}-${date.quarter() * 3 - 2}`;
            default:
                return date.format(MonthDateFormat);
        }
    };

    const getMetric = (row: ShortLongDatedVolumeWithDate) => {
        switch (filter.units) {
            case ChartUnits.Count:
            case ChartUnits.PercentByCount:
                return row.numberOfTransactions;
            default:
                return row.dealBalanceTotal;
        }
    };

    const groupByDate = (data: ShortLongDatedVolumeWithDate[]) => {
        const isSame = (row: ShortLongDatedVolumeWithDate) =>
            (iteratee: GroupedBy<Moment, ShortLongDatedVolumeWithDate>) =>
                iteratee.date.isSame(row.date, amrChartUtils.groupingToUnitOfTime(filter.grouping));

        return data.reduce((acc: GroupedBy<Moment, ShortLongDatedVolumeWithDate>[], row: ShortLongDatedVolumeWithDate) => {
            const existingEntry = acc.find(isSame(row));

            if (existingEntry) {
                return [
                    ...acc.filter(x => !isSame(row)(x)),
                    {
                        ...existingEntry,
                        entries: [
                            ...existingEntry.entries,
                            row,
                        ]
                    }
                ];
            }

            return [
                ...acc,
                { date: row.date, entries: [row] }
            ];
        }, []);
    };

    const groupVolumeData = (data: ShortLongDatedVolumeWithDate[], chartRange: Range<Moment>) => {
        const groupedByType = arrayUtils.groupBy(
            data,
            (row: ShortLongDatedVolumeWithDate) => row.shortOrLong
        );

        return [ShortOrLongDated.Short, ShortOrLongDated.Long].reduce((acc: Record<string, any[]>, shortOrLongDated: ShortOrLongDated) => {
            const dataByType: ShortLongDatedVolumeWithDate[] = groupedByType.get(shortOrLongDated) || [];

            const groupedByDate = groupByDate(dataByType);

            const fillGaps = amrChartUtils.gapsFiller(groupedByDate, row => row.date, date => ({ date, entries: [] }));

            const dataWithFilledGaps = (chartRange.min && chartRange.max) ? fillGaps(chartRange, amrChartUtils.groupingToUnitOfTime(filter.grouping)) : groupedByDate;

            const stats = dataWithFilledGaps.reduce((acc: any, row: GroupedBy<Moment, ShortLongDatedVolumeWithDate>) => {
                const dimension = getDimension(row.date);
                const metric = arrayUtils.sum(row.entries, (value: ShortLongDatedVolumeWithDate) => getMetric(value));

                const [x = [], y = []] = acc || [];

                return [
                    [...x, dimension],
                    [...y, metric],
                ];
            }, [[], []]);

            return {
                ...acc,
                [shortOrLongDated]: stats,
            };
        }, {
            [ShortOrLongDated.Long]: [],
            [ShortOrLongDated.Short]: [],
        });
    };

    const groupPercentData = (data: ShortLongDatedVolumeWithDate[], chartRange: Range<Moment>) => {
        const allGroupedByDate = arrayUtils.groupBy(
            data,
            (row: ShortLongDatedVolumeWithDate) => getDimension(row.date)
        );

        const groupedByType = arrayUtils.groupBy(
            data,
            (row: ShortLongDatedVolumeWithDate) => row.shortOrLong
        );

        return [ShortOrLongDated.Short, ShortOrLongDated.Long].reduce((acc: Record<string, any[]>, shortOrLongDated: ShortOrLongDated) => {
            const dataByType: ShortLongDatedVolumeWithDate[] = groupedByType.get(shortOrLongDated) || [];

            const groupedByDate = groupByDate(dataByType);

            const fillGaps = amrChartUtils.gapsFiller(groupedByDate, row => row.date, date => ({ date, entries: [] }));

            const dataWithFilledGaps = (chartRange.min && chartRange.max) ? fillGaps(chartRange, amrChartUtils.groupingToUnitOfTime(filter.grouping)) : groupedByDate;

            const stats = dataWithFilledGaps.reduce((acc: any, row: GroupedBy<Moment, ShortLongDatedVolumeWithDate>) => {
                const elelemntsByDate = allGroupedByDate.get(getDimension(row.date)) || [];
                const dayTotal = arrayUtils.sum(elelemntsByDate, (value: ShortLongDatedVolumeWithDate) => getMetric(value));
                const shortOrLongDayTotal = arrayUtils.sum(row.entries, (value: ShortLongDatedVolumeWithDate) => getMetric(value));

                const metric = numericUtils.round((shortOrLongDayTotal / dayTotal) * 100);
                const dimension = getDimension(row.date);

                const [x = [], y = []] = acc || [];

                return [
                    [...x, dimension],
                    [...y, metric],
                ];
            }, [[], []]);

            return {
                ...acc,
                [shortOrLongDated]: stats,
            };
        }, {
            [ShortOrLongDated.Long]: [],
            [ShortOrLongDated.Short]: [],
        });
    }

    const getChartTraces = (groupedData: Record<string, any[][]>) => {
        return Object.values(groupedData).map(([x, y], index) => ({
            x,
            y,
            name: ChartLegends[index],
            type: 'bar',
            // Custom hovertemplate fixes issue with shrinked legend in tooltip
            hovertemplate: `${ChartLegends[index]}: %{y}<extra></extra>`,
            customdata: [index],
            marker: {
                color: barColors[index],
            },
        }));
    };

    const getChartData = (chartRange: Range<Moment>) => {
        const groupedData = [ChartUnits.Count, ChartUnits.Volume].includes(filter.units)
            ? groupVolumeData(dealsWithDate, chartRange)
            : groupPercentData(dealsWithDate, chartRange);

        return getChartTraces(groupedData);
    };

    const getTickPrefix = (units: ChartUnits, currencyCode?: Currency) => {
        if (isPercentMode) {
            return '%';
        }

        return units === ChartUnits.Volume && currencyCode
            ? CurrencyTickPrefix[currencyCode]
            : '';
    };

    const getLayout = (chartRange: Range<Moment>) => {
        const { dtick, tickformat } = GroupingTickFormat[filter.grouping];

        const prefix = getTickPrefix(filter.units, filter.currencyCode);

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: onClick ? 'nearest' : 'x unified',
            hoverlabel,
            barmode: ChartView.Stacked,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                range: [
                    chartRange.min.format(ChartRangeFormat),
                    chartRange.max.format(ChartRangeFormat)
                ],
                tickfont: tickFont,
                fixedrange: true,
                dtick,
                tickformat,
                rangeslider: {
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
                ...(isPercentMode ? { ticksuffix: prefix } : { tickprefix: prefix }),
                ...(isPercentMode ? { range: [0, 100] } : undefined),
            },
        }
    };

    const actualDataRange = getActualDataRange();
    const chartRange = getChartRange(actualDataRange);
    const dataRange = getDataRange(actualDataRange, chartRange);
    const data = getChartData(dataRange);
    const layout = getLayout(chartRange);

    return (
        <>
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                onClick={handlePlotClick}
                divId={divId}
                data={data}
                layout={layout}
                config={mainConfig}
            />
            <div className="agenda">
                <div className="short-dates">{ChartLegends[1]}</div>
                <div className="long-dates">{ChartLegends[0]}</div>
            </div>
        </>
    );
}

ShortLongDatedDealsChart.defaultProps = {
    divId: 'long-short-dated-deals-chart'
};
