import React from 'react';
import { UserSlim } from '../../types/account/UserSlim';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { constants } from '../../constants';

interface DealerUserProps {
    user: UserSlim
}

export const DealerUser: React.FC<DealerUserProps> = ({ user }) => {
    return (
        <div className="data-table-item-row flex-row">
            <div className="flex-none data-list-name">
                <OnHoverTooltip overlay="Active">
                    <span className="status active" />
                </OnHoverTooltip>
                <OnHoverTooltip overlay={`${user.firstName} ${user.lastName}`}>
                    <span className="display-inline name text-ellipsis">{user.firstName} {user.lastName}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-title">
                <OnHoverTooltip wrapperClass="title" overlay={user.title === constants.emptyPlaceholder ? null : user.title}>
                    <span className="display-inline text-ellipsis dash">{user.title || constants.emptyPlaceholder}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-email">
                <OnHoverTooltip overlay={user.email}>
                    <span className="display-inline email text-ellipsis">
                        {<a href={`mailto:${user.email}`}>{user.email}</a> || constants.emptyPlaceholder}
                    </span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-phone">{user.phone || constants.emptyPlaceholder}</div>
        </div>
    )
};
