import React from 'react';
import moment from 'moment';
import { Plot, mainConfig } from '../../common/charts';
import { chartIssueTransactionVolume } from '../../../constants';
import { collateralTypes } from '../../../constants/collateral-types';
import { chartUtils } from '../../../utils';
import { arrayUtils } from '../../../utils/array.utils';
import { IssueTransactionVolume } from '../../../types/dashboard/IssueTransactionVolume';
import { ChartGrouping, ChartUnits } from '../../../types/dashboard/AmrChart';
import { ChartFilter } from './IssueTransactionVolumeFilter';
import { CollateralType } from '../../../types/amr-pipeline/enums/CollateralType';
import { Currency } from '../../../types/enums/Currency';

interface IssueTransactionVolumeChartProps {
    data: IssueTransactionVolume[];
    filter: ChartFilter,
    divId?: string;
}

const CurrencyTickPrefix = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
};

const GroupingTickFormat: Partial<Record<ChartGrouping, any>> = {
    [ChartGrouping.M]: {
        tickformat: '%b `%y',
        dtick: 'M1'
    },
    [ChartGrouping.Q]: {
        tickformat: '%qQ `%y',
        dtick: 'M3'
    },
    [ChartGrouping.Y]: {
        tickformat: '%Y',
        dtick: 'M12'
    },
};

const MonthDateFormat = 'YYYY-MM';
const ChartRangeFormat = 'YYYY-MM-DD';
const YearDateFormat = 'YYYY';
const MaxRangeYears = 6;

export function IssueTransactionVolumeChart({ data, filter, divId }: IssueTransactionVolumeChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        barColors,
        zeroLineColor,
        hoverlabel,
    } = chartIssueTransactionVolume;

    const getEdgeDimensionValues = () => {
        const valueGetter = (data: any) => moment.utc([data.year, data.month - 1]);
        const minDateRow = arrayUtils.min(data, valueGetter);
        const maxDateRow = arrayUtils.max(data, valueGetter);

        const toMoment = (value?: any) => moment.utc([
            value ? value.year : 1,
            value ? value.month - 1 : 0
        ]);

        return {
            min: toMoment(minDateRow),
            max: toMoment(maxDateRow),
        };
    };

    const getDimension = (row: IssueTransactionVolume) => {
        const momentDate = moment.utc([row.year, row.month - 1]);

        switch (filter.grouping) {
            case ChartGrouping.Y:
                return momentDate.format(YearDateFormat);
            case ChartGrouping.Q:
                // Group by first month of each quarter
                return `${momentDate.year()}-${momentDate.quarter() * 3 - 2}`;
            default:
                return momentDate.format(MonthDateFormat);
        }
    };

    const getMetric = (row: IssueTransactionVolume) => {
        switch (filter.units) {
            case ChartUnits.Count:
                return row.numberOfTransactions;
            default:
                return row.dealBalanceTotal;
        }
    };

    const groupData = (data: IssueTransactionVolume[]): IssueTransactionVolume[][] => {
        // Aggregate for year and querter dimensions
        const map = arrayUtils.groupBy(data, (row: IssueTransactionVolume) =>
            `${row.collateralType}-${getDimension(row)}`);

        return Array.from(map.values());
    };

    const getChartRange = () => {
        let { min, max } = getEdgeDimensionValues();

        switch (filter.grouping) {
            case ChartGrouping.M:
                min = max.clone()
                    .subtract(5, 'months')
                    .subtract(15, 'days');
                max.add(15, 'days');
                break;
            case ChartGrouping.Q:
                min = max.clone()
                    .subtract(1, 'years')
                    .startOf('year')
                    .subtract(45, 'days');
                max.startOf('quarter')
                    .add(45, 'days');
                break;
            default: {
                if (max.year() - min.year() >= MaxRangeYears) {
                    min = max.clone()
                        .subtract(MaxRangeYears - 1, 'years')
                        .startOf('year');
                }

                min.startOf('year')
                    .subtract(6, 'months');
                max.startOf('year')
                    .add(6, 'months');
            }
        }

        return [
            min.format(ChartRangeFormat),
            max.format(ChartRangeFormat),
        ];
    };

    const getChartData = () => {
        const grouped = groupData(data);

        const charts = grouped.reduce((acc: Record<CollateralType, any[]>, row) => {
            const [ firstElement ] = row;
            const { collateralType } = firstElement;

            const dimension = getDimension(firstElement);
            const metric = arrayUtils.sum(row, (value: IssueTransactionVolume) => getMetric(value));

            const [x = [], y = []] = acc[collateralType] || [];

            return {
                ...acc,
                [collateralType]: [
                    [...x, dimension],
                    [...y, metric],
                ],
            };
        },
        {
            [CollateralType.broadlySyndicated]: [],
            [CollateralType.middleMarket]: [],
        });

        return Object
            .entries(charts)
            .map(([collateralType, [x, y]], index) => ({
                x,
                y,
                name: collateralTypes[collateralType],
                type: 'bar',
                // This <extra> tag here is on purpose!
                hovertemplate: `${collateralTypes[collateralType]}: %{y}<extra></extra>`,
                marker: {
                    color: barColors[index],
                },
            }));
    };

    const getLayout = () => {
        const { dtick, tickformat } = GroupingTickFormat[filter.grouping];

        const tickprefix = filter.units === ChartUnits.Volume
            ? CurrencyTickPrefix[filter.currencyCode]
            : '';

        const xAxisRange = getChartRange();

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'x unified',
            hoverlabel,
            barmode: filter.view,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                range: xAxisRange,
                tickfont: tickFont,
                fixedrange: true,
                dtick,
                tickformat,
                rangeslider: {
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                tickprefix,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
            },
        }
    };

    const renderChart = () => (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={divId}
            data={getChartData()}
            layout={getLayout()}
            config={mainConfig}
        />
    );

    return (
        <>
            {renderChart()}
            <div className="agenda">
                <div className="high-yield">{collateralTypes[CollateralType.broadlySyndicated]}</div>
                <div className="middle-market">{collateralTypes[CollateralType.middleMarket]}</div>
            </div>
        </>
    );
}

IssueTransactionVolumeChart.defaultProps = {
    divId: 'issue-transaction-volume-chart'
};
