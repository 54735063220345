import { arrayUtils } from "../../../utils";
import { SORT, issuanceMonitorWidget } from "../../../constants";
import { PipelineWidgetColumn } from "../../../types/dashboard/PipelineWidgetColumn";

export function getAvailableYears(minYear = issuanceMonitorWidget.minYear) {
    const currentYear = new Date().getFullYear();
    return arrayUtils.arrayFromRange(minYear, currentYear).reverse();
}

export function getSortingParamsForExport(
    sortBy: string,
    sortDirection: string
) {
    const ascending = sortDirection === SORT.ASC;
    const companySorting = {
        field: "companyLegalName",
        ascending: true,
    };

    if (!sortBy) {
        return [
            {
                field: PipelineWidgetColumn.numberOfTransactions,
                ascending,
            },
            companySorting,
        ];
    }

    if (sortBy === PipelineWidgetColumn.company) {
        return [
            {
                ...companySorting,
                ascending,
            },
        ];
    }

    return [
        {
            field: sortBy,
            ascending,
        },
        companySorting,
    ];
}

export function getSortingParamsForStatisticExport(
    sortBy: string,
    sortDirection: string
) {
    const ascending = sortDirection === SORT.ASC;
    const dealNameSorting = {
        field: PipelineWidgetColumn.dealName,
        ascending: true,
    };

    if (!sortBy) {
        return [dealNameSorting];
    }

    if (sortBy === "dealLegalName") {
        return [
            {
                ...dealNameSorting,
                ascending,
            },
        ];
    }

    return [
        {
            field: sortBy,
            ascending,
        },
        dealNameSorting,
    ];
}
