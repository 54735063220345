import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { PipelineWidgetColumn } from "../../../../types/dashboard/PipelineWidgetColumn";
import { formatUtils } from "../../../../utils";
import { constants } from "../../../../constants";
import { FilterRelatedTransactionsIcon } from '../../../amrPipeline/common/FilterRelatedTransactionsIcon';
import { DetailedPageLink } from "../../../amrPipeline/common/DetailedPageLink";
import { OnHoverTooltip } from "../../../common";
import { ActionBlocker } from "../../../access/ActionBlocker";
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature";

const buyColumnsDefinitions: IColumnDefinition<AmrTransaction | OriginatingTransaction>[] = [
    {
        columnKey: PipelineWidgetColumn.dealName,
        renderColumnHeaderContent: () => 'Deal Name',
        renderColumnContent: (transaction) => (
            <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                {blocked => (
                    <OnHoverTooltip overlay={transaction.dealLegalName} disabled={blocked}>
                        <DetailedPageLink transaction={transaction} disabled={blocked} />
                    </OnHoverTooltip>
                )}
            </ActionBlocker>
        ), 
        headerClassName: 'data-list-cell-xl-flexible',
        bodyClassName: 'data-list-cell-xl-flexible',
        sortingField: 'dealLegalName',
    },
    {
        columnKey: PipelineWidgetColumn.relatedTransactions,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ dealLegalName, dealReferenceName, numberOfDealPublishedTransactions }, context) =>
            numberOfDealPublishedTransactions > 1 &&
                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                    {blocked => (
                        <FilterRelatedTransactionsIcon
                            dealLegalName={dealLegalName}
                            dealReferenceName={dealReferenceName}
                            onClick={context.onListItemClick}
                            disabled={blocked}
                        />
                    )}
                </ActionBlocker>,
        headerClassName: 'data-list-cell-xxxs',
        bodyClassName: 'data-list-cell-xxxs',
    },
    {
        columnKey: PipelineWidgetColumn.collateralType,
        renderColumnHeaderContent: () => 'Collat.',
        renderColumnContent: ({ collateralType }) => formatUtils.formatCollateralType(collateralType),
        headerClassName: 'data-list-cell-xs',
        bodyClassName: 'data-list-cell-xs',
        sortingField: 'collateralType',
    },
    {
        columnKey: PipelineWidgetColumn.currency,
        renderColumnHeaderContent: () => 'Ccy',
        renderColumnContent: ({ currency }) => (currency ? currency.code : constants.emptyPlaceholder),
        headerClassName: 'data-list-cell-xs',
        bodyClassName: 'data-list-cell-xs',
        sortingField: 'currency',
    },
];

export const topStaticDealColumns = buyColumnsDefinitions.map((c) => {
    return new ColumnBuilder(c);
});
