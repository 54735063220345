import React from 'react';
import { DashboardNoData } from './DashboardNoData';

export const DashboardNoSelectedFilters = ({ text = 'No data is available for the selected filters.' }) => (
    <div className="emptyBwics">
        <DashboardNoData
            imgName="no-data2"
            imgWidth={160}
            imgHeight={127}
            text={text}
        />
    </div>
);
