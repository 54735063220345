import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../types/state/AppState';
import { numericUtils } from '../../../utils/numeric.utils';
import { isRequesting } from '../../../utils';
import { DtcIgHyChart } from './DtcIgHyChart';
import { constants } from '../../../constants';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "TRACE Volume";

export function DtcIgHyWidget() {
    const dtcIgHy = useSelector((s: AppState) => s.dashboard.dtcIgHy);
    const requestStateDtcInvestmentGrade = useSelector((s: AppState) => s.dashboard.requestStateDtcInvestmentGrade);

    const data = useMemo(() => dtcIgHy.map(d => {
        const hy = numericUtils.round((d.hyVolume * 1000) / 1000000, 0);
        const ig = numericUtils.round((d.igVolume * 1000) / 1000000, 0);
        const y = { hy, ig };
        const x = moment.utc(d.date).format(constants.dashboardDateFormat);
        return { x, y }
    }), [dtcIgHy]);

    const chart = useMemo(() => <DtcIgHyChart data={data} />, [data]);

    return (
        <DashboardWidget title={chartName} className="hy-ig">
            <DashboardSkeleton inProgress={isRequesting(requestStateDtcInvestmentGrade)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getVantageData}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-trace-volume"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}<span className="date">USD, MM</span></h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateDtcInvestmentGrade}>
                            {chart}
                            <div className="agenda">
                                <div className="ig display-inline">IG</div>
                                <div className="hy display-inline">HY (includes unrated securities)</div>
                            </div>
                            <WidgetDescription
                                id="hy-ig-clamp"
                                lines={2}
                                text="This graph shows the total CLO/CDO trade volumes as reported by FINRA. These figures include all BWIC and out-of-comp trades involving a FINRA member firm."
                            />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
