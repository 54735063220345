import * as React from 'react';
import cn from 'classnames';
import { Contact } from '../../types/company/Contact';
import { DealerContactOptions } from './DealerContactOptions';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { nameUtils } from '../../utils';
import { constants } from '../../constants';

interface DealerContactProps {
    contact: Contact;
    enableContactOptions: boolean;
}

export const DealerContact: React.FC<DealerContactProps> = ({ contact, enableContactOptions }) => {
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(false);
    const brokerDealerList = useSelector((state: AppState) => state.entities.brokerDealerList.items);
    const editableContact = useSelector((state: AppState) => state.dealerList.editableContact.contact);
    const inviteToJoinRequestStatus = useSelector((state: AppState) => state.dealerList.inviteToJoinRequestStatus);

    const handleEditContact = () => {
        const dealerName = brokerDealerList.find(dealer => dealer.id === contact?.companyId)?.name || '';
        dispatch(dealerListActions.setEditableContact({ ...contact }, dealerName))
    };

    const handleDeleteContact = () => {
        dispatch(dealerListActions.deleteContact(contact.id));
    };

    const handleSendInviteToJoin = () => {
        dispatch(dealerListActions.contactInviteToJoin(contact.id))
    };

    const fullName = nameUtils.getFullName(contact.firstName, contact.lastName);

    return (
        <div className={cn('data-table-item-row flex-row', { 'active': editableContact?.id === contact.id || active })}>
            <div className="flex-none data-list-name">
                {contact.invitationSent &&
                    <OnHoverTooltip overlay="Invite Sent"><span className="status not-invited" /></OnHoverTooltip>
                }
                <OnHoverTooltip overlay={(fullName === constants.emptyPlaceholder) ? null : fullName}>
                    <span className="display-inline name text-ellipsis">{fullName}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-title">
                <OnHoverTooltip wrapperClass="title" overlay={contact.role}>
                    <span className="display-inline text-ellipsis dash">{contact.role || constants.emptyPlaceholder}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-email">
                <OnHoverTooltip overlay={contact.email}>
                    <span className="display-inline email text-ellipsis">{<a href={`mailto:${contact.email}`}>{contact.email}</a> || constants.emptyPlaceholder}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-phone">{contact.phone || constants.emptyPlaceholder}</div>
            {
                enableContactOptions &&
                <div className="flex-item-right">
                    <DealerContactOptions
                        onEditContact={handleEditContact}
                        onDeleteContact={handleDeleteContact}
                        canEditContact={!contact.readonly}
                        onSend={handleSendInviteToJoin}
                        sending={inviteToJoinRequestStatus[contact?.id || 0]}
                        onMenuShow={() => setActive(true)}
                        onMenuHide={() => setActive(false)}
                        invitationSent={contact.invitationSent}
                    />
                </div>
            }
        </div>
    )
};
