import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Pagination } from '../tables/Pagination';
import { Table } from '../../bidding/common/table';
import { dateTimeUtils, isRequestSuccess } from '../../../utils';
import { DashboardNoData } from '../DashboardNoData';
import { constants, routes, SORT, SORTING_TYPE } from '../../../constants';
import { useSortedList } from '../../../effects';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { BwicStatusLabel } from '../../common';
import { DashboardPortfolioSecurity } from '../../../types/dashboard/DashboardPortfolioSecurity';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { SameDayBwicIcon } from '../../common/SameDayBwicIcon';
import { OnHoverTooltip } from '../../common';
import { PortfolioAgreementDisclaimerActionBlocker } from '../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerActionBlocker';
import { CreatePortfolioButton } from '../../portfolio-agreement-disclaimer/CreatePortfolioButton';
import { BwicViewLink } from '../../common/BwicViewLink';

export const DashboardPortfolioBwicSecurities = () => {
    const history = useHistory();

    const portfolioSecurities = useSelector((s: AppState) => s.dashboard.portfolioSecurities);
    const portfolioExist = useSelector((s: AppState) => s.dashboard.portfolioExist);
    const requestStateFetchPortfolioSecurities = useSelector((s: AppState) => s.dashboard.requestStateFetchPortfolioSecurities);

    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState(SORT.ASC);

    const [sortedDataItems] = useSortedList(portfolioSecurities, sortBy, sortDirection);

    const getColumns = () => {
        const columnsDefinitions: IColumnDefinition<DashboardPortfolioSecurity>[] = [
            {
                columnKey: 'bwicStatus',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <BwicStatusLabel
                        status={entity.status}
                        liveBidding={entity.openBiddingEnabled}
                        isParsed={entity.isParsed}
                        directBidding={entity.isClearingBankParticipant}
                        isAllToAll={entity.isAllToAll}
                    />
                ),
                headerClassName: 'data-list-bwic-status',
                bodyClassName: 'data-list-bwic-status flex-item-left',
            }, {
                columnKey: 'bwicDate',
                renderColumnHeaderContent: () => 'Date',
                renderColumnContent: entity => entity.date
                    ? <>{!entity.isSameDayBwic && <SameDayBwicIcon />} {dateTimeUtils.utcToLocalString(entity.date, constants.dashboardDateFormat)}</>
                    : constants.emptyPlaceholder,
                headerClassName: 'data-list-date sort-column',
                bodyClassName: 'data-list-date flex-item-left',
                sortingField: 'date',
                sortingType: SORTING_TYPE.date,
            }, {
                columnKey: 'ticker',
                renderColumnHeaderContent: () => 'Ticker',
                renderColumnContent: entity => (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.ticker}>
                        {entity.ticker}
                    </OnHoverTooltip>
                ),
                className: 'data-list-ticker-bwics',
                sortingField: 'ticker'
            }, {
                columnKey: 'viewBwic',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity =>
                    <BwicViewLink
                        small
                        referenceName={entity.bwicReferenceName}
                        status={entity.status}
                        isDirectBidding={entity.isClearingBankParticipant}
                        isAllToAll={entity.isAllToAll}
                        isMy={entity.isMy}
                        isParticipant={entity.isParticipant}
                        isParsed={entity.isParsed}
                    />,
                headerClassName: '',
                bodyClassName: 'flex-item-right'
            }
        ]
        return columnsDefinitions.map(c => new ColumnBuilder(c))
    }

    const setSortParams = (field: string, direction: string) => {
        setSortBy(field);
        setSortDirection(direction)
    };

    const handleClick = () => history.push(routes.newPortfolio);

    return (
        <>
            {!!portfolioSecurities.length && (
                <Pagination list={sortedDataItems} itemsOnPage={7}>
                    {(items: DashboardPortfolioSecurity[]) => (
                        <Table dataItems={items} columns={getColumns()} onSort={setSortParams} />
                    )}
                </Pagination>
            )}
            {isRequestSuccess(requestStateFetchPortfolioSecurities) && !portfolioSecurities.length &&
                <>
                    <div className="emptyBwics">
                        <DashboardNoData
                            imgName="no-portfolios-small"
                            imgWidth={164}
                            imgHeight={128}
                            text={portfolioExist ? 'No securities are coming on a BWIC from your portfolio.' : 'There are no portfolios yet.'}
                        >
                            {!portfolioExist && (
                                <div>
                                    <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                                        <CreatePortfolioButton className="btn btn-main" onClick={handleClick}>
                                            Create Portfolio
                                        </CreatePortfolioButton>
                                    </PortfolioAgreementDisclaimerActionBlocker>
                                </div>
                            )}
                        </DashboardNoData>
                    </div>
                </>
            }
        </>
    )
}
