import { SetStateAction, useState } from 'react';
import { useHistory } from 'react-router';
import { ScheduledBwic } from '../../../types/dashboard/ScheduledBwic';
import { Pagination } from '../tables/Pagination';
import { sellColumns } from '../tables/columns/SellColumns';
import { useSortedList } from '../../../effects';
import { isRequesting, isRequestNone } from '../../../utils';
import { Table } from '../../bidding/common/table';
import { roles, routes, SORT } from '../../../constants';
import { isRequestSuccess } from '../../../utils';
import { ShowFor } from '../../access/ShowFor';
import { FeatureButton } from '../../access/FeatureButton';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { DirectBiddingDisclaimerActionBlocker } from '../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { DashboardNoData } from '../DashboardNoData';
import { RequestState } from '../../../constants/request-state';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';

interface Props {
    myBwics: ScheduledBwic[];
    hideCreateNewBwic?: boolean;
    requestStateFetchBwics: RequestState;
}

export function MyBwics({ myBwics, requestStateFetchBwics, hideCreateNewBwic = false }: Props) {
    const itemsOnPage = 7;
    const history = useHistory();

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(SORT.ASC);
    const [sortedDataItems] = useSortedList(myBwics, sortBy, sortDirection);

    const setSortParams = (field: SetStateAction<null>, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const handleNewBwicClick = () => history.push(routes.newBWIC);

    return (
        <DashboardSkeleton inProgress={isRequestNone(requestStateFetchBwics) || isRequesting(requestStateFetchBwics)}>
            {isRequestSuccess(requestStateFetchBwics) && !myBwics.length ? (
                <div className="emptyBwics">
                    <ShowFor roles={[...roles.seller(), roles.Administrator, roles.DataEntry, roles.Viewer, ...roles.bd()]}>
                        <DashboardNoData
                            imgName="no-data"
                            imgWidth={164}
                            imgHeight={128}
                            text="You don't have any scheduled BWICs."
                        >
                            {!hideCreateNewBwic && (
                                <ShowFor roles={[...roles.seller(), ...roles.bd()]}>
                                    <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                                        <DirectBiddingDisclaimerActionBlocker onConfirm={handleNewBwicClick}>
                                            <div>
                                                <FeatureButton className="btn btn-main" onClick={handleNewBwicClick}>
                                                    Create new bwic
                                                </FeatureButton>
                                            </div>
                                        </DirectBiddingDisclaimerActionBlocker>
                                    </RoleActionBlocker>
                                </ShowFor>
                            )}
                        </DashboardNoData>
                    </ShowFor>
                </div>
            ) : (
                <Pagination list={sortedDataItems} itemsOnPage={itemsOnPage}>
                    {(items: any) => (
                        <Table
                            className="data-list-striped"
                            dataItems={items}
                            columns={sellColumns}
                            onSort={setSortParams}
                        />
                    )}
                </Pagination>
            )}
        </DashboardSkeleton>
    )
}
