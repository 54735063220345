import { constants } from '../../../constants';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ChartGrouping, ChartTypes, ChartUnits, ChartView } from '../../../types/dashboard/AmrChart';
import { Currency } from '../../../types/enums/Currency';
import { ActionBlocker } from '../../access/ActionBlocker';
import { DropDownList } from '../../controls/DropDownList';
import { FilterPanel, FilterSection, FilterSwitch } from '../../filters';

export interface ChartFilter {
    transactionType: TransactionType | string;
    units: ChartUnits;
    grouping: ChartGrouping;
    view: ChartView;
    currencyCode: Currency;
}

interface IssueTransactionVolumeFilterProps extends ChartFilter {
    onChange: (value: ChartFilter) => void;
}

const ViewLabel = {
    [ChartView.Grouped]: 'Grouped',
    [ChartView.Stacked]: 'Stacked',
};

const ChartTypeList = [
    {
        value: constants.allListItem,
        text: 'All',
    },
    ...ChartTypes,
];

export function IssueTransactionVolumeFilter({
    onChange,
    ...values
}: IssueTransactionVolumeFilterProps) {
    const handleValueChange = (prop: string) => (value: any) => {
        let changedFilter = {
            ...values,
            [prop]: value,
        };

        onChange(changedFilter);
    };

    return (
        <FilterPanel>
            <FilterSection>
                <ActionBlocker feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                    {blocked =>
                        <DropDownList
                            items={ChartTypeList.map(({ value, text }) => ({
                                value,
                                text,
                                selected: values.transactionType === value,
                            }))}
                            className="custom-drop-down-ghost"
                            prefix="Type: "
                            disabled={blocked}
                            onChange={({ value }) => handleValueChange('transactionType')(value)}
                        />}
                </ActionBlocker>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[ChartUnits.Volume, ChartUnits.Count].map(unit =>
                        <ActionBlocker key={unit} feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                            {blocked =>
                                <FilterSwitch
                                    caption={unit}
                                    selected={values.units === unit}
                                    disabled={blocked}
                                    onClick={() => handleValueChange('units')(unit)}
                                />}
                        </ActionBlocker>
                    )}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[Currency.USD, Currency.EUR].map(currencyCode =>
                        <ActionBlocker key={currencyCode} feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                            {blocked =>
                                <FilterSwitch
                                    caption={currencyCode}
                                    selected={values.currencyCode === currencyCode}
                                    disabled={blocked}
                                    onClick={() => handleValueChange('currencyCode')(currencyCode)}
                                />}
                        </ActionBlocker>
                    )}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[
                        ChartGrouping.M,
                        ChartGrouping.Q,
                        ChartGrouping.Y,
                    ].map(grouping =>
                        <ActionBlocker key={grouping} feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                            {blocked =>
                                <FilterSwitch
                                    caption={grouping}
                                    selected={values.grouping === grouping}
                                    disabled={blocked}
                                    onClick={() => handleValueChange('grouping')(grouping)}
                                />}
                        </ActionBlocker>
                    )}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[ChartView.Grouped, ChartView.Stacked].map(view =>
                        <ActionBlocker key={view} feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                            {blocked =>
                                <FilterSwitch
                                    caption={ViewLabel[view]}
                                    selected={values.view === view}
                                    disabled={blocked}
                                    onClick={() => handleValueChange('view')(view)}
                                />}
                        </ActionBlocker>
                    )}
                </div>
            </FilterSection>
        </FilterPanel>
    );
}
