import React, { useCallback } from 'react';
import { PlotMouseEvent } from 'plotly.js';
import { useHistory } from 'react-router';
import moment from 'moment';
import { mainConfig, Plot } from '../../common/charts';
import { constants, routes } from '../../../constants';
import { chartUtils, getRatingForSearchString } from '../../../utils';
import {
    currency, dateFrom, dateTo, ratings, statuses
} from '../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../utils/filtering/query-string-serializer';
import { dashboardChartBar } from '../../../constants/dashboard';
import { RatingWithGroup } from '../../../types/dashboard/RatingGroup';
import { QueryStringArgumentSerializer } from '../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { BwicStatus } from '../../../types/enums/BwicStatus';

export interface ChartDataYValues {
    name: RatingWithGroup | string;
    color: string;
    values: number[],
    text: string[]
}

interface ChartData {
    x: string[];
    y: ChartDataYValues[];
    maxValue: number
}

interface ChartBarBwicsVolumeProps {
    chartDiv?: string;
    selectedCurrencies: string[];
    chartData: ChartData;
}

export function ChartBarScheduledBwics({ chartDiv, selectedCurrencies, chartData }: ChartBarBwicsVolumeProps) {
    const history = useHistory();
    const chartYRowStep = 5;
    const chartYroundTo = 10;
    const chartYLowLimitRoundTo = 0.01;
    const { yAxisDTick } = dashboardChartBar;
    const onBarClick = useCallback((e: PlotMouseEvent) => {
        const point = e && e.points && e.points[0];
        const date = moment(String(point.x), constants.dashboardDateFormat).format(constants.formatTimeStringWithoutTimeZone);
        if (point && point.data) {
            const searchParams = {
                statuses: [BwicStatus.scheduled, BwicStatus.bidding, BwicStatus.finished],
                currency: selectedCurrencies.length === 2 ? '' : selectedCurrencies[0],
                dateFrom: date,
                dateTo: date,
                ratings: getRatingForSearchString(point.data.name)
            };
            const serializers: QueryStringArgumentSerializer<any>[] = [ratings(), statuses(), currency([]), dateFrom(), dateTo()];
            const queryString = queryStringSerializer.serialize(searchParams, serializers);
            history.push({
                pathname: routes.allBWICs,
                search: queryString
            });
        }
    }, [selectedCurrencies, history]);

    const round = (a: number, roundTo: number) => {
        return a % roundTo ? a + roundTo - a % roundTo : a
    };

    const isInt = (n: number) => {
        return n % 1 === 0;
    }

    const getYAxesRange = () => {
        const to = chartData.maxValue / 1000000;
        const steppedSlice = to > chartYroundTo ? round(to / chartYRowStep, chartYroundTo) : to / chartYRowStep;
        const cratTo = isInt(steppedSlice) ? steppedSlice * chartYRowStep : round(steppedSlice * chartYRowStep, chartYLowLimitRoundTo);
        const withSteppedSlise = cratTo ? cratTo : yAxisDTick;
        return [0, withSteppedSlise];
    };

    const getDTick = () => {
        const to = getYAxesRange()[1];
        return isInt(to / chartYRowStep) ? to / chartYRowStep: round(to / chartYRowStep, chartYLowLimitRoundTo);
    };

    const getCharts = () => chartData.y
        .map(data => ({
            x: chartData.x,
            y: data.values.map(el => el / 1000000),
            name: data.name,
            type: 'bar',
            textposition: 'none',
            marker: {
                color: data.color,
            },
            hoverinfo: 'text',
            text: data.text || []
        }));

    const charts = getCharts();
    const {
        label, tickColor, tickFont, zeroLineColor, margin, fontSize, xAxisRange, barGap, barGroupGap
    } = dashboardChartBar;

    const layout = {
        showlegend: false,
        barmode: "group",
        autosize: true,
        hovermode: 'closest',
        hoverlabel: {
            bordercolor: label.bordercolor,
            bgcolor: label.bgColor,
            font: {
                color: label.fontColor,
                family: label.fontFamily,
                size: label.size
            }
        },
        font: { size: fontSize },
        bargap: barGap,
        bargroupgap: barGroupGap,
        margin,
        xaxis: {
            range: xAxisRange,
            zeroline: false,
            tickcolor: tickColor,
            tickfont: tickFont,
        },
        yaxis: {
            showgrid: true,
            range: getYAxesRange(),
            mirror: 'ticks',
            dtick: getDTick(),
            showtickprefix: 'none',
            zerolinecolor: zeroLineColor,
            fixedrange: true,
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
        },
    };

    return (
        <Plot
            onInitialized={chartUtils.setCursorByDiv(chartDiv, 'crosshair')}
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            onClick={onBarClick}
            data={charts}
            layout={layout}
            config={mainConfig}
        />
    );
}

ChartBarScheduledBwics.defaultProps = {
    chartDiv: 'dashboard-bars-chart'
};
