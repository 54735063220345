import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../types/state/AppState";
import { isRequesting } from "../../../utils";
import { SORT, SORTING_TYPE, constants, issuanceMonitorWidget } from "../../../constants";
import { Table } from "../../bidding/common/table";
import { topArrangersColumns } from "./columns/TopArrangersColumns";
import { Pagination } from "../tables/Pagination";
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from "../widget";
import { DashboardSkeleton } from "../DashboardSkeleton";
import {
    dashboardFetchTopArrangers,
    dashboardExportTopArrangers,
} from "../../../actions/dashboard.actions";
import { useDispatch } from "react-redux";
import { TopChartCompany } from "../../../types/dashboard/TopChartCompany";
import { getAvailableYears, getSortingParamsForExport } from "./top-list.utils";
import { YearSelector } from "../../controls/YearSelector";
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { useSortedList } from "../../../effects";
import { ShowFor } from "../../access";
import { DropDownList, DropDownListItem } from "../../controls/DropDownList";
import ExportButton from "../../amrPipeline/common/ExportButton";
import { PipelineWidgetColumn } from "../../../types/dashboard/PipelineWidgetColumn";
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { CollateralTypesList } from "../../../constants/dashboard/collateral-types";
import { amrChartUtils } from "../../../utils/amr-chart.utils";
import { ChartRequiredFeature } from "../../access/ChartRequiredFeature";
import { TopIssuersCurrencyTypes, TopIssuersTransactionTypes } from './constants';

const availableYears: number[] = getAvailableYears();

const getFilterValue = (value: string) =>
    value === constants.allListItem ? undefined : value;

interface Props {
    onListItemClick?: () => void;
}

export function TopArrangersWidget({ onListItemClick }: Props) {
    const [type, setType] = useState(constants.allListItem);
    const [year, setYear] = useState<number | undefined>(availableYears[0]);
    const [currency, setCurrency] = useState(constants.allListItem);
    const [collateralType, setCollateralType] = useState(CollateralTypesList[0].value);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState(SORT.DESC);

    const topArrangers = useSelector((s: AppState) => s.dashboard.topArrangers);

    const isSortByCompany = sortBy === PipelineWidgetColumn.company;
    const sortingType = isSortByCompany ? SORTING_TYPE.company : '';
    const [sortedDataItems] = useSortedList(topArrangers, sortBy, sortDirection, sortingType);

    const withSizeColumn = type !== constants.allListItem && currency !== constants.allListItem;

    const requestStateFetchTopArrangers = useSelector(
        (s: AppState) => s.dashboard.requestStateFetchTopArrangers
    );
    const requestStateExportTopArrangers = useSelector(
        (s: AppState) => s.dashboard.requestStateExportTopArrangers
    );

    const hasAccess = hasWidgetsAccess(SubscriptionFeature.Top10ArrangersTable);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasAccess) {
            return;
        }

        dispatch(
            dashboardFetchTopArrangers(
                getFilterValue(type),
                getFilterValue(currency),
                year,
                getFilterValue(collateralType)
            )
        );
        setPage(1);
    }, [dispatch, hasAccess, type, currency, year, collateralType]);

    const columnContext = () => ({
        onListItemClick,
        currency,
    });

    const handleTypeChange = ({ value }: DropDownListItem) => setType(value.toString());
    const handleCurrencyChange = ({ value }: DropDownListItem) => setCurrency(value.toString());
    const handleYearChange = (yearValue?: number) => setYear(yearValue);

    const handleSortChange = (field: string, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const handleExportChart = () =>
        dispatch(
            dashboardExportTopArrangers(
                getFilterValue(type),
                getFilterValue(currency),
                year,
                getFilterValue(collateralType),
                getSortingParamsForExport(sortBy, sortDirection),
            )
        );
    const onPageChanged = (selectedPage: number) => setPage(selectedPage);

    const tableColumns = withSizeColumn
        ? topArrangersColumns.filter(c => c.column.columnKey !== PipelineWidgetColumn.emptySize)
        : topArrangersColumns.filter(c => c.column.columnKey !== PipelineWidgetColumn.dealBalanceTotal);

    const renderTable = () =>
        sortedDataItems.length > issuanceMonitorWidget.itemsOnPage ? (
            <Pagination
                list={sortedDataItems}
                itemsOnPage={issuanceMonitorWidget.itemsOnPage}
                currentPage={page}
                onPageChanged={onPageChanged}
            >
                {(items: TopChartCompany[]) => (
                    <Table
                        onSort={handleSortChange}
                        defaultSortBy={sortBy}
                        defaultSortByDirection={sortDirection}
                        dataItems={items}
                        columns={tableColumns}
                        createSecurityCustomArgs={columnContext}
                        className="data-list-striped"
                    />
                )}
            </Pagination>
        ) : (
            <Table
                onSort={handleSortChange}
                defaultSortBy={sortBy}
                defaultSortByDirection={sortDirection}
                dataItems={sortedDataItems}
                columns={tableColumns}
                createSecurityCustomArgs={columnContext}
                className="data-list-striped"
            />
        );

    const chartName = "Top-10 Arrangers";

    return (
        <DashboardWidget title={chartName} className="top-arrangers">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchTopArrangers)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.Top10ArrangersTable}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-top-arrangers"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                        <ShowFor feature={SubscriptionFeature.Top10ArrangersTable}>
                            <div className="flex-item-right">
                                <ExportButton
                                    isExportLoad={isRequesting(requestStateExportTopArrangers)}
                                    handleExport={handleExportChart}
                                    disabled={!topArrangers.length}
                                />
                            </div>
                            <div className="flex-item-right control-box">
                                <div className="control-item">
                                    <DropDownList
                                        items={TopIssuersTransactionTypes.map(({ value, text }) => ({
                                            value,
                                            text,
                                            selected: value === type,
                                        }))}
                                        onChange={handleTypeChange}
                                        className="custom-drop-down-ghost"
                                        prefix="Type: "
                                    />
                                </div>
                                <div className="control-item ccy-box">
                                    <DropDownList
                                        items={TopIssuersCurrencyTypes.map(({ value, text }) => ({
                                            value,
                                            text,
                                            selected: value === currency,
                                        }))}
                                        onChange={handleCurrencyChange}
                                        className="custom-drop-down-ghost"
                                        defaultSortingEnabled={false}
                                        prefix="Ccy: "
                                    />
                                </div>
                                <div className="control-item">
                                    <DropDownList
                                        items={CollateralTypesList.map(x => ({
                                            ...x,
                                            selected: collateralType === x.value,
                                        }))}
                                        defaultSortingEnabled={false}
                                        onChange={({ value }) => setCollateralType(value as string)}
                                        renderSelectedItemCallback={amrChartUtils.renderSelectedFilterOptionWithPrefix('Collat.')}
                                        className="custom-drop-down-ghost"
                                    />
                                </div>
                                <div className="control-item">
                                    <YearSelector
                                        years={availableYears}
                                        selectedYear={year}
                                        currentYearPrefix="YTD"
                                        onChange={handleYearChange}
                                        prefix="Year: "
                                        showAllOption
                                    />
                                </div>
                            </div>
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchTopArrangers}
                        description={
                            !!sortedDataItems?.length
                                ? 'All statistics are calculated based on the transaction pricing date.'
                                : ''
                        }
                    >
                        {sortedDataItems.length ? renderTable() : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
