import React from 'react';
import { useDispatch } from 'react-redux';
import { inviteToJoinActions } from '../../../actions';
import { InviteToJoinPopup } from './InviteToJoinPopup';
import { FeatureButton } from '../../access/FeatureButton';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { roles } from '../../../constants';

export function InviteToJoinAllButton({ disabled = true }) {
    const dispatch = useDispatch();

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <InviteToJoinPopup />
            <RoleActionBlocker roles={[roles.SellerTrader]}>
                {
                    blocked =>
                        <OnHoverTooltip
                            overlay="All contacts from the address book were invited to join the KTX ATS Platform"
                            disabled={!disabled || blocked}
                        >
                            <FeatureButton
                                disabled={disabled}
                                className="btn btn-ghost invite-to-join"
                                onClick={() => dispatch(inviteToJoinActions.setVisiblePopup(true))}
                            >
                                Invite to Join
                            </FeatureButton>
                        </OnHoverTooltip>
                }
            </RoleActionBlocker>
        </div>
    )
}
