import React, { useContext } from 'react';
import { ClampLines } from '../common/Clamp';
import { DashboardWidgetContext } from './widget';
import IconSVG from "../../styles/svg-icons";

interface WidgetDescriptionProps {
    text: string;
    id: string;
    lines: number;
}

export function WidgetDescription({ text, id, lines }: WidgetDescriptionProps) {
    const { setDescriptionExpanded, descriptionExpanded } = useContext(DashboardWidgetContext);
    return (
        <div className="description">
            <ClampLines
                text={text}
                id={id}
                lines={lines}
                moreText={<><IconSVG name="corner-arrow" width={16} height={16} />Show description</>}
                lessText={<><IconSVG className="is-open" name="corner-arrow" width={16} height={16} />Hide description</>}
                innerElement="div"
                onClick={() => setDescriptionExpanded(!descriptionExpanded)}
            />
        </div>
    )
}
