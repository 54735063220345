import React, { useCallback } from 'react';
import Confirm from '../alerts/Confirm';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { companyStatuses } from '../../constants';
import { dealersSelector } from '../../selectors/dealers.selector';
import { useBrokerDealerUsers } from '../../effects/useBrokerDealerUsers';
import { useSettlementAgentAgreements } from '../../effects/useSettlementAgentAgreements';
import { dealersActions } from '../../actions';
import { useAppDispatch } from '../../effects/useAppDispatch';

const ConfirmRequestToBidPopup: React.FC = () => {
    const dispatch = useAppDispatch();

    const confirmRequestToBidPopupState = useSelector((s: AppState) => s.dealers.confirmRequestToBidPopup);
    const isRequestingSendRequestToBidRequest = useSelector((s: AppState) => s.dealers.requestToBidRequestState);
    const brokerDealersState = useSelector(dealersSelector);

    const brokerDealerUsers = useBrokerDealerUsers();
    const settlementAgentAgreements = useSettlementAgentAgreements();

    const getDealers = useCallback(() => brokerDealersState.filter((d) => (
        d.isClearingBank && (
            confirmRequestToBidPopupState.dealerId
                ? d.id === confirmRequestToBidPopupState.dealerId
                : (
                    d.status === companyStatuses.active.statusCode &&
                    brokerDealerUsers.items.some(contact => contact.companyId === d.id) &&
                    !settlementAgentAgreements.items.some(agreement => agreement.settlementAgent.id === d.id)
                )
        )
    )), [brokerDealersState, brokerDealerUsers.items, settlementAgentAgreements.items, confirmRequestToBidPopupState.dealerId]);

    const handleConfirm = () => {
        const dealers = getDealers();
        let clearingBankId;
        if(process.env.REACT_APP_SEND_REQUEST_TO_CUSTOM_SETTLEMENT_AGENT_ENABLED === 'true' && dealers && dealers.length === 1) {
            clearingBankId = dealers[0].id;
        }
        dispatch(dealersActions.requestToBid(clearingBankId));
    };

    return (
        <Confirm
            onConfirm={handleConfirm}
            onCancel={() => dispatch(dealersActions.hideBidRequestConfirmPopup())}
            TextComponent={() =>(
                <>
                    <span>Request to bid will be sent to the following companies:</span>
                    <ul className="list-bullet grey">
                        {
                            getDealers().map(c => (
                                <li key={c.id}>{c.name}</li>
                                ))
                        }
                    </ul>
                    <span>Do you want to complete sending the request?</span>
                </>
            )}
            disabled={isRequestingSendRequestToBidRequest}
        />
    )
};

export const ConfirmRequestToBid: React.FC = () => {
    const visibleConfirmRequestToBidPopup = useSelector((s: AppState) => s.dealers.confirmRequestToBidPopup.visible);
    if (visibleConfirmRequestToBidPopup) {
        return <ConfirmRequestToBidPopup />
    }
    return null;
};
