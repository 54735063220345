import { useEffect, useRef } from "react";
import { News } from "../../../types/news/News";
import { KWatchNewsListItem } from "./KWatchNewsListItem";
import Slider from "react-slick";
import { constants } from '../../../constants';

const speed = 2000;
const autoplaySpeed = 5000;
const slidesToShow = constants.maxCountNewsOnSlider;

interface Props {
    data: News[];
}

export const KWatchNewsList = ({ data }: Props) => {
    const sliderRef = useRef<Slider>(null);
    const sliderStateRef = useRef({
        isHovered: false,
        slideIndex: 0,
    });

    const lastScrolledIndex = data.length - slidesToShow;

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow,
        slidesToScroll: slidesToShow,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: autoplaySpeed + speed,
        speed,
        afterChange: (index: number) => {
            const sliderRefCurrent = sliderRef.current;
            const sliderStateRefCurrent = sliderStateRef.current;

            sliderStateRefCurrent.slideIndex = index;
            if (
                index === lastScrolledIndex &&
                !sliderStateRefCurrent.isHovered
            ) {
                setTimeout(() => {
                    if (sliderRefCurrent) {
                        sliderRefCurrent.slickGoTo(0);
                    }
                }, autoplaySpeed);
            }
        },
    };

    useEffect(() => {
        const slickListDiv = document.getElementsByClassName(
            "slick-list"
        )[0] as HTMLElement;

        if (slickListDiv) {
            const handleWheel = (event: WheelEvent) => {
                event.preventDefault();
    
                const nextIndex =
                    event.deltaY > 0
                        ? sliderStateRef.current.slideIndex + slidesToShow
                        : sliderStateRef.current.slideIndex - slidesToShow;
    
                return sliderRef.current?.slickGoTo(nextIndex);
            };
    
            slickListDiv.addEventListener("wheel", handleWheel);
    
            return () => slickListDiv.removeEventListener("wheel", handleWheel);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMouseEnter = () => (sliderStateRef.current.isHovered = true);
    const handleMouseLeave = () => {
        const sliderRefCurrent = sliderRef.current;
        const sliderStateRefCurrent = sliderStateRef.current;

        sliderStateRefCurrent.isHovered = false;
        if (
            sliderStateRefCurrent.slideIndex === lastScrolledIndex &&
            sliderRefCurrent
        ) {
            sliderRefCurrent.slickNext();
        }
    };

    const renderNews = () => {
        return data.map((n) => (
            <KWatchNewsListItem key={n.referenceName} newsItem={n} />
        ))
    }

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {data.length <= constants.maxCountNewsOnSlider ? 
                renderNews()
             : (
                <Slider ref={sliderRef} {...settings}>
                    {renderNews()}
                </Slider>
            )}
        </div>
    );
};
