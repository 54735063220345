import React from 'react';
import { Plot, mainConfig } from '../../common/charts';
import { numericUtils, chartUtils } from '../../../utils';
import { PriceLevelData } from '../../../types/dashboard/PriceLevel';
import { chartsPriceLevel } from '../../../constants/dashboard/chartsPriceLevel';
import { priceLevelBarColors } from '../../../constants/dashboard/priceLevelBarsColors';
import { ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';

interface ChartProps {
    chartDiv: string;
    priceLevelData: PriceLevelData[];
}

export function PriceLevelChart({ chartDiv, priceLevelData }: ChartProps) {

    const getDataByRating = (rating: string) => {
        const filteredData = priceLevelData.filter(d => d.rating === rating);
        return filteredData.length ? filteredData.map(d => d.normalizedLevel) : [-1]
    };

    const getHoverInfoText = (rating: string) => {
        const data = priceLevelData.filter(d => d.rating === rating);
        const values = data.map(d => d.normalizedLevel);
        const averageColorValue = values.reduce((a, b) => (a + b), 0) / data.length;
        const arrayStats = chartUtils.getArrayStats(values);
        return `Number of trades w/color: ${data.length} <br />` +
            `Color: ${numericUtils.round(averageColorValue)} <br />` +
            `Q1: ${numericUtils.round(arrayStats.q3) || ''} <br />` +
            `Med: ${numericUtils.round(arrayStats.median) || ''} <br />` +
            `Q3: ${numericUtils.round(arrayStats.q1) || ''}`;
    };

    const boxData = ratingWithGroupValues.map((r, index) => {
        const arrayStats = chartUtils.getArrayStats(getDataByRating(r));
        return {
            q1: [arrayStats.q1],
            q3: [arrayStats.q3],
            median: [arrayStats.median],
            lowerfence: [arrayStats.min],
            upperfence: [arrayStats.max],
            x: [ratingWithGroupValues[index]],
            exclusive: getDataByRating(r),
            type: 'box',
            name: getHoverInfoText(r),
            marker: {
                color: priceLevelBarColors[r].border
            },
            fillcolor: priceLevelBarColors[r].bar,
            hoverinfo: 'name',
            boxpoints: false,
        }
    });

    const getYRange = () => {
        const defaultMinValue = -0.1;
        const defaultMinMargin = 0.01;
        const defaultMaxvalue = 1;
        const marginInPercent = 4;
        const linesInChart = 5;
        const valuesForRange = ratingWithGroupValues.reduce((result: number[], rating) => {
            return result.concat(getDataByRating(rating).filter(r => r >= 0))
        }, []);
        let minValue = valuesForRange.length ? Math.min(...valuesForRange) : defaultMinValue;
        let maxValue = valuesForRange.length ? Math.max(...valuesForRange) : defaultMaxvalue;
        const difference = maxValue - minValue;
        let roundTo = defaultMaxvalue;
        if (difference > linesInChart && difference < (linesInChart * 2)) {
            roundTo = linesInChart
        } else if (difference >= (linesInChart * 2) && difference < (linesInChart * 10)) {
            roundTo = linesInChart * 2
        } else if (difference >= (linesInChart * 10) && difference < (linesInChart * 50)) {
            roundTo = linesInChart * 4
        } else if (difference >= (linesInChart * 50)){
            roundTo = linesInChart * 10
        }
        minValue = chartUtils.roundToNumber(minValue, roundTo, true);
        maxValue = chartUtils.roundToNumber(maxValue, roundTo);
        let margin = numericUtils.getPercentage(difference || marginInPercent, marginInPercent);
        if (margin < defaultMinMargin) {
            margin = defaultMinMargin
        }

        return [
            minValue <= 0 ? defaultMinValue : minValue - margin,
            maxValue + margin
        ]
    };

    return (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={boxData}
            layout={{
                xaxis: {
                    showgrid: false,
                    tickcolor: chartsPriceLevel.tickColor,
                    tickfont: chartsPriceLevel.tickFont,
                },
                yaxis: {
                    rangemode: 'tozero',
                    ticks: 'inside',
                    fixedrange: true,
                    tickcolor: chartsPriceLevel.tickColor,
                    tickfont: chartsPriceLevel.tickFont,
                    range: getYRange(),
                    zerolinecolor: chartsPriceLevel.layoutXAxisLineColor
                },
                hoverlabel: chartsPriceLevel.hoverlabel,
                showlegend: false,
                hovermode: 'closest',
                margin: chartsPriceLevel.margins
            }}
            config={mainConfig}
        />
    );
}

PriceLevelChart.defaultProps = {
    chartDiv: 'price-level-chart'
};
