import React from 'react'
import classNames from 'classnames';

interface DashboardWidgetMaximizedHeaderProps {
    children: React.ReactNode;
    className?: string;
}

export function DashboardWidgetHeader({ children, className }: DashboardWidgetMaximizedHeaderProps) {
    return (
        <div className={classNames('heading flex-none', className)}>
            {children}
        </div>
    )
}
