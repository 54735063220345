import React from 'react';
import { ChartGrouping, ChartUnits } from '../../../types/dashboard/AmrChart';
import { FilterPanel, FilterSection, FilterSwitch } from '../../filters';

export interface UsBslEsgNewIssueChartFilter {
    grouping: ChartGrouping;
    units: ChartUnits;
}

interface IssueTransactionVolumeFilterProps extends UsBslEsgNewIssueChartFilter {
    onChange: (value: UsBslEsgNewIssueChartFilter) => void;
}

export function UsBslEsgNewIssueFilter({
    onChange,
    ...values
}: IssueTransactionVolumeFilterProps) {
    const handleValueChange = (prop: string) => (value: any) => {
        onChange({
            ...values,
            [prop]: value,
        });
    };

    return (
        <FilterPanel>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[ChartUnits.Volume, ChartUnits.Count].map(unit =>
                        <FilterSwitch
                            key={unit}
                            caption={unit}
                            selected={values.units === unit}
                            disabled={false}
                            onClick={() => handleValueChange('units')(unit)}
                        />
                    )}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[
                        ChartGrouping.M,
                        ChartGrouping.Q,
                        ChartGrouping.Y,
                    ].map(grouping =>
                        <FilterSwitch
                            key={grouping}
                            caption={grouping}
                            selected={values.grouping === grouping}
                            disabled={false}
                            onClick={() => handleValueChange('grouping')(grouping)}
                    />)}
                </div>
            </FilterSection>
        </FilterPanel>
    );
}
