import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { BarColorChart } from './BarColorChart';
import { getRatingForSearchString, getVolume, isRequesting } from '../../../utils';
import { constants, routes, chartColorBar } from '../../../constants';
import { dateFrom, dateTo, ratings, currency } from '../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../utils/filtering/query-string-serializer';
import { Rating, Ratings } from '../../../types/enums/Rating';
import { RatingGroup, ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { Currencies } from '../../../types/enums/Currency';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "Color % in BWICs: Transparency Barometer";

export function BarColorWidget() {
    const history = useHistory();
    const colorRates = useSelector((s) => s.dashboard.colorRates);
    const requestStateFetchColorRates = useSelector((s) => s.dashboard.requestStateFetchColorRates);
    const filter = useSelector((s) => s.dashboard.filter);
    const filterActive = useSelector((s) => s.dashboard.filterActive);

    const { selectedCurrencies, selectedRatings } = filter;
    const getCurrentRatings = () => {
        if (selectedRatings.length) {
            return ratingWithGroupValues.filter(r => selectedRatings.some(sr => sr === r));
        }
        return ratingWithGroupValues;
    };
    const getCurrentCurrencies = () => {
        if (selectedCurrencies.length) {
            return Currencies.filter(c => selectedCurrencies.some(sc => sc === c));
        }
        return Currencies;
    };

    const getPercent = (amount, count) => {
        if (!amount || !count) {
            return 0;
        }
        return ((amount / count) * 100);
    };

    const getAmountVolume = (amountMoneyInCurrency) => {
        const { currencySign } = chartColorBar;
        const currenciesKeys = Object.keys(amountMoneyInCurrency);
        return currenciesKeys.map(c => `${currencySign[c]}${getVolume(amountMoneyInCurrency[c])}`).join(' / ') + 'MM';
    };

    const getAmountByDay = (ratesList) => {
        const getCurrencies = () => {
            return getCurrentCurrencies().reduce((result, currency) => {
                result[currency] = 0;
                return result;
            }, {});
        };

        const total = ratesList.reduce((result, current) => {
            result.count += current.main.length;
            const { amountInColor, amountMoneyInCurrency } = current.main.reduce((mainResult, current) => {
                mainResult.amountInColor += current.priceInColor;
                if (current.priceInColor) mainResult.amountMoneyInCurrency[current.currency] += current.amount;
                return mainResult;
            }, { amountInColor: 0, amountMoneyInCurrency: getCurrencies() });
            result.amountInColor += amountInColor;
            if (result.amountMoneyInCurrency.USD || result.amountMoneyInCurrency.USD === 0) result.amountMoneyInCurrency.USD += amountMoneyInCurrency.USD;
            if (result.amountMoneyInCurrency.EUR || result.amountMoneyInCurrency.EUR === 0) result.amountMoneyInCurrency.EUR += amountMoneyInCurrency.EUR;
            return result;
        }, { count: 0, amountInColor: 0, amountMoneyInCurrency: getCurrencies() });

        return {
            amountPercent: getPercent(total.amountInColor, total.count),
            amountMoneyInCurrency: total.amountMoneyInCurrency,
            amountMoney: getAmountVolume(total.amountMoneyInCurrency)
        };
    };

    const sortByDates = () => {
        const dates = [];
        const sortedByDates = colorRates.reduce((result, current) => {
            if (!Object.keys(result).some((d) => d === current.date)) {
                result[current.date] = {
                    ratings: getCurrentRatings().map(r => ({
                        rating: r,
                        main: [],
                    })),
                    amountPercent: 0,
                    amountMoneyInCurrency: 0,
                };
                dates.push(current.date);
            }
            // Filter by selected ratings
            if (selectedRatings.length) {
                if (current.rating === Rating.CCC || current.rating === Rating.D) {
                    if (!selectedRatings.some(r => r === RatingGroup.OTHER)) {
                        return result;
                    }
                } else if (!selectedRatings.some(r => r === current.rating)) {
                    return result;
                }
            }
            // Filter by selected currencies
            if (selectedCurrencies.length && !selectedCurrencies.some(c => c === current.currency)) {
                return result;
            }

            let currentRating = current.rating;
            if (current.rating === Rating.CCC || current.rating === Rating.D) {
                currentRating = RatingGroup.OTHER;
            }

            result[current.date].ratings.map(item => {
                if (item.rating === currentRating) {
                    item.main.push(current);
                }
                return item;
            });
            return result;
        }, {});
        return { dates, sortedByDates };
    };

    const getChartData = () => {
        const { dates, sortedByDates } = sortByDates();
        const data = dates.sort((a, b) => a.localeCompare(b)).reduce((result, date) => {
            const dateData = sortedByDates[date];
            if (!result[date]) {
                result[date] = {};
            }
            const { amountPercent, amountMoneyInCurrency, amountMoney } = getAmountByDay(dateData.ratings);
            result[date].amountPercent = amountPercent;
            result[date].amountMoneyInCurrency = amountMoneyInCurrency;
            result[date].amountMoney = amountMoney;
            result[date].ratings = dateData.ratings;
            return result;
        }, {});
        return { dates, data };
    };

    const handleBarClick = useCallback((e) => {
        const point = e && e.points && e.points[0];
        const date = moment(point.x, constants.dashboardDateFormat).format(constants.formatTimeStringWithoutTimeZone);
        const searchRatings = selectedRatings.reduce((result, current) => {
            const rating = getRatingForSearchString(current);
            result = result.concat(rating);
            return result;
        }, []);
        const searchParams = {
            dateFrom: date,
            dateTo: date,
            currency: selectedCurrencies.length === 2 ? '' : selectedCurrencies[0],
            ratings: searchRatings.length && searchRatings.length !== Ratings.length ? searchRatings : []
        };
        const serializers = [ratings(), currency(), dateFrom(), dateTo()];
        const queryString = queryStringSerializer.serialize(searchParams, serializers);
        history.push({
            pathname: routes.allBWICs,
            search: queryString
        });
    }, [history, selectedCurrencies, selectedRatings]);

    const chartData = getChartData();

    const chart = useMemo(() => <BarColorChart chartData={chartData} onClick={handleBarClick} />,
        [chartData, handleBarClick]);

    return (
        <DashboardWidget title={chartName} className="color-rates" filterActive={filterActive}>
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchColorRates)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getColorRates}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-color-percent-in-bwics"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateFetchColorRates}>
                        {chart}
                        <WidgetDescription
                            id="color-rates-clamp"
                            lines={2}
                            text='Color % in BWICs indicates the percentage of BWIC trades where color with price information was provided. Price information can come in the form of either a handle or the exact level of the cover (second-best) or best bid; a simple "Traded" or "DNT" does not count. When this metric is low, it indicates poor market transparency, which is generally seen in illiquid markets. For context, the 2015-2019 average BWIC Color % was about 60%.'
                        />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
