import React from 'react';
import { mainConfig, Plot } from '../../common/charts';
import { chartDtcIgHyConstants } from '../../../constants/dashboard';
import { numericUtils } from '../../../utils/numeric.utils';
import { chartUtils } from '../../../utils/chart.utils';
import { moneyUtils } from '../../../utils/money.utils';

interface ChartData {
    x: string;
    y: {
        hy: number,
        ig: number,
    };
}

interface ChartDtcIgHyChartProps {
    data: ChartData[]
    chartDiv?: string;
}

export function DtcIgHyChart({ data, chartDiv }: ChartDtcIgHyChartProps) {
    const formatChartMoney = (value: number) => `$${moneyUtils.money(value)}MM`;

    const createPlotData = () => {
        const { igBarColor, hyBarColor, barWidth } = chartDtcIgHyConstants;
        const x = data.map(d => d.x);
        const yIg = data.map(d => d.y.ig);
        const yHy = data.map(d => d.y.hy);
        const width = data.map(() => barWidth);
        return [
            {
                x,
                y: yIg,
                marker: { color: igBarColor },
                type: 'bar',
                name: 'Bar Ig',
                hoverinfo: 'text',
                textposition: 'none',
                text: data.map(d => getBarText(d)),
                width
            },
            {
                x,
                y: yHy,
                marker: { color: hyBarColor },
                type: 'bar',
                name: 'Bar Hy',
                hoverinfo: 'text',
                textposition: 'none',
                text: data.map(d => getBarText(d)),
                width
            },
        ]
    };

    const getBarText = (pointData: any) => {
        return `IG Volume: ${formatChartMoney(pointData.y.ig)} <br />HY Volume: ${formatChartMoney(pointData.y.hy)}`
    };

    const plotData = createPlotData();

    const getYAxesMaxValue = () => {
        let maxValue = 0;
        data.forEach(d => {
            const total = d.y.ig + d.y.hy;
            if (total > maxValue) {
                maxValue = total
            }
        });
        return maxValue
    };

    const getYAxesRange = () => {
        const { yAxesMaxValueLineMargin } = chartDtcIgHyConstants;
        const maxValue = getYAxesMaxValue();

        let roundTo = 500;
        if (maxValue < roundTo) {
            roundTo = 100; 
        }

        const roundMaxValue = chartUtils.roundToNumber(maxValue, roundTo);
        return [0, roundMaxValue + numericUtils.getPercentage(roundMaxValue, yAxesMaxValueLineMargin)];
    };

    const annotations = data.map((d) => {
        const { annotationMargin, annotationsFont } = chartDtcIgHyConstants;
        const total = d.y.ig + d.y.hy;
        const maxValue = getYAxesMaxValue();
        return {
            x: d.x,
            y: total + numericUtils.getPercentage(maxValue, annotationMargin),
            text: moneyUtils.money(total),
            arrowhead: 0,
            ax: 0,
            ay: 0,
            font: annotationsFont
        }
    });

    const layout = {
        barmode: 'stack',
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        annotations,
        margin: chartDtcIgHyConstants.margin,
        hoverlabel: chartDtcIgHyConstants.hoverlabel,
        xaxis: {
            tickcolor: chartDtcIgHyConstants.tickColor,
            tickfont: chartDtcIgHyConstants.font,
        },
        yaxis: {
            separatethousands: true,
            zerolinecolor: chartDtcIgHyConstants.tickColor,
            range: getYAxesRange(),
            gridcolor: chartDtcIgHyConstants.tickColor,
            ticks: 'inside',
            tickcolor: chartDtcIgHyConstants.tickColor,
            tickfont: chartDtcIgHyConstants.font,
        },
    };

    return (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={plotData}
            layout={layout}
            config={mainConfig}
        />
    )
}

DtcIgHyChart.defaultProps = {
    chartDiv: 'dtc-ig-hy-chart'
};
