import { useMemo } from 'react';
import { constants } from '../../../constants';
import { CollateralType } from '../../../types/amr-pipeline/enums/CollateralType';
import { FloaterIndex } from '../../../types/amr-pipeline/enums/FloaterIndex';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { ChartGrouping, ChartTypes, Term } from '../../../types/dashboard/AmrChart';
import { Currency } from '../../../types/enums/Currency';
import { Rating } from '../../../types/enums/Rating';
import { DropDownList, DropDownListItem } from '../../controls/DropDownList';
import { FilterPanel, FilterSection } from '../../filters';

export interface IssuanceSpreadChartFilter {
    transactionType: TransactionType | string;
    collateralType: CollateralType;
    rating: Rating;
    currencyCode: Currency;
    grouping: ChartGrouping;
    term: Term | string;
    floaterIndex: FloaterIndex | string;
}

interface IssuanceSpreadFilterProps extends IssuanceSpreadChartFilter {
    onChange: (value: IssuanceSpreadChartFilter) => void;
    availableGroupings?: ChartGrouping[];
    withAllTermsOption?: boolean;
}

const ChartTypeList = [
    {
        value: constants.allListItem,
        text: 'All',
    },
    ...ChartTypes,
];

const FloaterIndexList = [
    {
        value: constants.allListItem,
        text: 'All',
    },
    ...[
        FloaterIndex.Fixed,
        FloaterIndex.Sofr,
        FloaterIndex.Libor,
        FloaterIndex.Euribor
    ].map(floaterIndex => ({
            text: floaterIndex,
            value: floaterIndex,
    })),
];

const RatingsList = [Rating.AAA, Rating.AA, Rating.A, Rating.BBB, Rating.BB]
    .map(rating => ({
        text: rating,
        value: rating,
    }));

const CollateralTypesList = [
    {
        text: 'BSL',
        value: CollateralType.broadlySyndicated,
    },
    {
        text: 'MM',
        value: CollateralType.middleMarket,
    }
];

const AllTermsItem = {
    text: 'All',
    value: constants.allListItem,
    payload: ''
};

const TermsList = [
    {
        text: Term.Short,
        value: Term.Short,
        payload: 'Short Dated',
    },
    {
        text: Term.Long,
        value: Term.Long,
        payload: 'Long Dated',
    }
];

const PeriodsList = [
    {
        text: ChartGrouping.W,
        value: ChartGrouping.W,
        payload: 'Week',
    },
    {
        text: ChartGrouping.M,
        value: ChartGrouping.M,
        payload: 'Month',
    },
    {
        text: ChartGrouping.Q,
        value: ChartGrouping.Q,
        payload: 'Quarter',
    },
    {
        text: ChartGrouping.Y,
        value: ChartGrouping.Y,
        payload: 'Year',
    },
];

const CurrencyList = [Currency.USD, Currency.EUR]
    .map(currency => ({
        text: currency,
        value: currency,
    }));

export function IssuanceSpreadFilter({
    onChange,
    availableGroupings = Object.values(ChartGrouping),
    withAllTermsOption = false,
    ...values
}: IssuanceSpreadFilterProps) {
    const handleValueChange = (prop: keyof IssuanceSpreadChartFilter) => (value: any) => {
        onChange({
            ...values,
            [prop]: value,
        });
    };

    const renderSelectedWithPrefix = (prefix: string) =>
        (item: DropDownListItem) => `${prefix}: ${item.text}`;

    const renderListItemWithDescription = (item: DropDownListItem) => (
        <div className="">
            <span className="">{item.text}</span>
            <span className="">{item.payload}</span>
        </div>
    );

    const periods = useMemo(() =>
        PeriodsList.filter(x => availableGroupings.includes(x.value)),
        [availableGroupings]
    );

    const terms = useMemo(() => withAllTermsOption
        ? [AllTermsItem, ...TermsList]
        : TermsList,
    [withAllTermsOption])

    return (
        <FilterPanel>
            <FilterSection>
                <DropDownList
                    items={ChartTypeList.map(x => ({ ...x, selected: values.transactionType === x.value }))}
                    onChange={({ value }) => handleValueChange('transactionType')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Type')}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={FloaterIndexList.map(x => ({ ...x, selected: values.floaterIndex === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('floaterIndex')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Coupon')}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={RatingsList.map(x => ({ ...x, selected: values.rating === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('rating')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Rtg')}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={CollateralTypesList.map(x => ({ ...x, selected: values.collateralType === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('collateralType')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Collat.')}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={terms.map(x => ({ ...x, selected: values.term === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('term')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Term')}
                    renderItemContentCallback={renderListItemWithDescription}
                    className="custom-drop-down-ghost custom-drop-down-term"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={periods.map(x => ({ ...x, selected: values.grouping === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('grouping')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Period')}
                    renderItemContentCallback={renderListItemWithDescription}
                    className="custom-drop-down-ghost custom-drop-down-period"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={CurrencyList.map(x => ({ ...x, selected: values.currencyCode === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('currencyCode')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Ccy')}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
        </FilterPanel>
    );
};
