import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../types/state/AppState";
import { isRequesting } from "../../../utils";
import { SORT, SORTING_TYPE, issuanceMonitorWidget } from "../../../constants";
import { Table } from "../../bidding/common/table";
import { topDebutDealsColumns } from "./columns/TopDebutDealsColumns";
import { Pagination } from "../tables/Pagination";
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from "../widget";
import { DashboardSkeleton } from "../DashboardSkeleton";
import { dashboardExportTopDebutDeals, dashboardFetchTopDebutDeals } from "../../../actions/dashboard.actions";
import { getAvailableYears, getSortingParamsForStatisticExport } from "./top-list.utils";
import { YearSelector } from "../../controls/YearSelector";
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { AmrClass } from "../../../types/amr-pipeline/models/AmrClass";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { useSortedList } from "../../../effects";
import { ShowFor } from "../../access";
import ExportButton from "../../amrPipeline/common/ExportButton";
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import moment from 'moment';
import { ChartRequiredFeature } from "../../access/ChartRequiredFeature";

const availableYears: number[] = getAvailableYears(issuanceMonitorWidget.dealsMinYear);

const getSortType = (type: string) => {
    switch (type) {
        case "collateralManager":
            return SORTING_TYPE.company;
        case "currency":
            return SORTING_TYPE.transactionCurrency;
        default:
            return '';
    }
}

interface Props {
    onListItemClick?: () => void;
}

export function TopDebutDeals({ onListItemClick }: Props) {
    const [year, setYear] = useState<number | undefined>(availableYears[0]);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState(SORT.ASC);

    const topDebutDeals = useSelector((s: AppState) => s.dashboard.topDebutDeals);
    const requestStateFetchTopDebutDeals = useSelector((s: AppState) => s.dashboard.requestStateFetchTopDebutDeals);
    const requestStateExportDebutManagers = useSelector((s: AppState) => s.dashboard.requestStateExportDebutManagers);

    const hasAccess = hasWidgetsAccess(SubscriptionFeature.DebutManagersTable);

    const dispatch = useDispatch();

    useEffect(() => {
        hasAccess && dispatch(dashboardFetchTopDebutDeals(moment().year()));
    }, [dispatch, hasAccess]);

    const columnContext = () => {
        return {
            onListItemClick: onListItemClick,
        };
    };

    const sortByType = getSortType(sortBy)
    const [sortedDataItems] = useSortedList(topDebutDeals, sortBy, sortDirection, sortByType);

    const handleSortChange = (field: string, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const handleYearChange = (yearValue?: number) => {
        setYear(yearValue);
        dispatch(dashboardFetchTopDebutDeals(yearValue));
    };

    const handleExportChart = () =>
        dispatch(
            dashboardExportTopDebutDeals(
                year,
                getSortingParamsForStatisticExport(sortBy, sortDirection),
            )
        );

    const onPageChanged = (selectedPage: number) => setPage(selectedPage);

    const renderTable = () =>
        sortedDataItems.length > issuanceMonitorWidget.itemsOnPage ? (
            <Pagination
                list={sortedDataItems}
                itemsOnPage={issuanceMonitorWidget.itemsOnPage}
                currentPage={page}
                onPageChanged={onPageChanged}
            >
                {(items: AmrClass[] | OriginatingTransaction[]) => (
                    <Table
                        defaultSortBy={sortBy}
                        defaultSortByDirection={sortDirection}
                        dataItems={items}
                        columns={topDebutDealsColumns}
                        createSecurityCustomArgs={columnContext}
                        className="data-list-striped"
                    />
                )}
            </Pagination>
        ) : (
            <Table
                onSort={handleSortChange}
                defaultSortBy={sortBy}
                defaultSortByDirection={sortDirection}
                dataItems={sortedDataItems}
                columns={topDebutDealsColumns}
                createSecurityCustomArgs={columnContext}
                className="data-list-striped"
            />
        );

    const chartName = "Debut Managers";

    return (
        <DashboardWidget title={chartName} className="debut-deals">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchTopDebutDeals)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.DebutManagersTable}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-debut-manager"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                        <ShowFor feature={SubscriptionFeature.DebutManagersTable}>
                            <div className="flex-item-right">
                                <ExportButton
                                    isExportLoad={isRequesting(requestStateExportDebutManagers)}
                                    handleExport={handleExportChart}
                                    disabled={!topDebutDeals.length}
                                />
                            </div>
                            <div className="flex-item-right control-box">
                                <div className="control-item">
                                    <YearSelector
                                        years={availableYears}
                                        selectedYear={year}
                                        currentYearPrefix="YTD"
                                        onChange={handleYearChange}
                                        prefix="Year: "
                                        showAllOption
                                    />
                                </div>
                            </div>
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchTopDebutDeals}
                        description={
                            sortedDataItems?.length
                                ? "All statistics are calculated based on the transaction pricing date."
                                : ""
                        }
                    >
                        {sortedDataItems.length ? renderTable() : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
