import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { useDispatch } from 'react-redux';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { Contact } from '../../types/company/Contact';
import { UserSlim } from '../../types/account/UserSlim';
import { DealerUser } from './DealerUser';
import { DealerContact } from './DealerContact';
import { DealerHeadOfTrading } from './DealerHeadOfTrading';
import { DealerDistributionList } from './DealerDistributionList';
import { FavoriteButton, OnHoverTooltip, Pluralize } from '../common';
import { DealerRequestToBidButton } from './DealerRequestToBidButton';
import { Checkbox } from '../controls/Checkbox';
import { Bid } from '../../types/bidding/Bid';
import IconSVG from '../../styles/svg-icons';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { CompanyStatus } from '../../types/company/CompanyStatus';

interface DealerCompanyProps {
    dealer: BrokerDealerCompanySlim;
    expanded: boolean;
    expandEnabled: boolean;
    selected: boolean;
    contacts: Contact[];
    users: UserSlim[];
    enableContactOptions: boolean;
    enableRequestToBid: boolean;
    selectEnabled: boolean;
    favoriteButtonDisabled: boolean;
    companyBids: Bid[];
    onSelectAllChange?: (dealerId: number) => void;
    agreements?: SettlementAgentAgreement[];
    liveBidding?: boolean;
}

type TCompanyMember = UserSlim | Contact;

export const DealerCompany: FC<DealerCompanyProps> = (
    {
        dealer,
        expanded,
        expandEnabled,
        contacts,
        users,
        enableContactOptions,
        enableRequestToBid,
        selectEnabled,
        selected,
        companyBids,
        favoriteButtonDisabled,
        onSelectAllChange,
        agreements,
        liveBidding = false
    }
) => {
    const dispatch = useDispatch();

    const isConfirmedClearingBank = useMemo(() => (
        dealer.isClearingBank && agreements?.find(a => a.settlementAgent.id === dealer.id)?.agreementStatus === SettlementAgreementStatus.confirmed),
        [dealer, agreements]
    );
    const members: TCompanyMember[] = React.useMemo(() => users
            .concat(contacts)
            .sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)),
        [users, contacts]);

    const handleSetExpanded = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (expandEnabled) {
            dispatch(dealerListActions.setDealerExpanded(dealer.id))
        }
    };

    const totalUsersContacts = useMemo(() =>
            (contacts.length + users.length + Number(!!dealer.headOfTrading) + Number(!!dealer.distributionList)),
        [contacts, users, dealer]
    );

    const renderHeadOfTrading = () => dealer.headOfTrading &&
        <DealerHeadOfTrading dealer={dealer} enableContactOptions={enableContactOptions} />;

    const renderDistributionList = () => dealer.distributionList &&
        <DealerDistributionList dealer={dealer} enableContactOptions={enableContactOptions} />;

    const renderList = () =>
        <>
            {renderDistributionList()}
            {renderHeadOfTrading()}
            {
                members.map(m => users.indexOf(m) >= 0
                    ? <DealerUser key={`user-${m.id}`} user={m as UserSlim} />
                    : <DealerContact key={`contact-${m.id}`} contact={m as Contact} enableContactOptions={enableContactOptions} />
                )
            }
        </>;

    const handleSetSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        onSelectAllChange?.(dealer.id)
    };

    const renderSelectCheckBox = () => {
        if (!selectEnabled) {
            return null;
        }

        const isLiveBiddingBlockedOffplatform = liveBidding && dealer.status !== CompanyStatus.active;

        const checkboxDisabled = isConfirmedClearingBank || isLiveBiddingBlockedOffplatform;
        const tooltip =
            (isConfirmedClearingBank && 'You can’t uncheck Settlement Agent.') ||
            (isLiveBiddingBlockedOffplatform && 'Only platform Broker-Dealers will be able to participate on LiveBidding\u2122 BWICs.')

        return (
            <OnHoverTooltip overlay={tooltip ?? ''} disabled={!checkboxDisabled}>
                <Checkbox
                    disabled={checkboxDisabled}
                    checked={selected}
                    onChange={handleSetSelected}
                />
            </OnHoverTooltip>
        );
    };

    const renderIcon = () => {
        if (dealer.status === CompanyStatus.active) {
            if ((agreements && isConfirmedClearingBank) || (!agreements && dealer.isClearingBank)) {
                return (
                    <OnHoverTooltip overlay="Settlement Agent. Submit bids directly to the Seller once approved by the Settlement Agent.">
                        <span className="icon-wrap position-relative">
                            <IconSVG name="clearing" width={16} height={16} />
                        </span>
                    </OnHoverTooltip>
                );
            }
            return (
                <OnHoverTooltip overlay="Platform member who can submit Px Talk, live bids and receive system messages from a Seller.">
                    <div className="kpt-platform">
                        <IconSVG name="on-platform" width={16} height={16} />
                    </div>
                </OnHoverTooltip>
            )
        }
        return null;
    };

    return (
        <div className="data-table-item">
            <div className={cn('data-item-collapsable-header', { 'expand': expanded && expandEnabled })} onClick={handleSetExpanded}>
                <span className="btn-link">
                    <IconSVG
                        name="icon-expand"
                        className={cn({ 'collapse': expanded && expandEnabled, 'disabled': !expandEnabled })}
                        width={16}
                        height={16}
                    />
                </span>
                {renderSelectCheckBox()}
                <span className="favorite-company"><FavoriteButton companyId={dealer.id} disabled={favoriteButtonDisabled} hideWhenNotFavorite={true} /></span>
                {renderIcon()}
                <span className="flex-none dealer-name">
                    <strong>{dealer.name} ({totalUsersContacts})</strong>
                    {!expandEnabled && <span className="text-sm"> No users or contacts yet</span>}
                </span>
                <DealerRequestToBidButton
                    dealer={dealer}
                    dealerUsers={users}
                    enableRequestToBid={enableRequestToBid}
                />
                {!!companyBids.length &&
                <div className="bids-counter text-sm flex-item-right">
                    <Pluralize singular="bid" count={companyBids.length} />
                </div>}
            </div>
            <div className="data-item-collapsable-content">
                {expanded && renderList()}
            </div>
        </div>
    )
};
