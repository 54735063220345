import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { TopFilterTypes } from "../../../../types/dashboard/RedirectFilterTypes";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { PipelineWidgetColumn } from "../../../../types/dashboard/PipelineWidgetColumn";
import { formatUtils } from "../../../../utils";
import { constants } from "../../../../constants";
import moment from "moment";
import { ManagerLink } from '../../../amrPipeline/common/ManagerLink';
import { FilterLinkIcon } from '../../../amrPipeline/common/FilterLinkIcon';
import { FilterRelatedTransactionsIcon } from '../../../amrPipeline/common/FilterRelatedTransactionsIcon';
import { DetailedPageLink } from "../../../amrPipeline/common/DetailedPageLink";
import { OnHoverTooltip } from "../../../common";
import { ActionBlocker } from "../../../access/ActionBlocker";
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature";

const buyColumnsDefinitions: IColumnDefinition<
    AmrTransaction | OriginatingTransaction
>[] = [
    {
        columnKey: PipelineWidgetColumn.dealName,
        renderColumnHeaderContent: () => "Deal Name",
        renderColumnContent: (transaction) => (
            <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                {blocked => (
                    <OnHoverTooltip overlay={transaction.dealLegalName} disabled={blocked}>
                        <DetailedPageLink transaction={transaction} disabled={blocked} />
                    </OnHoverTooltip>
                )}
            </ActionBlocker>
        ),
        headerClassName: "data-list-cell-xs-flexible",
        bodyClassName: "data-list-cell-xs-flexible",
        sortingField: "dealLegalName",
    },
    {
        columnKey: PipelineWidgetColumn.relatedTransactions,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ dealLegalName, dealReferenceName, numberOfDealPublishedTransactions }, context) =>
            numberOfDealPublishedTransactions > 1 &&
                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                    {blocked => (
                        <FilterRelatedTransactionsIcon
                            dealLegalName={dealLegalName}
                            dealReferenceName={dealReferenceName}
                            onClick={context.onListItemClick}
                            disabled={blocked}
                        />
                    )}
                </ActionBlocker>,
        headerClassName: 'data-list-cell-xxxs',
        bodyClassName: 'data-list-cell-xxxs',
    },
    {
        columnKey: PipelineWidgetColumn.collateralManager,
        renderColumnHeaderContent: () => "Manager",
        renderColumnContent: ({ collateralManager }, context) =>
            collateralManager ? (
                <ManagerLink
                    referenceName={collateralManager.referenceName}
                    legalName={collateralManager.legalName}
                />
            ) : constants.emptyPlaceholder,
        headerClassName: "data-list-cell-xs-flexible",
        bodyClassName: "data-list-cell-xs-flexible",
        sortingField: "collateralManager",
    },
    {
        columnKey: PipelineWidgetColumn.redirectToIm,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ collateralManager }, context) =>
            collateralManager &&
                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                    {blocked => (
                        <FilterLinkIcon
                            referenceName={collateralManager.referenceName}
                            legalName={collateralManager.legalName}
                            filter={TopFilterTypes.issuer}
                            onClick={context.onListItemClick}
                            tooltip="All Manager’s Transactions"
                            disabled={blocked}
                        />
                    )}
                </ActionBlocker>,
        headerClassName: "data-list-cell-xxxs",
        bodyClassName: "data-list-cell-xxxs",
    },
    {
        columnKey: PipelineWidgetColumn.collateralType,
        renderColumnHeaderContent: () => "Collat.",
        renderColumnContent: ({ collateralType }) =>
            formatUtils.formatCollateralType(collateralType),
        sortingField: "collateralType",
        headerClassName: "data-list-cell-xs",
        bodyClassName: "data-list-cell-xs",
    },
    {
        columnKey: PipelineWidgetColumn.currency,
        renderColumnHeaderContent: () => "Ccy",
        renderColumnContent: ({ currency }) => currency ? currency.code : constants.emptyPlaceholder,
        sortingField: "currency",
        headerClassName: "data-list-cell-xxs-02",
        bodyClassName: "data-list-cell-xxs-02",
    },
    {
        columnKey: PipelineWidgetColumn.pricingDate,
        renderColumnHeaderContent: () => "Pricing Date",
        renderColumnContent: ({ pricingDate }) =>
            moment(pricingDate).format(constants.dateFormat),
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm",
        sortingField: "pricingDate",
    },
];
export const topDebutDealsColumns = buyColumnsDefinitions.map((c) => {
    return new ColumnBuilder(c);
});
