import React from 'react';
import moment from 'moment';
import { Plot, mainConfig } from '../../common/charts/plotlyConfig'
import { constants } from '../../../constants';
import { chartColorBar } from '../../../constants/dashboard';
import { chartUtils } from '../../../utils';

export function BarColorChart({ chartData, divId, onClick = () => {} }) {
    const { tickColor, tickFont, chartLayout, zeroLineColor, yAxisRange, annotationMargin, barColor, hoverlabel } = chartColorBar;
    const annotations = chartData.dates.map((d) => {
        return {
            x: moment(d).format(constants.dashboardDateFormat),
            y: chartData.data[d].amountPercent + annotationMargin,
            xref: 'x',
            yref: 'y',
            text: `${chartData.data[d].amountPercent.toFixed(2)} %`,
            arrowhead: 0,
            ax: 0,
            ay: 0
        }
    });

    const getChartData = () => {
        return [{
            x: chartData.dates.map((d) => moment(d).format(constants.dashboardDateFormat)),
            y: chartData.dates.map((d) => chartData.data[d].amountPercent),
            name: 'bar-color',
            type: "bar",
            marker: {
                color: barColor,
            },
            hoverinfo: 'text',
            text: chartData.dates
                .map((d) => ` Color %: ${chartData.data[d].amountPercent.toFixed(2)} <br /> Volume: ${chartData.data[d].amountMoney}`),
            textposition: 'none',
        }]
    };

    const layout = {
        ...chartLayout,
        annotations,
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        hoverlabel,
        yaxis: {
            showgrid: true,
            range: yAxisRange,
            fixedrange: true,
            showtickprefix: 'none',
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
            zerolinecolor: zeroLineColor,
        },
        xaxis: {
            zeroline: false,
            tickcolor: tickColor,
            tickfont: tickFont,
        },
    };

    return (
        <Plot
            divId={divId}
            layout={layout}
            data={getChartData()}
            config={mainConfig}
            onClick={onClick}
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
        />
    );
}

BarColorChart.defaultProps = {
    divId: 'bar-color-chart'
};
