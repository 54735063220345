import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DashboardWidgetContext } from './DashboardWidgetContext';
import { useContentVisibilityLogger } from '../../../logging/useContentVisibilityLogger';

interface DashboardWidgetProps {
    children: React.ReactNode;
    filterActive?: boolean;
    className?: string;
    title?: string;
}

export function DashboardWidget({ children, filterActive, className, title }: DashboardWidgetProps) {
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const initialClientHeight = useRef(0);

    const { id } = useContentVisibilityLogger(
        containerRef,
        title ? `Dashboard scroll tracking: ${title} is shown` : undefined
    );

    useEffect(() => {
        const current = containerRef.current;
        if (current) {
            initialClientHeight.current = current.clientHeight;
        }
    }, [containerRef]);

    const getContainerStyles = () => {
        const current = containerRef.current;
        if (current && descriptionExpanded) {
            return {
                marginBottom: -(current.clientHeight - initialClientHeight.current),
            }
        }
        return {};
    };

    return (
        <DashboardWidgetContext.Provider
            value={{
                descriptionExpanded,
                setDescriptionExpanded: (value: boolean) => setDescriptionExpanded(value)
            }}
        >
            <div
                ref={containerRef}
                id={id}
                style={getContainerStyles()}
                className={classNames('dashboard-cnt-box', className, {
                    'filtered-widget': filterActive,
                    'expanded-widget': (containerRef.current && descriptionExpanded)
                })}
            >
                {children}
            </div>
        </DashboardWidgetContext.Provider>
    )
}

DashboardWidget.defaultProps = {
    filterActive: false,
    className: '',
    defaultIsMinimized: false
};
