import React from 'react';
import { Rating } from '../../types/enums/Rating';
import { ratingColors } from '../../constants/dashboard/ratings';

interface RatingColorLabelProps {
    ratings: (Rating | string)[]
}

export function RatingColorLabel({ ratings }: RatingColorLabelProps) {
    return (
        <div className="agenda">
            {ratings.map(rating => ratingColors[rating]
                ? (
                    <div key={rating} className="display-inline item">
                        <span style={{ background: ratingColors[rating] }} />{rating}
                    </div>
                ) : null
            )}
        </div>
    )
}
