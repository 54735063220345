import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Plot, mainConfig } from '../../common/charts';
import { chartIssuanceSpread, constants } from '../../../constants';
import { chartUtils } from '../../../utils';
import { ChartGrouping, Term } from '../../../types/dashboard/AmrChart';
import { WeeklyIssuanceSpread } from '../../../types/dashboard/IssuanceSpread';
import { IssuanceSpreadChartFilter } from './IssuanceSpreadFilter';
import { PlotMouseEvent } from 'plotly.js';
import { IssuanceSpreadsChartClickHandler } from './types';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { FloaterIndex } from '../../../types/amr-pipeline/enums/FloaterIndex';
import { amrChartUtils } from '../../../utils/amr-chart.utils';

interface IssuanceSpreadChartProps {
    issuanceSpread: WeeklyIssuanceSpread[];
    commonMedian: number;
    filter: IssuanceSpreadChartFilter;
    withSlider?: boolean;
    divId?: string;
    onClick?: IssuanceSpreadsChartClickHandler;
}

const MaxRangeYears = 12;

const BarWidthInDays = {
    [ChartGrouping.M]: 15,
    [ChartGrouping.Q]: 45,
    [ChartGrouping.Y]: 182,
    [ChartGrouping.W]: 3,
};

const GroupingTickFormat = {
    [ChartGrouping.M]: {
        tickformat: '%b `%y',
        dtick: 'M1',
    },
    [ChartGrouping.Q]: {
        tickformat: '%qQ `%y',
        dtick: 'M3',
    },
    [ChartGrouping.Y]: {
        tickformat: '%Y',
        dtick: 'M12',
    },
    [ChartGrouping.W]: {
        tickformat: '%m/%d/%y',
        // 7 days (1 week)
        dtick: constants.dayMs * 7,
    },
};

const ChartDimensionFormat = {
    [ChartGrouping.M]: 'YYYY-MM',
    [ChartGrouping.Q]: 'YYYY-MM',
    [ChartGrouping.Y]: 'YYYY',
    [ChartGrouping.W]: 'YYYY-MM-DD',
};

const TooltipDimensionFormat = {
    [ChartGrouping.M]: 'MMM `YY',
    [ChartGrouping.Q]: 'Q[Q] `YY',
    [ChartGrouping.Y]: 'YYYY',
    [ChartGrouping.W]: 'MM/DD/YYYY',
};

export function IssuanceSpreadChart({
    issuanceSpread,
    commonMedian,
    filter,
    divId,
    withSlider = false,
    onClick,
}: IssuanceSpreadChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        bar,
        border,
        zeroLineColor,
        hoverlabel,
        minAdditionalYAmount,
    } = chartIssuanceSpread;

    const filteredData = useMemo(() => {
        switch (filter.grouping) {
            case ChartGrouping.W:
                return issuanceSpread
                    .filter((x) => !!x.week)
                    .sort(
                        (a, b) =>
                            new Date(a.week as Date).getTime() -
                            new Date(b.week as Date).getTime()
                    );
            case ChartGrouping.M:
                return issuanceSpread
                    .filter((x) => !!x.month)
                    .sort((a, b) => {
                        const yearA = a.year || 0;
                        const yearB = b.year || 0;
                        const monthA = a.month || 0;
                        const monthB = b.month || 0;
                        return yearA - yearB ? yearA - yearB : monthA - monthB;
                    });
            case ChartGrouping.Q:
                return issuanceSpread
                    .filter((x) => !!x.quarter)
                    .sort((a, b) => {
                        const yearA = a.year || 0;
                        const yearB = b.year || 0;
                        const quarterA = a.quarter || 0;
                        const quarterB = b.quarter || 0;
                        return yearA - yearB
                            ? yearA - yearB
                            : quarterA - quarterB;
                    });
            default:
                return issuanceSpread
                    .filter(
                        (x) => !!x.year && !x.month && !x.quarter && !x.week
                    )
                    .sort((a, b) => (a.year as number) - (b.year as number));
        }
    }, [issuanceSpread, filter.grouping]);

    const handleChartClick = (e: PlotMouseEvent) => {
        if (!onClick) {
            return;
        }

        if (!e.points.length) {
            return;
        }

        const [point] = e.points;

        const dimension = point.x;

        const periodStart = moment.utc(dimension);

        const periodEnd = periodStart.clone();

        switch (filter.grouping) {
            case ChartGrouping.M:
                periodEnd.endOf('month');
                break;
            case ChartGrouping.Q:
                periodEnd.endOf('quarter');
                break;
            case ChartGrouping.W:
                periodEnd.endOf('week');
                break;
            case ChartGrouping.Y:
                periodEnd.endOf('year');
                break;
        }

        onClick({
            startPeriod: periodStart.toDate(),
            endPeriod: periodEnd.toDate(),
            transactionType: filter.transactionType === constants.allListItem
                ? undefined
                : filter.transactionType as TransactionType,
            collateralType: filter.collateralType,
            rating: filter.rating,
            currencyCode: filter.currencyCode,
            term: filter.term === constants.allListItem
                ? undefined
                : filter.term as Term,
            floaterIndex: filter.floaterIndex === constants.allListItem
                ? undefined
                : filter.floaterIndex as FloaterIndex,
        });
    };

    const getDimension = (row: WeeklyIssuanceSpread) => {
        switch (filter.grouping) {
            case ChartGrouping.M:
                return row.year && row.month
                    ? moment([row.year, row.month - 1])
                    : undefined;
            case ChartGrouping.W:
                return row.week ? moment(row.week) : undefined;
            case ChartGrouping.Q:
                return row.year && row.quarter
                    ? moment(`${row.year}-${row.quarter}`, 'YYYY-Q', true)
                    : undefined;
            default:
                return row.year ? moment([row.year]) : undefined;
        }
    };

    const getTooltipDimesion = (row: WeeklyIssuanceSpread) =>
        getDimension(row)?.format(TooltipDimensionFormat[filter.grouping]);

    const getChartDimension = (row: WeeklyIssuanceSpread) =>
        getDimension(row)?.format(ChartDimensionFormat[filter.grouping]);

    const getHoverInfoText = (row: WeeklyIssuanceSpread) => {
        const outliers =
            row.bottomOutlier && row.topOutlier
                ? row.bottomOutlier + '; ' + row.topOutlier
                : row.bottomOutlier || row.topOutlier;
        return (
            `${getTooltipDimesion(row)}<br />` +
            `Min: ${row.min} <br />` +
            `Q1: ${row.q1} <br />` +
            `Med: ${row.median} <br />` +
            `Q3: ${row.q3} <br />` +
            `Max: ${row.max} <br />` +
            `Common Med: ${commonMedian} <br />` +
            `Trend Mean: ${row.mean} <br />` +
            (outliers ? `Outlier(s): ${outliers} <br />` : '')
        );
    };

    const getChartRange = (min: Moment, max: Moment) => {
        let rangeMin = min.clone();
        let rangeMax = max.clone();

        switch (filter.grouping) {
            case ChartGrouping.M:
                rangeMin = max.clone()
                    .subtract(11, 'months')
                    .subtract(15, 'days');
                rangeMax.add(15, 'days');
                break;
            case ChartGrouping.W:
                rangeMin = max.clone()
                    .subtract(11, 'weeks')
                    .startOf('week')
                    .subtract(3.5, 'days');
                rangeMax.startOf('week')
                    .add(3.5, 'days');
                break;
            case ChartGrouping.Q:
                rangeMin = max.clone()
                    .subtract(11, 'quarters')
                    .subtract(45, 'days');
                rangeMax.startOf('quarter')
                    .add(45, 'days');
                break;
            default: {
                rangeMin = max.clone()
                    .subtract(MaxRangeYears - 1, 'years')
                    .startOf('year')
                    .subtract(6, 'months');

                if (rangeMin.isBefore(min)) {
                    rangeMin = min.clone()
                        .startOf('year')
                        .subtract(6, 'months');
                }

                rangeMax.startOf('year')
                    .add(6, 'months');
            }
        }

        return [
            rangeMin.format(),
            rangeMax.format(),
        ];
    };

    const getAdditionalYData = () => {
        const maxValue = Math.max(...issuanceSpread.map(i => i.topOutlier || i.q3));
        const minValue = Math.min(...issuanceSpread.map(i => i.bottomOutlier || i.q1));

        const difference = (maxValue - minValue);
        const isLowData = difference < minAdditionalYAmount;
        // adding of number with half to prevent empty row on the top and bottom
        const rowsToAdd = difference < minAdditionalYAmount ? 2.5 : 1.5;
        const rangeY = isLowData ? [minValue - rowsToAdd, maxValue + rowsToAdd] : [];
        const dtickY = isLowData ? 1 : 0;

        return {
            rangeY,
            dtickY,
        }
    };

    const getChartData = () => {
        return filteredData.reduce((accum: any, row) => {
            const xAxis = [getChartDimension(row)];
            const box = {
                q1: [row.q1],
                q3: [row.q3],
                median: [row.median],
                lowerfence: [row.min],
                upperfence: [row.max],
                x: xAxis,
                name: getHoverInfoText(row),
                type: 'box',
                marker: {
                    color: border,
                },
                width: BarWidthInDays[filter.grouping] * constants.dayMs,
                fillcolor: bar,
                hoverinfo: 'name',
                boxpoints: false,
            };

            const topOutlier = {
                x: xAxis,
                y: [row.topOutlier],
                mode: 'markers',
                type: 'scatter',
                hoverinfo: 'skip',
                marker: {
                    size: 6,
                    color: border,
                },
            };

            const bottomOutliers = {
                x: xAxis,
                y: [row.bottomOutlier],
                mode: 'markers',
                type: 'scatter',
                hoverinfo: 'skip',
                marker: {
                    size: 6,
                    color: border,
                },
            };

            return [...accum, box, topOutlier, bottomOutliers];
        }, []);
    };

    const commonMedianData = {
        type: 'scatter',
        x: [0, 100],
        y: filteredData.map(() => commonMedian),
        mode: 'lines',
        xaxis: 'x2',
        line: {
            color: 'red',
            width: 2,
            dash: 'dash',
        },
    };

    const trendMeanData = {
        type: 'scatter',
        x: filteredData.map((row) => getChartDimension(row)),
        y: filteredData.map((row) => row.mean),
        mode: 'lines',
        hoverinfo: 'skip',
        line: {
            color: 'green',
            width: 2,
            dash: 'dash',
        },
    };

    const getLayout = () => {
        const { dtick, tickformat } = GroupingTickFormat[filter.grouping];

        const { min, max } = amrChartUtils.getEdgeDimensionValues(filteredData, getDimension);
        const chartRange = getChartRange(min, max);
        const sliderRange = [min.format(), max.format()];
        const { dtickY, rangeY } = getAdditionalYData();
        const tick0 = min.format(ChartDimensionFormat[filter.grouping]);

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                tickfont: tickFont,
                range: withSlider ? chartRange : sliderRange,
                fixedrange: true,
                dtick,
                tickformat,
                tick0,
                rangeslider: withSlider ? {
                    range: sliderRange,
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                } : undefined,
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
                ticksuffix: ' bps',
                range: rangeY,
                dtick : dtickY,
            },
            xaxis2: {
                zeroline: false,
                showgrid: false,
                fixedrange: true,
                range: [0, 100],
                overlaying: 'x',
                showticklabels: false,
            },
        };
    };

    const data = [...getChartData(), commonMedianData, trendMeanData];
    const layout = getLayout();

    return (
        <>
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                onClick={handleChartClick}
                divId={divId}
                data={data}
                layout={layout}
                config={mainConfig}
            />
            <div className="agenda">
                <div className="common-median">Common Median</div>
                <div className="trend-mean">Trend Mean</div>
                <div className="outlier">Outlier(s)</div>
            </div>
        </>
    );
}

IssuanceSpreadChart.defaultProps = {
    divId: 'issuance-spread-chart',
};
