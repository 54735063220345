import React from 'react';
import { DealerFilterOptions } from './DealerFilterOptions';
import { DealerListFilterOption } from '../../types/state/DealerListState';
import { FilterSelect } from '../common/filters/FilterSelect';

interface Props {
    disabled: boolean;
    filterOptions: DealerListFilterOption[];
    onFilterChange: (option: DealerListFilterOption) => void;
}

export const DealerListFilter: React.FC<Props> = ({ disabled, filterOptions, onFilterChange }) => {
    const getDealerTypeOptions = () => filterOptions.map( o=> ({
        ...o,
        visible: true,
        disabled: false
    }));

    const handleSelectAll = () => {
        const [favorite, onPlatform, offPlatform] = getDealerTypeOptions();

        !favorite.selected && onFilterChange({ ...favorite, selected: true });
        !onPlatform.selected && onFilterChange({ ...onPlatform, selected: true });
        !offPlatform.selected && onFilterChange({ ...offPlatform, selected: true });
    }

    const handleResetAll = () => {
        const [favorite, onPlatform, offPlatform] = getDealerTypeOptions();

        favorite.selected && onFilterChange({ ...favorite, selected: false });
        onPlatform.selected && onFilterChange({ ...onPlatform, selected: false });
        offPlatform.selected && onFilterChange({ ...offPlatform, selected: false });
    }


    return <FilterSelect
        title="Dealer Type"
        disabled={disabled}
        isApplied={filterOptions.some(o => o.selected)}
        options={getDealerTypeOptions()}
        onSelectAll={handleSelectAll}
        onClearAll={handleResetAll}        
        onChangeItemSelection={(i, selected) => onFilterChange({
            value: i.value as DealerFilterOptions,
            selected,
            text: i.text
        })}
    />
};
