import moment from 'moment/moment';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardFetchBidRequestsAsSeller, errorActions } from '../../../actions';
import { RequestState } from '../../../constants/request-state';
import { bidAsDealerRequestService } from '../../../services';
import { BwicStatus } from '../../../types/enums/BwicStatus';
import { AppState } from '../../../types/state/AppState';
import { isRequestNone } from '../../../utils';

export function useMyBidRequests(disabled?: boolean) {
    const dispatch = useDispatch();
    const requestStateBidAsDealerRequests = useSelector((state: AppState) => state.dashboard.requestStateBidAsDealerRequests);

    const getRequests = useCallback(async () => {
        try {
            dispatch(dashboardFetchBidRequestsAsSeller(RequestState.request));
            const response = await bidAsDealerRequestService.findBidRequestsAsSeller({
                isinCusipsAndTickers: [],
                from: moment().format('YYYY-MM-DD'),
                to: undefined,
                statuses: [BwicStatus.scheduled, BwicStatus.bidding, BwicStatus.finished],
                currency: undefined,
                ratings: [],
                types: [],
                page: 1,
                pageSize: 200,
                submittedBids: null
            })
            dispatch(dashboardFetchBidRequestsAsSeller(RequestState.success, response));
        } catch (e) {
            dispatch(dashboardFetchBidRequestsAsSeller(RequestState.failure));
            dispatch(errorActions.unexpectedError(e));
        }
    }, [dispatch])

    useEffect(() => {
        if (!disabled && isRequestNone(requestStateBidAsDealerRequests)) {
            getRequests()
        }
    }, [disabled, requestStateBidAsDealerRequests, getRequests])
}
