import React from 'react';
import { Plot, mainConfig } from '../../common/charts';
import { chartUtils } from '../../../utils';
import { chartPxTalkDeviation } from '../../../constants/dashboard/chartPxTalkDeviation';
import { formatUtils, numericUtils } from '../../../utils';


export interface PxTalkDeviationChartData {
    x: string[];
    y: number[];
    markersColors: string[];
    ratings: string[];
}

export interface PxTalkDeviationData {
    data: PxTalkDeviationChartData[];
    maxValue: number
}

interface PxTalkDeviationChartProps {
    chartDiv?: string;
    chartData: PxTalkDeviationData;
}

export function PxTalkDeviationChart({chartDiv, chartData}: PxTalkDeviationChartProps) {

    const getHoverText = (data: PxTalkDeviationChartData) =>
        data.x.map((d, index) =>
            ` Rating: ${data.ratings[index]} <br /> Value: ${formatUtils.formatDecimal(data.y[index], 2)}%`
        );

    const createPlotData = () => {
        return chartData.data.map(data => ({
            x: data.x,
            y: data.y,
            mode: 'markers',
            type: 'scatter',
            name: data.x,
            text: getHoverText(data),
            hoverinfo: 'text',
            marker: {
                size: chartPxTalkDeviation.markerSize,
                color: data.markersColors
            }
        }))
    };

    const getYRange = () => {
        const additionalValueInPercentagesForLastLine = 30;
        if(chartData.maxValue) {
            const maxValue = numericUtils.round(chartData.maxValue, 0);
            const max = maxValue + numericUtils.getPercentage(maxValue, additionalValueInPercentagesForLastLine);
            return [0, max];
        }
       return []
    };

    const plotData = createPlotData();

    const layout = {
        barmode: 'stack',
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        margin: chartPxTalkDeviation.margin,
        hoverlabel: chartPxTalkDeviation.hoverlabel,
        xaxis: {
            showgrid: false,
            tickcolor: chartPxTalkDeviation.tickColor,
            tickfont: chartPxTalkDeviation.font,
            ticks: 'none'
        },
        yaxis: {
            fixedrange: true,
            separatethousands: true,
            zerolinecolor: chartPxTalkDeviation.tickColor,
            range: getYRange(),
            gridcolor: chartPxTalkDeviation.tickColor,
            ticks: 'inside',
            tickcolor: chartPxTalkDeviation.tickColor,
            tickfont: chartPxTalkDeviation.font,
        },
    };

    return (
        <Plot
            onInitialized={chartUtils.setCursorByDiv(chartDiv, 'crosshair')}
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={plotData}
            layout={layout}
            config={mainConfig}
        />
    )
}

PxTalkDeviationChart.defaultProps = {
    chartDiv: 'px-talk-deviation-chart'
};
