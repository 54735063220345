import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import IconSVG from '../../../styles/svg-icons';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

export function Pagination({ list, total, itemsOnPage, children, onPageChanged, currentPage = 1, withGoToPageNavigation = false, }) {
    const [selectedPage, setSelectedPage] = useState(currentPage);
    const [pages, setPages] = useState(0);
    const [pageInput, setPageInput] = useState(currentPage);
    const [errorPageInput, setErrorPageInput] = useState('');
    const totalItems = total || list.length;

    useEffect(() => {
        setPages(Math.ceil(totalItems / itemsOnPage));
    }, [totalItems, itemsOnPage]);

    useEffect(() => {
        setSelectedPage(currentPage);
        setPageInput(currentPage);
    }, [currentPage])

    const getItems = () => {
        if (totalItems) {
            return list.slice(itemsOnPage * (selectedPage - 1), getLastItemIndexInList());
        }
        return [];
    };

    const isContinue = (page) => {
        if (page === 1 || page === pages || page === selectedPage || pages === 9) {
            return false;
        }
        if (page === selectedPage - 2 || page === selectedPage + 2 || page === selectedPage - 1 || page === selectedPage + 1) {
            return false;
        }
        if (selectedPage <= 4 && page <= 7) {
            return false;
        }
        if (selectedPage > pages - 4 && page > pages - 7) {
            return false
        }
        return true;
    };

    const getVisiblePages = () => {
        const visiblePages = [];
        for (let i = 1; i <= pages; i++) {
            if (!isContinue(i)) {
                visiblePages.push(i);
            }
        }
        return visiblePages;
    };

    const renderPagesList = () => {
        const visiblePages = getVisiblePages();
        const result = [];

        visiblePages.forEach((p, index) => {
            result.push(
                <div key={`pagination-${p}`} className={classNames({
                    'pagination-cntrls-item': true,
                    'selected': ((selectedPage) === p)
                })}>
                    <button className="btn btn-link" onClick={() => handlePageChange(p)}>{p}</button>
                </div>
            );
            if (visiblePages[index + 1] && p + 1 !== visiblePages[index + 1]) {
                result.push(<div key={`pagination-points-${p + 1}`} className="ellipsis">...</div>)
            }
        });
        return result;
    };

    const validatePageInput = (value) => {
        if (value >= 1 && value <= pages) {
            return;
        }

        return `Valid range is 1-${pages}`;
    }

    const handleChangePageInput = (e) => {
        setPageInput(Number(e.target.value) || '');

        if (errorPageInput) {
            const error = validatePageInput(e.target.value);
            setErrorPageInput(error);
        }
    }

    const renderGoToPageNavigation = () => {
        return (
            <div className="flex-row pagination-go-to">
                <span className="pagination-go-to-text">Go to page:</span>
                <div className="input-wrapper">
                    <MaskedInput
                        className={classNames('form-control form-control-sm', { 'is-invalid': errorPageInput })}
                        mask={createNumberMask({
                            prefix: '',
                            allowDecimal: false,
                        })}
                        value={pageInput}
                        onChange={handleChangePageInput}
                    />
                    {errorPageInput && <div className="form-error">{errorPageInput}</div>}
                </div>
                <button
                    onClick={() => {
                        handlePageChange(pageInput);
                    }}
                    disabled={pageInput === selectedPage || errorPageInput || !pageInput}
                    className="btn btn-ghost btn-sm"
                >
                    Go
                </button>
            </div>
        );
    };

    const handlePageChange = (page) => {
        const error = validatePageInput(page);
        setErrorPageInput(error);

        if (!error) {
            setSelectedPage(page);
            onPageChanged && onPageChanged(page);
        }
    }

    const getLastItemIndexInList = () => {
        return (itemsOnPage * (selectedPage - 1)) + itemsOnPage;
    };

    const previous = () => {
        handlePageChange(selectedPage - 1);
    };

    const next = () => {
        handlePageChange(selectedPage + 1);
    };

    return (
        <>
            {typeof children === 'function' ? children(getItems()) : children}
            {
                (total > itemsOnPage || list.length > itemsOnPage) && (
                    <div className="pagination flex-row">
                        <div className="pagination-entries">
                            {`${(itemsOnPage * (selectedPage - 1)) + 1}-${getLastItemIndexInList() > totalItems ? totalItems : getLastItemIndexInList()} of ${totalItems}`}
                        </div>
                        <div className="pagination-cntrls flex-row flex-item-right">
                            <button
                                className="btn btn-link pagination-cntrls-step-left"
                                onClick={previous}
                                disabled={selectedPage === 1}
                            >
                                <IconSVG className={classNames({ 'warm-grey': (selectedPage === 1) })} name="icon-expand" width={16} height={16} />
                            </button>
                            {renderPagesList()}
                            <button
                                className="btn btn-link pagination-cntrls-step-right"
                                onClick={next}
                                disabled={(selectedPage) === pages}
                            >
                                <IconSVG className={classNames({ 'warm-grey': ((selectedPage) === pages) })} name="icon-expand" width={16} height={16} />
                            </button>
                        </div>
                        {withGoToPageNavigation && renderGoToPageNavigation()}
                    </div>
                )
            }
        </>
    );
}

Pagination.defaultProps = {
    onPageChanged: () => {},
    list: [],
    itemsOnPage: 10,
    total: 0,
}
