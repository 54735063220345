import * as React from 'react';
import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { DealerContactOptions } from './DealerContactOptions';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { companyStatuses, constants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { nameUtils } from '../../utils';

interface DealerHeadOfTradingProps {
    dealer: BrokerDealerCompanySlim;
    enableContactOptions: boolean;
}

export const DealerHeadOfTrading: React.FC<DealerHeadOfTradingProps> = ({ dealer, enableContactOptions }) => {
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(false);

    const inviteToJoinRequestStatus = useSelector((state: AppState) => state.dealerList.inviteToJoinRequestStatus);

    const handleSendInviteToJoin = () => {
        dispatch(dealerListActions.headOfTradingInviteToJoin(dealer.id))
    };

    const fullName = nameUtils.getFullName(dealer?.headOfTrading.firstName, dealer?.headOfTrading.lastName);

    return (
        <div className={cn("data-table-item-row flex-row", { active })}>
            <div className="flex-none data-list-name">
                <OnHoverTooltip overlay={(fullName === constants.emptyPlaceholder) ? null : fullName}>
                    <span className="display-inline name text-ellipsis">{fullName}</span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-title">
                <IconSVG name="head-of-trade" width={16} height={16} />Head of trading
            </div>
            <div className="flex-none data-list-email">
                <OnHoverTooltip overlay={dealer?.headOfTrading.email}>
                    <span className="display-inline email text-ellipsis">
                        <a href={`mailto:${dealer?.headOfTrading.email}`}>{dealer?.headOfTrading.email}</a>
                    </span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-phone">{constants.emptyPlaceholder}</div>
            <div className="flex-item-right">
                {dealer.status !== companyStatuses.active.statusCode && enableContactOptions && (
                    <OnHoverTooltip wrapperClass="flex-item-right" overlay="Invite Sent" disabled={!dealer.headOfTradingInvitationSent}>
                        <DealerContactOptions
                            disabled={dealer.headOfTradingInvitationSent}
                            onSend={handleSendInviteToJoin}
                            sending={inviteToJoinRequestStatus[dealer.id]}
                            onMenuShow={() => setActive(true)}
                            onMenuHide={() => setActive(false)}
                        />
                    </OnHoverTooltip>
                )}
            </div>
        </div>
    )
};
