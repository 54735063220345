import React from 'react';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { BwicStatusLabel, OnHoverTooltip } from '../../../common';
import { DashboardMyBwic } from '../../../../types/dashboard/MyBwic';
import dateTimeUtils from '../../../../utils/dateTime.utils';
import { SameDayBwicIcon } from '../../../common/SameDayBwicIcon';
import { BwicViewLink } from '../../../common/BwicViewLink';

const sellColumnsDefinitions: IColumnDefinition<DashboardMyBwic>[] = [
    {
        columnKey: 'bwic-status',
        renderColumnHeaderContent: () => '',
        renderColumnContent: entity =>
            <BwicStatusLabel
                status={entity.status}
                liveBidding={entity.openBiddingEnabled}
                isParsed={entity.isParsed}
                directBidding={entity.isClearingBankParticipant}
                isAllToAll={entity.isAllToAll}
            />,
        headerClassName: 'data-list-cell-lg',
        bodyClassName: 'data-list-cell-lg flex-none',
    },
    {
        columnKey: 'same-day',
        renderColumnHeaderContent: () => '',
        renderColumnContent: entity =>
            <>
                {entity.isSameDayBwic && <SameDayBwicIcon />}
            </>,
        className: 'data-list-cell-icon'
    }, {
        columnKey: 'bwic-name',
        renderColumnHeaderContent: () => 'BWIC Name',
        renderColumnContent: entity => {
            const dateTime = dateTimeUtils.utcToLocalString(entity.bidsDueDateUtc);
            return (
                <OnHoverTooltip overlay={`${dateTime} ${entity.bwicName}`}>
                    <span className="text-ellipsis">{`${dateTime} ${entity.bwicName}`}</span>
                </OnHoverTooltip>
            )
        },
        className: 'data-list-cell-xs-flexible',
        sortingField: 'bwicName'
    }, {
        columnKey: 'bwic-view',
        renderColumnHeaderContent: () => '',
        renderColumnContent: entity =>
            <BwicViewLink
                small
                referenceName={entity.bwicReferenceName}
                status={entity.status}
                isDirectBidding={entity.isClearingBankParticipant}
                isAllToAll={entity.isAllToAll}
                isMy={entity.isMy}
                isParticipant={entity.isParticipant}
                isParsed={entity.isParsed}
            />,
        headerClassName: 'data-list-cell-xs',
        bodyClassName: 'data-list-cell-sm-01 flex-item-right',
    }
];

export const sellColumns = sellColumnsDefinitions.map(c => new ColumnBuilder(c));
