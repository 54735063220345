import React from 'react';
import classNames from 'classnames';
import { RequestState } from '../../../constants/request-state';
import { DashboardWidgetLoadContentError } from './DashboardWidgetLoadContentError';

interface DashboardWidgetContentProps {
    children: React.ReactNode;
    className?: string;
    requestState?: RequestState;
    description?: string | React.ReactNode;
}

export function DashboardWidgetContent({
    children,
    className,
    requestState,
    description
}: DashboardWidgetContentProps) {
    const renderContent = () => (
        <>
            {children}
            {!!description && <div className="description">{description}</div>}
        </>
    );

    return (
        <div className={classNames('graph-box', className)}>
            {requestState === RequestState.failure ? <DashboardWidgetLoadContentError /> : renderContent()}
        </div>
    )
}

DashboardWidgetContent.defaultProps = {
    className: ''
};
