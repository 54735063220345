import React from 'react';
import moment from 'moment';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { BidAsDealerAgreementStatusLabel } from '../bid-as-dealer/BidAsDealerAgreementStatusLabel';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { FeatureButton } from '../access/FeatureButton';
import { companyStatuses, constants, roles } from '../../constants';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { useDispatch } from 'react-redux';
import { UserSlim } from '../../types/account/UserSlim';
import { dealersActions } from '../../actions';
import { useSettlementAgentAgreements } from '../../effects/useSettlementAgentAgreements';
import { ViewSettlementAgentCommission } from './ViewSettlementAgentCommission';
import { DailyTradingLimitDialog } from '../common/DailyTradingLimitDialog';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

interface Props {
    dealer: BrokerDealerCompanySlim;
    dealerUsers: UserSlim[];
    enableRequestToBid: boolean;
}

export const DealerRequestToBidButton: React.FC<Props> = ({ dealer, enableRequestToBid, dealerUsers }) => {
    const dispatch = useDispatch();
    const settlementAgentAgreements = useSettlementAgentAgreements().items;

    if (dealer.status !== companyStatuses.active.statusCode || !enableRequestToBid) {
        return null;
    }

    const agreement = settlementAgentAgreements.find(d => d.settlementAgent.id === dealer.id);
    const agreementStatus = agreement?.agreementStatus;
    const getRequestDate = () => agreement && moment(agreement.date).format(constants.dateFormat4);
    const handleRequestToBid = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(dealersActions.showBidRequestConfirmPopup(dealer.id));
    };
    if (!dealer.isClearingBank) {
        return null;
    }
    switch (agreementStatus) {
        case SettlementAgreementStatus.pending:
        case SettlementAgreementStatus.rejected:
            return (
                <div className="flex-row flex-item-right">
                    <span className="item-request-date text-sm">{getRequestDate()}</span>
                    <BidAsDealerAgreementStatusLabel
                        status={agreementStatus}
                        rejectReason={agreement?.comment || ''}
                    />
                </div>
            );
        case SettlementAgreementStatus.confirmed:
            return (
                <div className="flex-row flex-item-right">
                    <div className="bd-options flex-row text-sm">
                        {
                            agreement?.dailyTradingLimit &&
                            <DailyTradingLimitDialog
                                agreement={agreement}
                                companyName={agreement.settlementAgent.name}
                                valueLabel="Daily Trading Limit: $"
                            />
                        }
                        <ViewSettlementAgentCommission commission={agreement!.commissions} company={dealer} />
                    </div>
                </div>
            );
        default: // No agreement
            return (
                <div className="flex-row flex-item-right">
                    <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                        {
                            !dealerUsers.length
                                ?
                                <OnHoverTooltip overlay="There are no active users within this company">
                                    <span>
                                        <button className="btn btn-main btn-sm btn-no-uppercase" disabled={true}>
                                            REQUEST DirrectBidding
                                        </button>
                                    </span>
                                </OnHoverTooltip>
                                :
                                <FeatureButton className="btn btn-main btn-sm btn-no-uppercase" onClick={handleRequestToBid}>
                                    REQUEST DirrectBidding
                                </FeatureButton>
                        }
                    </RoleActionBlocker>
                </div>
            );
    }
};
