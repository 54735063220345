import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { dashboarNewIssuedWeeklySpreadsRequest } from '../../../actions';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { UsBslNewIssueDsWidget } from './UsBslNewIssueDsWidget';
import { UsBslNewIssueWarfWidget } from './UsBslNewIssueWarfWidget';
import { UsBslNewIssueWasWidget } from './UsBslNewIssueWasWidget';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';

export function UsBslNewIssueWeeklySpreads() {
    const dispatch = useDispatch();

    const hasAccess = hasWidgetsAccess(
        SubscriptionFeature.UsBslNewIssueWasGraph,
        SubscriptionFeature.UsBslNewIssueWarfGraph,
        SubscriptionFeature.UsBslNewIssueDsGraph
    );

    useEffect(() => {
        hasAccess && dispatch(dashboarNewIssuedWeeklySpreadsRequest());
    }, [hasAccess, dispatch]);

    return (
        <>
            <UsBslNewIssueWasWidget />
            <UsBslNewIssueWarfWidget />
            <UsBslNewIssueDsWidget />
        </>
    );
}
