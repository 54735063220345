import * as React from 'react';
import { SettlementAgentCommissionByRating } from '../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { CompanySlim } from '../../types/company/CompanySlim';
import { ViewSettlementAgentCommissionPopup } from '../management/companies/clearing-bank/ViewSettlementAgentComissionPopup';

interface Props {
    company: CompanySlim;
    commission: SettlementAgentCommissionByRating;
}

export function ViewSettlementAgentCommission({ company, commission }: Props) {
    const [isPopupVisible, setPopupVisible] = React.useState(false);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setPopupVisible(true);
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <button className="pseudo-link" onClick={handleClick}>View Commission</button>
            {
                isPopupVisible &&
                <ViewSettlementAgentCommissionPopup
                    commission={commission}
                    companyName={company.name}
                    onClose={() => setPopupVisible(false)}
                />
            }
        </div>
    );
}
