import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ShortLongDatedDealsChart } from './ShortLongDatedDealsChart';
import { ShortLongDatedDealsFilter, ShortLongDatedDealsChartFilter, ShortLongDatedDealsChartFilterProps } from './ShortLongDatedDealsFilter';
import { isRequesting } from '../../../utils';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { AppState } from '../../../types/state/AppState';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { dashboardShortLongDatedDealsRequest } from '../../../actions';
import { ChartGrouping, ChartUnits } from '../../../types/dashboard/AmrChart';
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { constants } from '../../../constants';
import { Currency } from '../../../types/enums/Currency';
import { ShowFor } from '../../access';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { ShortLongChartClickHandler } from './types';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

interface ShortLongDatedDealsWidgetProps {
    managerReferenceName?: string;
    subtitle?: string;
    defaultFilter?: Partial<ShortLongDatedDealsChartFilter>;
    periodsCount?: number;
    startDate: Date;
    withFilter?: boolean;
    filterComponent?: React.ElementType<ShortLongDatedDealsChartFilterProps>;
    className?: string;
    feature?: SubscriptionFeature;
    onChartClick?: ShortLongChartClickHandler;
}

export function ShortLongDatedDealsWidget({
    managerReferenceName,
    subtitle,
    defaultFilter = {},
    periodsCount,
    startDate,
    withFilter = true,
    filterComponent: FilterComponent = ShortLongDatedDealsFilter,
    className = "double-size im-chart",
    feature = SubscriptionFeature.ShortAndLongDatedDealsGraph,
    onChartClick,
}: ShortLongDatedDealsWidgetProps) {
    const {
        shortLongDatedDeals,
        requestStateShortLongDatedDeals,
    } = useSelector((state: AppState) => state.dashboard);

    const dispatch = useDispatch();

    const hasAccess = hasWidgetsAccess(feature);

    const [filterState, setFilterState] = useState<ShortLongDatedDealsChartFilter>({
        grouping: ChartGrouping.M,
        units: ChartUnits.Volume,
        transactionType: constants.allListItem,
        currencyCode: Currency.USD,
        ...defaultFilter
    });

    useEffect(() => {
        hasAccess && dispatch(dashboardShortLongDatedDealsRequest(filterState, managerReferenceName, startDate));
    }, [hasAccess, dispatch, filterState, managerReferenceName, startDate]);

    const chart = useMemo(() =>
        <ShortLongDatedDealsChart
            filter={filterState}
            deals={shortLongDatedDeals}
            periodsCount={periodsCount}
            onClick={onChartClick}
        />,
        [shortLongDatedDeals, filterState, periodsCount, onChartClick]);

    const chartName = "Short & Long-Dated Deals";

    return (
        <DashboardWidget title={chartName} className={className}>
            <DashboardSkeleton inProgress={isRequesting(requestStateShortLongDatedDeals)}>
                <ChartRequiredFeature
                    feature={feature}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-short-long-dated-deals"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}{subtitle && <span>{subtitle}</span>}</h3>
                        <ShowFor feature={feature}>
                            {withFilter && <FilterComponent onChange={setFilterState} {...filterState} />}
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateShortLongDatedDeals}
                        description={
                            shortLongDatedDeals?.length ? (
                                <>
                                    Short-dated deals are classified as those with a non-call period of 0-1.5 years and reinvestment period of 0-3.5 years. <br />
                                    All statistics are calculated based on the transaction pricing date.
                                </>
                            ) : null
                        }
                    >
                        {shortLongDatedDeals?.length ? chart : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
