import React from 'react';
import { Plot, mainConfig } from '../../common/charts'
import { chartUtils } from '../../../utils';
import { bwicPercentOfTrace } from '../../../constants/dashboard/bwicPercentOfTrace';
import { numericUtils } from '../../../utils/numeric.utils';

interface ChartData {
    x: string;
    igPercent: number;
    hyPercent: number;
}

interface BwicPercentOfTraceChartProps {
    chartData: ChartData[];
    chartDiv: string;
}

export function BwicPercentOfTraceChart({ chartData, chartDiv }: BwicPercentOfTraceChartProps) {
    const createPlotData = () => {
        const chartLineIg = {
            x: chartData.map(data => data.x),
            y: chartData.map(data => data.igPercent),
            mode: 'lines+markers',
            name: 'IG',
            hoverinfo: 'text',
            text: chartData.map(data => `IG Value: ${data.igPercent}%`),
            line: { width: bwicPercentOfTrace.lineWidth, color: bwicPercentOfTrace.igLineColor, shape: 'linear' },
            marker: {
                size: bwicPercentOfTrace.markerSize,
                color: bwicPercentOfTrace.igMarkerColor,
                symbol: 'diamond'
            }
        };
        const chartLineHy = {
            x: chartData.map(data => data.x),
            y: chartData.map(data => data.hyPercent),
            mode: 'lines+markers',
            name: 'HY',
            hoverinfo: 'text',
            text: chartData.map(data => `HY Value: ${data.hyPercent}%`),
            line: { width: bwicPercentOfTrace.lineWidth, color: bwicPercentOfTrace.hyLineColor, shape: 'linear' },
            marker: {
                size: bwicPercentOfTrace.markerSize,
                color: bwicPercentOfTrace.hyMarkerColor,
                symbol: 'diamond'
            }
        };
        return [chartLineIg, chartLineHy]
    };

    const plotData = createPlotData();

    const getYRange = () => {
        const igPercent = chartData.map(data => data.igPercent);
        const hyPercent = chartData.map(data => data.hyPercent);
        const allYValues = igPercent.concat(hyPercent);
        if(allYValues.length) {
            const maxValue = Math.max(...allYValues);
            const max = chartUtils.roundToNumber(maxValue, bwicPercentOfTrace.additionalValueInPercentagesForLastLine) +
                numericUtils.getPercentage(maxValue, bwicPercentOfTrace.additionalValueInPercentagesForLastLine);
            return [0, max];
        }
        return []
    };

    const layout = {
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        margin: bwicPercentOfTrace.margin,
        autoscale: false,
        hoverlabel: bwicPercentOfTrace.hoverlabel,
        xaxis: {
            showgrid: false,
            tickcolor: bwicPercentOfTrace.tickColor,
            tickfont: bwicPercentOfTrace.font,
            ticks: 'none',
        },
        yaxis: {
            titlefont: bwicPercentOfTrace.titlefont,
            separatethousands: true,
            zerolinecolor: bwicPercentOfTrace.tickColor,
            gridcolor: bwicPercentOfTrace.tickColor,
            ticks: 'inside',
            tickcolor: bwicPercentOfTrace.tickColor,
            tickfont: bwicPercentOfTrace.font,
            rangemode:'tozero',
            range: getYRange()
        },
    };

    return (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={plotData}
            layout={layout}
            config={mainConfig}
        />
    )
}

BwicPercentOfTraceChart.defaultProps = {
    chartDiv: 'bwic-percent-of-trace'
};
