import moment from 'moment';
import { Plot, mainConfig } from '../../common/charts';
import { chartUtils, moneyUtils } from '../../../utils';
import { DailyIssuanceSpread, Spread } from '../../../types/dashboard/IssuanceSpread';
import { chartUsBslNewIssueSpreads } from '../../../constants/dashboard/chartUsBslNewIssueSpreads';
import { isNil, sortBy } from 'lodash';
import { constants } from '../../../constants';

interface WeeklySpreadsChartProps {
    spreads: DailyIssuanceSpread[];
    fieldSelector: (spread: DailyIssuanceSpread) => Spread;
    divId?: string;
}

const TooltipDateFormat = 'MM/DD/YY';

export function WeeklySpreadsChart({
    spreads,
    fieldSelector,
    divId = 'was-warf-ds-chart'
}: WeeklySpreadsChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        bar,
        border,
        zeroLineColor,
        hoverlabel,
    } = chartUsBslNewIssueSpreads;

    const getDimension = ({ year, month, day }: DailyIssuanceSpread) => {
        return !isNil(year) && !isNil(month) && !isNil(day)
            ? moment([year, month - 1, day])
            : undefined;
    };

    const getHoverInfoText = (spread: DailyIssuanceSpread) => {
        const row = fieldSelector(spread);
        let outliers;

        if (row.bottomOutlier && row.topOutlier) {
            outliers = moneyUtils.money(row.bottomOutlier, true) + '; ' + moneyUtils.money(row.topOutlier, true);
        } else {
            const outlier = row.bottomOutlier || row.topOutlier;
            outliers = outlier ? moneyUtils.money(outlier, true) : undefined;
        }          

        return (
            `${getDimension(spread)?.format(TooltipDateFormat)}<br />` +
            `Min: ${moneyUtils.money(row.min, true)} <br />` +
            `Q1: ${moneyUtils.money(row.q1, true)} <br />` +
            `Med: ${moneyUtils.money(row.median, true)} <br />` +
            `Q3: ${moneyUtils.money(row.q3, true)} <br />` +
            `Max: ${moneyUtils.money(row.max, true)} <br />` +
            (outliers ? `Outlier(s): ${(outliers)} <br />` : '')
        );
    };
    const getChartData = () => {
        return sortBy(spreads, spread => getDimension(spread)).reduce((acc: any, spread: DailyIssuanceSpread) => {
            const dimension = getDimension(spread)?.format(constants.dateFormat4);
            const row = fieldSelector(spread);

            const box = row.count ? {
                q1: [row.q1],
                q3: [row.q3],
                median: [row.median],
                lowerfence: [row.min],
                upperfence: [row.max],
                name: getHoverInfoText(spread),
                x: [dimension],
                type: 'box',
                marker: {
                    color: border,
                },
                fillcolor: bar,
                hoverinfo: 'name',
                boxpoints: false,
            } : {};

            const topOutlier = {
                x: [dimension],
                y: [row.count ? row.topOutlier : undefined],
                hoverinfo: 'skip',
                mode: 'markers',
                type: 'scatter',
                marker: {
                    size: 6,
                    color: border,
                },
            };

            const bottomOutliers = {
                x: [dimension],
                y: [row.count ? row.bottomOutlier : undefined],
                hoverinfo: 'skip',
                mode: 'markers',
                type: 'scatter',
                marker: {
                    size: 6,
                    color: border,
                },
            };

            return [...acc, box, topOutlier, bottomOutliers];
        }, []);
    };

    const getLayout = () => {
        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            xaxis: {
                type: 'category',
                tickcolor: tickColor,
                tickfont: tickFont,
                fixedrange: true,
                dtick: 'D1',
                tickformat: '%m/%d/%y',
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
                separatethousands: true,
            },
        };
    };

    const data = getChartData();
    const layout = getLayout();

    return (
        <>
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                divId={divId}
                data={data}
                layout={layout}
                config={mainConfig}
            />
        </>
    );
}
