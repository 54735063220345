import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SecuritiesSearchInput } from '../../filters';
import { dashboardOnSelectedSecurity, dashboardExportBwiStatistic } from '../../../actions';
import { dashboardChartBox, roles } from '../../../constants';
import { ChartBox } from '../../common/charts';
import { RequestState } from '../../../constants/request-state';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { DashboardNoData } from '../DashboardNoData'
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import IconSVG from '../../../styles/svg-icons';
import { ShowFor } from '../../access';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { user } from '../../../user';

export function BoxPxTalkAndColorWidget() {
    const dispatch = useDispatch();

    const selectedSecurityHistory = useSelector(s => s.dashboard.selectedSecurityHistory) || [];
    const requestStateFetchSecurityHistory = useSelector(s => s.dashboard.requestStateFetchSecurityHistory);
    const searchTermItem = useSelector(s => s.dashboard.searchTermItem);
    const lookupCache = useSelector((s) => s.searchSecurities.lookupCache);
    const searchTerm = useSelector((s) => s.searchSecurities.searchTerm);

    const chartHasData = () => {
        return selectedSecurityHistory.some(s => (s.color || (s.pxTalks && s.pxTalks.length)));
    };

    const chartVisible = () => {
        return selectedSecurityHistory.length && requestStateFetchSecurityHistory === RequestState.success && searchTermItem && chartHasData();
    };

    const renderContent = () => {
        if (chartVisible()) {
            return (
                <>
                    <ChartBox
                        historyList={selectedSecurityHistory}
                        constants={dashboardChartBox}
                        divId="dashboard-px-talk-color-dnt-chart"
                        showHoverInfo={true}
                        evalPriceTalkVisible={!user.hasRoles(roles.Media)}
                    />
                    <WidgetDescription
                        id="color-dispersion-clamp"
                        lines={2}
                        text="This chart shows a historical time-series of price information for each BWIC on which the selected security was listed. Px Talk from broker-dealers is shown as blue dots, with the distribution outlined by the blue box-and-whiskers. If a trade occurs and color is provided, the level is represented as a point along the orange line. Hover over any data points to reveal more details."
                    />
                </>
            );
        }
        if ((searchTermItem && !lookupCache.length) || (searchTermItem && searchTermItem.length < 8)) {
            return (
                <DashboardNoData
                    imgName="no-security-search-result-found"
                    imgWidth={164}
                    imgHeight={128}
                    text="No security search results found."
                />
            );
        }
        if (!searchTermItem && !searchTerm) {
            return (
                <DashboardNoData
                    imgName="start-search-by-ticker"
                    imgWidth={164}
                    imgHeight={128}
                    text={<>Start your search<br />by ISIN/CUSIP or ticker.</>}
                />
            );
        }
        if (!chartHasData() && requestStateFetchSecurityHistory === RequestState.success && searchTermItem) {
            return (
                <DashboardNoData
                    imgName="no-data-on-security-found"
                    imgWidth={164}
                    imgHeight={128}
                    text="No historical data on this security was found."
                />
            );
        }

        return null;
    };

    const chartName = "Security Lookup: Price History (Px Talk & Color)";

    return (
        <DashboardWidget title={chartName} className="color-dispersion">
            <ChartRequiredFeature
                features={[SubscriptionFeature.getSecurityBwicStatistics, SubscriptionFeature.BwicMonitorHistoricalData]}
                requireAllFeatures
                chartName={chartName}
                blockedClassName="restricted-placeholder-security-lookup-price-history"
            >
                <DashboardWidgetHeader className="flex-row">
                    <h3 className="display-inline">{chartName}</h3>
                    <ShowFor feature={SubscriptionFeature.getSecurityBwicStatistics}>
                        {chartVisible() ? (
                            <div className="flex-item flex-item-right">
                                <button
                                    className="btn-link"
                                    type="button"
                                    onClick={() => dispatch(dashboardExportBwiStatistic())}
                                >
                                    <IconSVG name="export" width={16} height={16} />
                                </button>
                            </div>
                        ) : null}
                    </ShowFor>
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestStateFetchSecurityHistory}>
                    <SecuritiesSearchInput
                        defaultSearchTerm={searchTermItem}
                        singleSearch={true}
                        onSearch={() => dispatch(dashboardOnSelectedSecurity())}
                        className="securities-search"
                        maxLength="32"
                        requiredFeature={SubscriptionFeature.getSecurityBwicStatistics}
                    />
                    {renderContent()}
                </DashboardWidgetContent>
            </ChartRequiredFeature>
        </DashboardWidget>
    );
}
