import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dashboardIssuanceSpreadRequest } from '../../../actions';
import { constants } from '../../../constants';
import { CollateralType } from '../../../types/amr-pipeline/enums/CollateralType';
import { ChartGrouping, Term } from '../../../types/dashboard/AmrChart';
import { Currency } from '../../../types/enums/Currency';
import { Rating } from '../../../types/enums/Rating';
import { isRequesting } from '../../../utils';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { ShowFor } from '../../access';
import { DashboardNoSelectedFilters } from '../DashboardNoSelectedFilters';
import { DashboardSkeleton } from '../DashboardSkeleton';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { IssuanceSpreadChart } from './IssuanceSpreadChart';
import {
    IssuanceSpreadFilter,
    IssuanceSpreadChartFilter,
} from './IssuanceSpreadFilter';
import { IssuanceSpreadsChartClickHandler } from './types';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { useAppSelector } from '../../../effects/useAppSelector';

interface IssuanceSpreadWidgetProps {
    className?: string;
    managerReferenceName?: string;
    availableGroupings?: ChartGrouping[];
    startDate?: Date;
    defaultFilter?: Partial<IssuanceSpreadChartFilter>;
    withSlider?: boolean;
    withAllTermsOption?: boolean;
    feature?: SubscriptionFeature;
    onChartClick?: IssuanceSpreadsChartClickHandler;
}

export function IssuanceSpreadWidget({
    className = 'double-size im-chart issuance-spread-chart',
    managerReferenceName,
    availableGroupings,
    startDate,
    defaultFilter,
    withSlider = false,
    withAllTermsOption = false,
    feature = SubscriptionFeature.IssuanceSpreadsGraph,
    onChartClick,
}: IssuanceSpreadWidgetProps) {
    const issuanceSpread = useAppSelector(s => s.dashboard.issuanceSpread);
    const requestStateIssuanceSpread = useAppSelector(s => s.dashboard.requestStateIssuanceSpread);

    const { issuanceSpreads, median } = issuanceSpread;

    const dispatch = useDispatch();

    const hasAccess = hasWidgetsAccess(feature);

    const [filterState, setFilterState] = useState<IssuanceSpreadChartFilter>({
        transactionType: constants.allListItem,
        collateralType: CollateralType.broadlySyndicated,
        rating: Rating.AAA,
        currencyCode: Currency.USD,
        grouping: ChartGrouping.M,
        term: Term.Long,
        floaterIndex: constants.allListItem,
        ...defaultFilter,
    });

    useEffect(() => {
        hasAccess && dispatch(dashboardIssuanceSpreadRequest(filterState, managerReferenceName, startDate));
    }, [hasAccess, dispatch, filterState, managerReferenceName, startDate]);

    const chart = useMemo(() => {
        return (
            <IssuanceSpreadChart
                filter={filterState}
                issuanceSpread={issuanceSpreads}
                commonMedian={median}
                withSlider={withSlider}
                onClick={onChartClick}
            />
        );
    }, [filterState, issuanceSpreads, median, withSlider, onChartClick]);

    const chartName = "Issuance Spreads";

    return (
        <DashboardWidget title={chartName} className={className}>
            <DashboardSkeleton inProgress={isRequesting(requestStateIssuanceSpread)}>
                <ChartRequiredFeature
                    feature={feature}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-issuance-spread"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                        <ShowFor feature={feature}>
                            <IssuanceSpreadFilter
                                onChange={setFilterState}
                                availableGroupings={availableGroupings}
                                withAllTermsOption={withAllTermsOption}
                                {...filterState}
                            />
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateIssuanceSpread}
                        description={
                            issuanceSpreads?.length ? (
                                <>
                                    Spread are shown over LIBOR base rate until December 31, 2021 and over SOFR thereafter. <br />
                                    All statistics are calculated based on the transaction pricing date.
                                </>
                            ) : null
                        }
                    >
                            {issuanceSpreads?.length ? chart : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
