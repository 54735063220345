import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import xor from 'lodash/xor';
import isEqual from 'lodash/isEqual';
import { OnHoverTooltip } from '../common';
import { dashboardClearFilter, dashboardSetFilter, dashboardSetNotAppliedFilter } from '../../actions';
import { AppState } from '../../types/state/AppState';
import { RatingGroup } from '../../types/dashboard/RatingGroup';
import { Currencies, Currency } from '../../types/enums/Currency';
import IconSVG from '../../styles/svg-icons';
import { FilterClear } from '../filters';
import { FilterSelect } from '../common/filters/FilterSelect';
import { Rating, Ratings } from '../../types/enums/Rating';
import { ActionBlocker } from '../access/ActionBlocker';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { FeatureButton } from '../access/FeatureButton';

export function Filter() {
    const dispatch = useDispatch();
    const filter = useSelector((state: AppState) => state.dashboard.filter);
    const notAppliedFilter = useSelector((state: AppState) => state.dashboard.notAppliedFilter);

    const onChangeRating = (filter: (Rating | RatingGroup)[]) => {
        const selectedRatings = xor(filter, notAppliedFilter.selectedRatings);
        dispatch(dashboardSetNotAppliedFilter({
            ...notAppliedFilter,
            selectedRatings
        }));
    };

    const onChangeCurrency = (filter: Currency[]) => {
        const selectedCurrencies = xor(filter, notAppliedFilter.selectedCurrencies);
        dispatch(dashboardSetNotAppliedFilter({
            ...notAppliedFilter,
            selectedCurrencies
        }));
    };

    const onClearFilters = () => {
        dispatch(dashboardClearFilter());
    };

    const handleApply = () => {
        dispatch(dashboardSetFilter(notAppliedFilter));
    };

    const disabledApplyButton = () => (isEqual(notAppliedFilter, filter));
    const isRatingFilterApplied = isEqual(notAppliedFilter.selectedRatings, filter.selectedRatings);
    const isCurrencyFilterApplied = isEqual(notAppliedFilter.selectedCurrencies, filter.selectedCurrencies);

    return (
        <div className="sub-header">
            <div className="sub-header-row type02">
                <div className="filters-area flex-row align-items-flex-end">
                    <ActionBlocker feature={SubscriptionFeature.CanUseDashboardFilter}>
                        <FilterSelect
                            title="Rtg"
                            multiply={true}
                            isApplied={!isEqual(filter.selectedRatings, []) && isRatingFilterApplied}
                            options={Ratings.map(x => ({
                                text: x,
                                value: x,
                                selected: (
                                    notAppliedFilter.selectedRatings.some(el => el === x)
                                ),
                                visible: true,
                                disabled: false
                            }))
                            }
                            onChangeItemSelection={item => onChangeRating([item.value])}
                            onClearAll={() => onChangeRating(notAppliedFilter.selectedRatings)}
                            onSelectAll={() => onChangeRating(Ratings)}
                        />
                    </ActionBlocker>
                    <ActionBlocker feature={SubscriptionFeature.CanUseDashboardFilter}>
                        <FilterSelect
                            title="Ccy"
                            multiply={true}
                            isApplied={!isEqual(filter.selectedCurrencies, []) && isCurrencyFilterApplied}
                            options={Currencies.map(x => ({
                                text: x,
                                value: x,
                                selected: (
                                    notAppliedFilter.selectedCurrencies.some(el => el === x)
                                ),
                                visible: true,
                                disabled: false
                            }))
                            }
                            onChangeItemSelection={item => onChangeCurrency([item.value])}
                            onClearAll={() => onChangeCurrency(notAppliedFilter.selectedCurrencies as Currency[])}
                            onSelectAll={() => onChangeCurrency(Currencies)}
                        />
                    </ActionBlocker>
                    <ActionBlocker feature={SubscriptionFeature.CanUseDashboardFilter}>
                        <FeatureButton className="btn btn-main btn-sm" onClick={handleApply} disabled={disabledApplyButton()}>
                            Apply
                        </FeatureButton>
                    </ActionBlocker>
                    <OnHoverTooltip overlay="Select the required rating and currency to apply to supported tiles. Tiles that support the overall rating and currency are highlighted.">
                        <IconSVG name="help" width={16} height={16} />
                    </OnHoverTooltip>
                    <FilterClear isShown={!!(notAppliedFilter.selectedRatings.length || notAppliedFilter.selectedCurrencies.length)} onClick={onClearFilters} />
                </div>
            </div>
        </div>
    )
}
