import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequesting } from '../../../utils';
import { numericUtils } from '../../../utils/numeric.utils';
import { BwicPercentOfTraceChart } from './BwicPercentOfTraceChart';
import { constants } from '../../../constants';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "BWICs as % of TRACE";

export function BwicPercentOfTraceWidget() {
    const bwicPercentOfTrace = useSelector((s: AppState) => s.dashboard.bwicPercentOfTrace);
    const requestStateFetchBwicPercentOfTrace = useSelector((s: AppState) => s.dashboard.requestStateFetchBwicPercentOfTrace);

    const chartData = useMemo(() => bwicPercentOfTrace.map(data => ({
        x: moment.utc(data.date).format(constants.dashboardDateFormat),
        igPercent: numericUtils.round(data.igPercent, 2),
        hyPercent: numericUtils.round(data.hyPercent, 2)
    })), [bwicPercentOfTrace]);

    const chart = useMemo(() => <BwicPercentOfTraceChart chartData={chartData} />, [chartData]);
    return (
        <DashboardWidget title={chartName} className="percent-of-trace">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchBwicPercentOfTrace)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getBwicPercentOfTrace}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-bwic-percent-of-trace"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateFetchBwicPercentOfTrace}>
                        {chart}
                        <div className="agenda">
                            <div className="hy display-inline">HY</div>
                            <div className="ig display-inline">IG</div>
                        </div>
                        <div className="description">
                            All CLO/CDO trades involving FINRA participants are recorded on TRACE and aggregated daily.
                            To determine how much trading volume is conducted via BWICs, we divide our recorded BWIC volumes
                            by TRACE-reported volumes, using 5-day rolling averages for each metric. Historically, BWICs have
                            represented one-quarter to one-third of all CLO trading volume. This graph shows how 'open' the
                            market is at any given time. High values indicate more activity on BWICs, while lower values
                            indicate more out-of-comp trades are occurring.
                        </div>
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
