import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import moment from "moment";
import { BarSameDayChart } from './BarSameDayChart';
import { useSelector } from 'react-redux';
import { chartBarSameDay } from '../../../constants/dashboard/chartBarSameDay';
import { constants } from '../../../constants';
import { getVolume, isRequesting } from '../../../utils';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { WidgetDescription } from '../WidgetDescription';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { Currencies } from '../../../types/enums/Currency';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "Same-Day BWICs %: Stability Barometer";

export function BarSameDayWidget() {
    const { serverDateType } = chartBarSameDay;

    const sameDayPeriods = useSelector((s) => s.dashboard.sameDayPeriods);
    const requestStateFetchSameDayPeriods = useSelector((s) => s.dashboard.requestStateFetchSameDayPeriods);
    const filter = useSelector((s) => s.dashboard.filter);
    const filterActive = useSelector((s) => s.dashboard.filterActive);

    const { selectedRatings, selectedCurrencies } = filter;

    const filterByRatingCurrency = (periodData) => {
        let byRating = periodData;
        if (selectedRatings.length) {
            byRating = periodData.filter(d => !!selectedRatings.some(r => d.rating === r));
        }
        if (selectedCurrencies.length) {
            return byRating.filter(d => !!selectedCurrencies.some(c => d.currency === c));
        }
        return byRating;
    };

    const filterData = () => {
        return cloneDeep(sameDayPeriods).map(d => {
            if (selectedRatings.length || selectedCurrencies.length) {
                d.sameDayBwicsData = filterByRatingCurrency(d.sameDayBwicsData);
            }
            return d;
        });
    };

    const getSelectedCurrency = () => {
        if (selectedCurrencies.length) {
            return Currencies.filter(c => selectedCurrencies.some(sc => sc === c)).map(c => ({
                currencyName: c,
                amount: 0
            }));
        }
        return Currencies.map(c => ({ currencyName: c, amount: 0 }));
    };

    const getAmountPercent = (amountByPeriod, amountBySameDay) => {
        if (amountBySameDay && amountByPeriod) {
            return ((amountBySameDay / amountByPeriod) * 100).toFixed(2);
        }
        return 0;
    };

    const getAmountToDisplay = (amountByCurrency) => {
        const { currencySign } = chartBarSameDay;
        return amountByCurrency.map(c => `${currencySign[c.currencyName]}${getVolume(c.amount)}`).join(' / ') + 'MM';
    };

    const getTotalAmount = (sameDayBwicsData = []) => {
        return sameDayBwicsData.reduce((result, current) => {
            result.amountByPeriod += current.amount;
            if (current.sameDay) {
                result.amountBySameDay += current.amount;
            }
            result.amountBySameDayByCurrency = result.amountBySameDayByCurrency.map(c => {
                if (current.currency === c.currencyName && current.sameDay) {
                    c.amount += current.amount;
                }
                return c;
            });
            return result;
        }, { amountByPeriod: 0, amountBySameDay: 0, amountBySameDayByCurrency: getSelectedCurrency() });
    };

    const formatPeriodToDisplay = (period) => {
        const from = moment(period, serverDateType).format(constants.dashboardDateFormat);
        const to = moment(period, serverDateType).add(6, 'days').format(constants.dashboardDateFormat);
        return `${from} - ${to}`;
    };

    const getChartData = () => {
        const data = filterData();
        return data.map((d) => {
            const { amountByPeriod, amountBySameDay, amountBySameDayByCurrency } = getTotalAmount(d.sameDayBwicsData || []);
            const amountPercentBySameDay = getAmountPercent(amountByPeriod, amountBySameDay);
            const amountToDisplay = getAmountToDisplay(amountBySameDayByCurrency);
            const periodToDisplay = formatPeriodToDisplay(d.periodName);
            return {
                amountByPeriod,
                amountBySameDay,
                amountBySameDayByCurrency,
                amountPercentBySameDay,
                amountToDisplay,
                periodToDisplay,
                periodFrom: moment(d.periodName.split('-')[0], serverDateType).format(constants.dashboardDateFormat)
            };
        });
    };

    const chartData = getChartData();

    const chart = useMemo(() => <BarSameDayChart data={chartData} divId="bar-same-day" />, [chartData]);

    return (
        <DashboardWidget title={chartName} className="same-day" filterActive={filterActive}>
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchSameDayPeriods)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getSameDayBwics}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-same-day-bwics"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}<span className="date">weekly avg</span></h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent requestState={requestStateFetchSameDayPeriods}>
                            {chart}
                            <WidgetDescription
                                id="same-day-clamp"
                                lines={2}
                                text="Same-day BWICs are BWICs that are announced and scheduled on the same day. When this
                                      type of BWIC represents a large portion of the market, it can indicate liquidity concerns."
                            />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
