import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { mainConfig, Plot } from '../../common/charts'
import { kIndicator } from '../../../constants/dashboard/kIndicator';
import { KIndicator } from '../../../types/dashboard/KIndicator';
import {minBy, maxBy} from 'lodash';

interface Props {
    chartDiv?: string;
    data: KIndicator[];
}

export const KIndicatorChart: FC<Props> = ({ chartDiv, data }) => {
    const dates = data.map(d => d.date);

    const trace = {
        x: dates,
        y: data.map(d => d.value),
        hoverinfo: 'none',
        mode: 'lines',
        type: 'scatter',
        name: 'k-indicator',
        marker: kIndicator.traceMarker
    };

    const traceZeroLine = {
        x: dates,
        y: data.map(() => 0),
        mode: 'lines',
        type: 'scatter',
        name: 'k-indicator-zero-line',
        hoverinfo: 'none',
        line: { dash: 'dot', width: 1, color: kIndicator.zerolinecolor },
    };

    const range = useMemo(() => [
        Math.floor(minBy(data, (d) => d.value)?.value || 0),
        Math.ceil(maxBy(data, (d) => d.value)?.value || 0)
    ], [data])

    const totalYTicks = Math.abs(range[0]) + Math.abs(range[1]);
    const endFirstShape = (totalYTicks - (range[1] - 1)) / totalYTicks ;
    const endSecondShape = Math.abs(range[0] + 1) / totalYTicks

    const layout = {
        font: kIndicator.layoutFont,
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        margin: kIndicator.margin,
        autoscale: false,
        hoverlabel: kIndicator.hoverlabel,
        xaxis: {
            type: 'date',
            showgrid: false,
            tickcolor: kIndicator.tickColor,
            tickfont: kIndicator.tickFont,
            range: [moment().subtract(1, 'year').toDate(), moment().toDate()],
            tickformat: kIndicator.xAxisTickFormat,
            dtick: kIndicator.xAxisDTick,
            /* rangeslider: {
                 bgcolor: 'rgba(79, 123, 156, 0.2)',
             },*/
        },
        yaxis: {
            titlefont: kIndicator.titlefont,
            gridcolor: kIndicator.tickColor,
            tickcolor: kIndicator.tickColor,
            tickfont: kIndicator.tickFont,
            dtick: kIndicator.yaxisDtick,
            range,
            zeroline: false,
            showgrid: false,

        },
        shapes: [
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: endFirstShape,
                y1: 1,
                type: 'rect',
                fillcolor: '#B2EDC7',
                line: {
                    width: 0,
                }
            },
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: endSecondShape,
                y1: endFirstShape,
                type: 'rect',
                fillcolor: '#FFE8B6',
                line: {
                    width: 0,
                }
            },
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: 0,
                y1: endSecondShape,
                type: 'rect',
                fillcolor: '#FCDED4',
                line: {
                    width: 0,
                }
            },
        ]
    };

    return (
        <Plot
            divId={chartDiv}
            data={[trace, traceZeroLine]}
            layout={layout}
            config={mainConfig}
        />
    )
};

KIndicatorChart.defaultProps = {
    chartDiv: 'k-indicator-chart'
};
