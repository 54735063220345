import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { UsBslNewIssueWeeklySpread } from './UsBslNewIssueWeeklySpread';

export function UsBslNewIssueWasWidget() {
    return (
        <UsBslNewIssueWeeklySpread
            divId="us-bsl-new-issue-spreads-was-chart"
            title="US BSL New Issue WAS"
            field="was"
            feature={SubscriptionFeature.UsBslNewIssueWasGraph}
        />
    );
}
