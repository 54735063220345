import React, { useMemo } from 'react';
import { constants } from '../../../constants';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { ChartGrouping, ChartTypes, ChartUnits, ChartUnitsList } from '../../../types/dashboard/AmrChart';
import { Currency } from '../../../types/enums/Currency';
import { DropDownList } from '../../controls/DropDownList';
import { FilterPanel, FilterSection, FilterSwitch } from '../../filters';

export interface ShortLongDatedDealsChartFilter {
    transactionType: TransactionType | string;
    units: ChartUnits;
    grouping: ChartGrouping;
    currencyCode?: Currency;
}

export interface ShortLongDatedDealsChartFilterProps extends ShortLongDatedDealsChartFilter {
    onChange: (value: ShortLongDatedDealsChartFilter) => void;
}

export function ShortLongDatedDealsFilter({
    onChange,
    ...values
}: ShortLongDatedDealsChartFilterProps) {
    const handleValueChange = (prop: keyof ShortLongDatedDealsChartFilter) => (value: any) => {
        onChange({
            ...values,
            [prop]: value,
        });
    };

    const chartTypes = useMemo(() => {
        const all = {
            value: constants.allListItem,
            text: 'All',
            selected: values.transactionType === constants.allListItem,
        };

        return [
            all,
            ...ChartTypes.map(({ value, text }) => ({
                value,
                text,
                selected: values.transactionType === value,
            })),
        ];
    }, [values.transactionType]);

    const chartUnits = useMemo(() => ChartUnitsList
        .map(({ value, text }) => ({
            value,
            text,
            selected: values.units === value,
        })), [values.units]);

    return (
        <FilterPanel>
            <FilterSection>
                <DropDownList
                    items={chartTypes}
                    onChange={({ value }) => handleValueChange('transactionType')(value)}
                    className="custom-drop-down-ghost"
                    prefix="Type: "
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={chartUnits}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('units')(value)}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[Currency.USD, Currency.EUR].map(currencyCode =>
                        <FilterSwitch
                            key={currencyCode}
                            caption={currencyCode}
                            selected={values.currencyCode === currencyCode}
                            disabled={false}
                            onClick={() => handleValueChange('currencyCode')(currencyCode)}
                    />)}
                </div>
            </FilterSection>
            <FilterSection>
                <div className="filter-group filter-group-type01 flex-row">
                    {[
                        ChartGrouping.M,
                        ChartGrouping.Q,
                        ChartGrouping.Y,
                    ].map(grouping =>
                        <FilterSwitch
                            key={grouping}
                            caption={grouping}
                            selected={values.grouping === grouping}
                            disabled={false}
                            onClick={() => handleValueChange('grouping')(grouping)}
                    />)}
                </div>
            </FilterSection>
        </FilterPanel>
    );
}
