import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isRequesting } from '../../../utils';
import { bwicStatuses } from '../../../constants';
import { Table } from '../../bidding/common/table';
import { dailyTradingStatsColumns } from '../tables/columns/tradingStats';
import { numericUtils } from '../../../utils';
import { dashboardExportTradingStats, dashboardInitTradingStats } from '../../../actions';
import { TradingStats } from '../../../types/models/TradingStats';
import { TradingStatsColumnDefinitions } from '../../bidding/common/table/types/TradingStatsColumnDefinitions';
import { AppState } from '../../../types/state/AppState';
import IconSVG from '../../../styles/svg-icons';
import { RatingGroup, ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { RequestState } from '../../../constants/request-state';
import { ShowFor } from '../../access';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { OnHoverTooltip, Preloader } from '../../common';
import { PxTalkNormalizationTable } from '../../common/PxTalk/PxTalkNormalizationTable'
import { Currencies } from '../../../types/enums/Currency';
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemHeader } from '../../common/accordion';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

const chartName = "Daily Trade Analytics";

interface Props {
    collapsibleDescription?: boolean;
}

export function DailyTradingStatsWidget({ collapsibleDescription }: Props) {
    const dispatch = useDispatch();
    const filter = useSelector((s: AppState) => s.dashboard.filter);
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);
    const tradingStats = useSelector((s: AppState) => s.dashboard.tradingStats);
    const requestStateExportTradingStats = useSelector((s: AppState) => s.dashboard.requestStateExportTradingStats);
    const requestStateFetchTradingStats = useSelector((s: AppState) => s.dashboard.requestStateFetchTradingStats);

    const { periodName, tradingStatsData } = tradingStats;
    const { selectedCurrencies, selectedRatings } = filter;

    const [showDescription, setShowDescription] = useState(false);

    useEffect(() => {
        dispatch(dashboardInitTradingStats());
    }, [dispatch]);

    const tradedCondition = (security: TradingStats) =>
        security.bwicStatus === bwicStatuses.finished.key &&
        security.traded &&
        security.hasColor;

    const colorCondition = (security: TradingStats) =>
        security.normalizedLevel &&
        security.traded &&
        security.bwicStatus === bwicStatuses.finished.key &&
        security.hasColor;

    const calculateStats = (data: TradingStats[]) => {
        const totalTradingVolume = data.reduce((result: number, current: TradingStats) => {
            if (tradedCondition(current)) {
                result += current.amount;
            }
            return result
        }, 0);

        const totalPostedVolume = data.reduce((result: number, current: TradingStats) => {
            result += current.amount;
            return result;
        }, 0);

        const tradedCount = data.filter((s: TradingStats) => tradedCondition(s)).length;

        const postedCount = data.length;

        const dntRate = postedCount > 0 ? (1 - tradedCount / postedCount) * 100 : 0;

        const countNormalizedLevel = data.filter((s: TradingStats) => colorCondition(s)).length;

        const amountPriceInColor = data.reduce((result: number, current: TradingStats) => {
            if (tradedCondition(current)) {
                result += current.priceInColor;
            }
            return result;
        }, 0);

        const priceInColor = (numericUtils.divideSafe(amountPriceInColor, tradedCount) || 0) * 100;

        const avgTradeVolume = numericUtils.divideSafe(totalTradingVolume, tradedCount) || 0;

        const tradedWithColorData = data.reduce((result: number, current: TradingStats) => {
            if (colorCondition(current) && current.normalizedLevel) {
                result += current.normalizedLevel
            }
            return result;
        }, 0);

        const avgColorPrice = numericUtils.divideSafe(tradedWithColorData, countNormalizedLevel) || 0;

        const pxTalkAmount = data.reduce((result: number, current: TradingStats) => {
            if (current.avgPxTalk && tradedCondition(current)) {
                result += current.avgPxTalk
            }
            return result;
        }, 0);

        const pxTalkCount = data.filter((s: TradingStats) => (s.avgPxTalk && tradedCondition(s))).length;

        const avgPxTalk = numericUtils.divideSafe(pxTalkAmount, pxTalkCount) || 0;

        return {
            totalTradingVolume,
            totalPostedVolume,
            tradedCount,
            postedCount,
            dntRate,
            priceInColor,
            avgTradeVolume,
            avgColorPrice,
            avgPxTalk
        }
    };

    const getTotal = (stats: TradingStatsColumnDefinitions[]) => {
        const total = {
            totalTradingVolume: 0,
            totalPostedVolume: 0,
            tradedCount: 0,
            postedCount: 0,
            dntRate: 0,
            priceInColor: 0,
            avgTradeVolume: 0
        };

        let sumProductForDntRange = 0;
        let sumProductForPriceInColor = 0;

        stats.forEach((s: TradingStatsColumnDefinitions) => {
            total.totalTradingVolume += s.totalTradingVolume;
            total.totalPostedVolume += s.totalPostedVolume;
            total.tradedCount += s.tradedCount;
            total.postedCount += s.postedCount;
            total.dntRate += s.dntRate;
            total.priceInColor += s.priceInColor;
            total.avgTradeVolume += s.avgTradeVolume;
            sumProductForDntRange += (s.postedCount * s.dntRate);
            sumProductForPriceInColor += (s.tradedCount * s.priceInColor);
        });

        return {
            rating: 'Total',
            ...total,
            dntRate: numericUtils.divideSafe(sumProductForDntRange, total.postedCount) || 0,
            priceInColor: numericUtils.divideSafe(sumProductForPriceInColor, total.tradedCount) || 0,
            avgTradeVolume: numericUtils.divideSafe(total.totalTradingVolume, total.tradedCount) || 0,
            avgColorPrice: NaN,
            avgPxTalk: NaN,
        }
    };

    const filterDataByCurrency = () => {
        if (selectedCurrencies.length) {
            return tradingStatsData.filter((statItem: TradingStats) => selectedCurrencies.some(c => c === statItem.currency))
        }
        return tradingStatsData
    };

    const getTableData = () => {
        const ratingsList = selectedRatings.length
            ? ratingWithGroupValues
                .filter(rating =>
                    selectedRatings.some(selectedRating => selectedRating === rating)
                )
            : ratingWithGroupValues;
        const stats: TradingStatsColumnDefinitions[] = ratingsList.map(r => {
            const dataByRating = filterDataByCurrency().filter((data: TradingStats) => data.rating === r);
            return {
                rating: r === RatingGroup.OTHER ? 'Other' : r,
                ...calculateStats(dataByRating),
            }
        });

        const total = getTotal(stats);
        stats.push(total);
        return stats;
    };

    const tableData = getTableData();

    const handleExport = () => {
        const filterCurrencies = selectedCurrencies.length ? selectedCurrencies : Currencies;
        const filterRatings = selectedRatings.length ? selectedRatings : ratingWithGroupValues;
        dispatch(dashboardExportTradingStats(filterCurrencies, filterRatings))
    };

    const renderDescription = () => (
        <div className="description">
            <p>
                <sup>1</sup> MM <sup>2</sup> Traded Only <sup>3</sup> Average
                <span className="time">Updated daily at 5:00 PM EST.</span>
            </p>
            <p>Color% - % of trades where a seller discloses second best price received</p>
            <div className="info">
                Color - second best price received during BWIC; digitized by the KTX ATS Platform according to the Rules
                <OnHoverTooltip overlayClassName="px-talk-tooltip" overlay={<PxTalkNormalizationTable />}>
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </div>
            <div className="info">
                Px Talk - expected price for a security sent by market-makers prior to a BWIC; digitized by the KTX ATS
                Platform according to the Rules
                <OnHoverTooltip overlayClassName="px-talk-tooltip" overlay={<PxTalkNormalizationTable />}>
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </div>
        </div>
    );

    const renderAccordionDescription = () =>
        <Accordion>
            <AccordionItem isSelected={showDescription}>
                <AccordionItemHeader
                    isSelected={showDescription}
                    onItemHeaderClick={(e) => {
                        e.stopPropagation();
                        setShowDescription(!showDescription)
                    }}
                    showAdditionalIcon={false}
                >
                    {showDescription ? "Hide" : "Show"} Description
                </AccordionItemHeader>
            </AccordionItem>
            <AccordionItemBody visible={showDescription}>
                {renderDescription()}
            </AccordionItemBody>
        </Accordion>

    return (
        <DashboardWidget title={chartName} filterActive={filterActive} className="daily-stats height-size01 double-size">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchTradingStats)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getTradingStats}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-daily-trades-analytics"
                >
                    <DashboardWidgetHeader className="flex-row">
                        <h3>{chartName}<span className="date">{periodName}</span></h3>
                        {
                            requestStateFetchTradingStats !== RequestState.failure &&
                            <ShowFor feature={SubscriptionFeature.getTradingStats}>
                                <div className="flex-item-right flex-row">
                                    <Preloader small={true} fullScreen={false} inProgress={requestStateExportTradingStats} text="Exporting…">
                                        <button onClick={handleExport} className="btn btn-link">
                                            <IconSVG name="export" width={16} height={16} /> Export
                                        </button>
                                    </Preloader>
                                </div>
                            </ShowFor>
                        }
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchTradingStats}
                        description={
                            <ShowFor feature={SubscriptionFeature.getTradingStats}>
                                {collapsibleDescription ? renderAccordionDescription() : renderDescription()}
                            </ShowFor>
                        }
                    >
                        <Table
                            dataItems={tableData}
                            columns={dailyTradingStatsColumns}
                        />
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
