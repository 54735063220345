import * as React from 'react';
import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { DealerContactOptions } from './DealerContactOptions';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { companyStatuses, constants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { OnHoverTooltip } from '../common/OnHoverTooltip';

interface DealerDistributionListProps {
    dealer: BrokerDealerCompanySlim;
    enableContactOptions: boolean;
}

export const DealerDistributionList: React.FC<DealerDistributionListProps> = ({ dealer, enableContactOptions }) => {
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(false);
    const inviteToJoinRequestStatus = useSelector((state: AppState) => state.dealerList.inviteToJoinRequestStatus);

    const handleSendInviteToJoin = () => {
        dispatch(dealerListActions.distributionListInviteToJoin(dealer.id))
    };

    return (
        <div className={cn("data-table-item-row flex-row", { active })}>
            <div className="flex-none data-list-name">
                <span className="name">{constants.emptyPlaceholder}</span>
            </div>
            <div className="flex-none data-list-title">
                <IconSVG name="distrib" width={16} height={16} />Distribution list
            </div>
            <div className="flex-none data-list-email">
                <OnHoverTooltip overlay={dealer.distributionList}>
                    <span className="display-inline email text-ellipsis">
                        <a href={`mailto:${dealer.distributionList}`}>{dealer.distributionList}</a>
                    </span>
                </OnHoverTooltip>
            </div>
            <div className="flex-none data-list-phone">{constants.emptyPlaceholder}</div>
            <div className="flex-item-right">
                {dealer.status !== companyStatuses.active.statusCode && enableContactOptions && (
                    <OnHoverTooltip wrapperClass="flex-item-right" overlay="Invite Sent" disabled={!dealer.distributionListInvitationSent}>
                        <DealerContactOptions
                            disabled={dealer.distributionListInvitationSent}
                            onSend={handleSendInviteToJoin}
                            sending={inviteToJoinRequestStatus[dealer.id]}
                            onMenuShow={() => setActive(true)}
                            onMenuHide={() => setActive(false)}
                        />
                    </OnHoverTooltip>
                )}
            </div>
        </div>
    )
};
