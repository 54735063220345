import { useDispatch } from "react-redux";
import { RequestState } from "../../../constants/request-state";
import { useAppSelector } from "../../../effects/useAppSelector";
import { isRequestNone } from "../../../utils";
import { useEffect } from "react";
import { dashboardFetchBwics, errorActions } from "../../../actions";
import { dashboardService } from "../../../services";

export const useScheduledBwics = (readonly: boolean = true) => {
    const dispatch = useDispatch();
    const bwics = useAppSelector(s => s.dashboard.bwics);
    const myBwics = useAppSelector(s => s.dashboard.myBwics);
    const requestStateFetchBwics = useAppSelector(s => s.dashboard.requestStateFetchBwics);

    useEffect(() => {
        if (isRequestNone(requestStateFetchBwics) && !readonly) {
            (async () => {
                try {
                    dispatch(dashboardFetchBwics(RequestState.request));
                    const result = await dashboardService.fetchScheduledBwics();
                    dispatch(dashboardFetchBwics(RequestState.success, result));
                } catch (e) {
                    dispatch(dashboardFetchBwics(RequestState.failure));
                    dispatch(errorActions.error(e));
                }
            })();
        }

    }, [dispatch, readonly, requestStateFetchBwics])
    return { requestStateFetchBwics, bwics, myBwics };

}