import React, { SetStateAction, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { MostTradedTickersData } from '../../../types/dashboard/MostTradedTickers';
import { isRequesting, isRequestSuccess, moneyUtils, numericUtils } from '../../../utils';
import { Table } from '../../bidding/common/table';
import { useSortedList } from '../../../effects';
import { SORT, SORTING_TYPE, constants } from '../../../constants';
import { Pagination } from '../tables/Pagination';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { DashboardNoSelectedFilters } from '../DashboardNoSelectedFilters';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { ShowFor } from '../../access';

const chartName = "Most Traded Bonds";

export function MostTradedTickersWidget() {
    const itemsOnPage = 6;
    const mostTradedTickers = useSelector(mostTradedTickersSelector);
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);
    const requestStateMostTradedTickers = useSelector((s: AppState) => s.dashboard.requestStateMostTradedTickers);

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(SORT.ASC);

    const [sortedDataItems] = useSortedList(mostTradedTickers.mostTradedTickers, sortBy, sortDirection);

    const setSortParams = (field: SetStateAction<null>, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const isEmptyList = isRequestSuccess(requestStateMostTradedTickers) && !sortedDataItems.length;

    const renderTable = useMemo(() => {
        if (isEmptyList) {
            return <DashboardNoSelectedFilters />
        }
        return (
            <Pagination list={sortedDataItems} itemsOnPage={itemsOnPage}>
                {(items: MostTradedTickersData[]) => (
                    <Table
                        dataItems={items}
                        columns={mostTradedTickersColumns}
                        onSort={setSortParams}
                    />
                )}
            </Pagination>
        )
    }, [sortedDataItems, isEmptyList]);

    return (
        <DashboardWidget title={chartName} filterActive={filterActive} className="most-traded-tickers">
            <DashboardSkeleton inProgress={isRequesting(requestStateMostTradedTickers)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getMostTradedTickers}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-most-traded-bonds"
                >
                    <DashboardWidgetHeader>
                        <h3>
                            {chartName}<span className="date">rolling 30 days</span>
                        </h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateMostTradedTickers}
                        description={
                            <ShowFor feature={SubscriptionFeature.getMostTradedTickers}>
                                {isEmptyList ? '' : 'This table shows which Tickers have been traded the most over the past 30 days. Filter and sort for more detail.'}
                            </ShowFor>
                        }
                    >
                            {renderTable}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}

const mostTradedTickersSelector = createSelector([
    (s: AppState) => s.dashboard.mostTradedTickers,
    (s: AppState) => s.dashboard.filter.selectedRatings,
    (s: AppState) => s.dashboard.filter.selectedCurrencies
], (
    tickersData,
    selectedRatings,
    selectedCurrencies
) => {
    let mostTradedTickers = tickersData.mostTradedTickers;
    if (selectedRatings.length) {
        mostTradedTickers = mostTradedTickers.filter(t => selectedRatings.some(c => c === t.rating));
    }
    if (selectedCurrencies.length) {
        mostTradedTickers = mostTradedTickers.filter(t => selectedCurrencies.some(c => c === t.currency));
    }
    return { ...tickersData, mostTradedTickers }
});

const buyColumnsDefinitions: IColumnDefinition<MostTradedTickersData>[] = [
    {
        columnKey: 'ticker',
        renderColumnHeaderContent: () => 'Ticker',
        renderColumnContent: entity => entity.ticker,
        className: 'data-list-ticker',
        sortingField: 'ticker',
        sortingType: SORTING_TYPE.string,
    }, {
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Rtg',
        renderColumnContent: entity => entity.rating,
        className: 'data-list-rating',
        sortingField: 'rating',
        sortingType: SORTING_TYPE.rating,
    }, {
        columnKey: 'trades',
        renderColumnHeaderContent: () => 'Trades #',
        renderColumnContent: entity => entity.trades,
        className: 'text-right data-list-trades',
        sortingField: 'trades',
        sortingType: SORTING_TYPE.string,
    }, {
        columnKey: 'trades-vol',
        renderColumnHeaderContent: () => 'Trades Vol (MM)',
        renderColumnContent: entity => moneyUtils.amountToMM(entity.tradesVol),
        className: 'text-right data-list-trades-vol p-r-0',
        sortingField: 'tradesVol',
        sortingType: SORTING_TYPE.number,
    }, {
        columnKey: 'avg-size',
        renderColumnHeaderContent: () => 'Avg Size, MM',
        renderColumnContent: entity => moneyUtils.amountToMM(entity.avgSize),
        className: 'text-right data-list-avg-size hidden',
        sortingField: 'avgSize',
        sortingType: SORTING_TYPE.number,
    }, {
        columnKey: 'avg-px-talk',
        renderColumnHeaderContent: () => 'Avg Px Talk',
        renderColumnContent: entity => entity.avgPxTalk ? numericUtils.round(entity.avgPxTalk, 2) : constants.emptyPlaceholder,
        className: 'text-right data-list-avg-px hidden',
        sortingField: 'avgPxTalk',
        sortingType: SORTING_TYPE.number,
    }, {
        columnKey: 'avg-color',
        renderColumnHeaderContent: () => 'Avg Color',
        renderColumnContent: entity => entity.avgColor ? numericUtils.round(entity.avgColor, 2) : constants.emptyPlaceholder,
        className: 'text-right data-list-avg-color hidden',
        sortingField: 'avgColor',
        sortingType: SORTING_TYPE.number,
    }
];

const mostTradedTickersColumns = buyColumnsDefinitions.map(c => {
    return new ColumnBuilder(c);
});
