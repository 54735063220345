import React from 'react';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { moneyUtils } from '../../../../utils';
import { TradingStatsColumnDefinitions } from '../../../bidding/common/table/types/TradingStatsColumnDefinitions';

const tradingStatsColumnDefinitions: IColumnDefinition<TradingStatsColumnDefinitions>[] = [
    {
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Rtg',
        renderColumnContent: entity => entity.rating,
        headerClassName: 'data-rating',
        bodyClassName: 'data-rating',
    },
    {
        columnKey: 'tot-traded-vol-mm',
        renderColumnHeaderContent: () => (<>Traded<span className="sup">1</span></>),
        renderColumnContent: entity => moneyUtils.amountToMM(entity.totalTradingVolume),
        headerClassName: 'data-total text-right',
        bodyClassName: 'data-total text-right',
    },
    {
        columnKey: 'tot-posted-vol-mm',
        renderColumnHeaderContent: () => (<>Posted<span className="sup">1</span></>),
        renderColumnContent: entity => moneyUtils.amountToMM(entity.totalPostedVolume),
        headerClassName: 'data-total text-right',
        bodyClassName: 'data-total text-right',
    },
    {
        columnKey: 'traded-count',
        renderColumnHeaderContent: () => (<>Traded#</>),
        renderColumnContent: entity => String(entity.tradedCount),
        headerClassName: 'data-count text-right',
        bodyClassName: 'data-count text-right',
    },
    {
        columnKey: 'posted-count',
        renderColumnHeaderContent: () => (<>Posted#</>),
        renderColumnContent: entity => String(entity.postedCount),
        headerClassName: 'data-count text-right',
        bodyClassName: 'data-count text-right',
    },
    {
        columnKey: 'dnt-rate',
        renderColumnHeaderContent: () => <>DNT%</>,
        renderColumnContent: entity => entity.dntRate.toFixed(2),
        headerClassName: 'data-dnt text-right',
        bodyClassName: 'data-dnt text-right',
    },
    {
        columnKey: 'price-in-color',
        renderColumnHeaderContent: () => (<>Color%<span className="sup">2</span></>),
        renderColumnContent: entity => entity.priceInColor.toFixed(2),
        headerClassName: 'data-color text-right',
        bodyClassName: 'data-color text-right',
    },
    {
        columnKey: 'avg-trade-vol-mm',
        renderColumnHeaderContent: () => (<>Trade<span className="sup">1,3</span></>),
        renderColumnContent: entity => moneyUtils.amountToMM(entity.avgTradeVolume),
        headerClassName: 'data-volume text-right',
        bodyClassName: 'data-volume text-right',
    },
    {
        columnKey: 'avg-color-price',
        renderColumnHeaderContent: () => (<>Color<span className="sup">3</span></>),
        renderColumnContent: entity => isNaN(entity.avgColorPrice) ? '' : entity.avgColorPrice.toFixed(2),
        headerClassName: 'data-avg-color text-right',
        bodyClassName: 'data-avg-color text-right',
    },
    {
        columnKey: 'avg-px-talk',
        renderColumnHeaderContent: () => (<>Px Talk<span className="sup">3</span></>),
        renderColumnContent: entity => isNaN(entity.avgPxTalk) ? '' : String(entity.avgPxTalk.toFixed(2)),
        headerClassName: 'data-px-talk text-right',
        bodyClassName: 'data-px-talk text-right',
    },
];

export const dailyTradingStatsColumns = tradingStatsColumnDefinitions.map(c => {
    return new ColumnBuilder(c);
});
