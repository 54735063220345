import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { UsBslNewIssueWeeklySpread } from './UsBslNewIssueWeeklySpread';

export function UsBslNewIssueDsWidget() {
    return (
        <UsBslNewIssueWeeklySpread
            divId="us-bsl-new-issue-spreads-ds-chart"
            title="US BSL New Issue DS"
            field="ds"
            feature={SubscriptionFeature.UsBslNewIssueDsGraph}
        />
    );
}
