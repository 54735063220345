import React, { SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { Pagination } from '../tables/Pagination';
import { SORT } from '../../../constants';
import { useSortedList } from '../../../effects';
import { isRequesting, isRequestNone } from '../../../utils';
import { Table } from '../../bidding/common/table';
import { buyColumns } from '../tables/columns/BuyColumns';
import { DashboardNoData } from '../DashboardNoData';
import { DashboardSkeleton } from '../DashboardSkeleton';

export function BidAsDealerRequests() {
    const itemsOnPage = 7;

    const bidAsDealerRequests = useSelector((s: AppState) => s.dashboard.bidAsDealerRequests);
    const requestStateBidAsDealerRequests = useSelector((s: AppState) => s.dashboard.requestStateBidAsDealerRequests);

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(SORT.ASC);

    const [sortedDataItems] = useSortedList(bidAsDealerRequests, sortBy, sortDirection);

    const setSortParams = (field: SetStateAction<null>, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    return (
        <DashboardSkeleton inProgress={isRequestNone(requestStateBidAsDealerRequests) || isRequesting(requestStateBidAsDealerRequests)}>
            {bidAsDealerRequests.length ? (
                <Pagination list={sortedDataItems} itemsOnPage={itemsOnPage}>
                    {(items: any) => (
                        <Table
                            dataItems={items}
                            columns={buyColumns}
                            onSort={setSortParams}
                        />
                    )}
                </Pagination>
            ) : (
                <DashboardNoData
                    imgName="no-data"
                    imgWidth={164}
                    imgHeight={128}
                    text="No bids have been placed on any securities yet."
                />
            )}
        </DashboardSkeleton>
    );
}
