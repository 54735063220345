import { useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router';
import { trim } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { dashboardInit, dashboardResetNotAppliedFilter } from '../../actions/dashboard.actions';
import { BarScheduledBwicsWidget } from './barScheduledBwics/BarScheduledBwicsWidget';
import { HeatMapScheduledBwicsWidget } from './heatmapScheduledBwics/HeatmapScheduledBwicsWidget';
import { BoxPxTalkAndColorWidget } from './boxPxTalkAndColor/BoxPxTalkAndColorWidget';
import { BarDntWidget } from './barDnt/BarDntWidget';
import { MyScheduledBwicsWidget } from './mySellBuy/MyScheduledBwicsWidget';
import { PortfolioWidget } from './portfolio/PortfolioWidget';
import { Preloader } from '../common';
import { searchSecuritiesActions } from '../../actions';
import { BarColorWidget } from './barColor/BarColorWidget';
import { BarSameDayWidget } from './sameDay/BarSameDayWidget';
import { DailyTradingStatsWidget } from './dailyTradingStats/DailyTradingStatsWidget';
import { PriceLevelWidget } from './priceLevel/PriceLevelWidget';
import { DtcSellingBuyingWidget } from './dtcSellingBuying/DtcSellingBuyingWidget';
import { DtcIgHyWidget } from './dtcIgHy/DtcIgHyWidget';
import { AAAPriceLevelWidget } from './aaaPriceLevel/AAAPriceLevelWidget';
import { MostTradedTickersWidget } from './mostTradedTickers/MostTradedTickersWidget';
import { PxTalkDeviationWidget } from './pxTalkDeviation/PxTalkDeviationWidget';
import { BwicPercentOfTraceWidget } from './bwicPercentOfTrace/BwicPercentOfTraceWidget';
import { DashboardKWatchNewsWidget } from './k-watchNews/DashboardKWatchNewsWidget';
import { BiggestMoversWidget } from './biggestMovers/BiggestMoversWidget';
import { isRequestSuccess } from '../../utils';
import { Filter } from './Filter';
import { TopIssuersWidget } from './topIssuers/TopIssuersWidget';
import { TopArrangersWidget } from './topIssuers/TopArrangersWidget';
import { TopTrusteesWidget } from './topIssuers/TopTrusteesWidget';
import { TopStaticDeals } from './topIssuers/TopStaticDeals';
import { TopDebutDeals } from './topIssuers/TopDebutDeals';
import { KIndicatorWidget } from './k-indicator/KIndicatorWidget';
import { IssueTransactionVolumeWidget } from './issueTransactionVolume/IssueTransactionVolumeWidget';
import { UsBslEsgNewIssueWidget } from './usBslEsgNewIssue/UsBslEsgNewIssueWidget';
import { UsBslEuCompliantDealsWidget } from './usBslEuCompliantDeals/UsBslEuCompliantDealsWidget';
import { ShortLongDatedDealsWidget } from './shortLongDatedDeals/ShortLongDatedDealsWidget';
import { IssuanceSpreadWidget } from './issuanceSpread/IssuanceSpreadWidget';
import { UsBslNewIssueWeeklySpreads } from './usBslNewIssueSpreads/UsBslNewIssueWeeklySpreads';
import { pipelineRequestCriteria, roles, routes } from '../../constants';
import { useScheduledBwics } from './hooks/useScheduledBwics';
import { ShowFor } from '../access';
import { IntersectionObserverProvider } from '../common/IntersectionObsereverProvider';

function DashboardComponent({ location }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const anchorElement = useRef(null);
    const requestStateInitDashboard = useSelector((s) => s.dashboard.requestStateInitDashboard);
    const filterActive = useSelector((s) => s.dashboard.filterActive);

    useScheduledBwics(false);

    useEffect(() => {
        dispatch(dashboardInit());

        return () => {
            dispatch(searchSecuritiesActions.reset());
            dispatch(dashboardResetNotAppliedFilter());
        };
    }, [dispatch]);

    useEffect(() => {
        anchorElement.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const setAnchorRef = (anchor) => {
        if (anchor === trim(location.hash, '#')) {
            return anchorElement;
        }

        return undefined;
    };

    const redirectToIssuanceMonitor = () => history.push(routes.AMRPipeline)

    return (
        <div className="container dashboard-container">
            <div className="sub-header sub-header-head">
                <div className="sub-header-row flex-row type01">
                    <h1>Dashboard</h1>
                </div>
            </div>
            <Filter />
            <div className={classNames('dashboard', { filtered: filterActive })}>
                <Preloader inProgress={!isRequestSuccess(requestStateInitDashboard)}>
                    {
                        isRequestSuccess(requestStateInitDashboard) &&
                        <IntersectionObserverProvider options={{ threshold: 1 }}>
                            <h1 ref={setAnchorRef('bwics-volume-and-price')}>BWICs Volume & Price</h1>
                            <div className="dashboard-row flex-row">
                                <BarScheduledBwicsWidget />
                                <HeatMapScheduledBwicsWidget />
                                <PriceLevelWidget />
                                <DailyTradingStatsWidget />
                                <BiggestMoversWidget />
                                <MostTradedTickersWidget />
                                <DtcIgHyWidget />
                                <DtcSellingBuyingWidget />
                                <BwicPercentOfTraceWidget />
                                <DashboardKWatchNewsWidget />
                            </div>

                            <h1>Market Health Indicators</h1>
                            <div className="dashboard-row flex-row">
                                <KIndicatorWidget />
                                <AAAPriceLevelWidget />
                                <BarDntWidget />
                                <BarColorWidget />
                                <BarSameDayWidget />
                                <PxTalkDeviationWidget />
                            </div>

                            <ShowFor roles={roles.issuanceMonitorAccess()}>
                                <h1 ref={setAnchorRef('issuance-monitor')}>Issuance Monitor</h1>
                                <div className="dashboard-row flex-row">
                                    <IssueTransactionVolumeWidget />
                                    <TopIssuersWidget onListItemClick={redirectToIssuanceMonitor} />
                                </div>
                                <div className="dashboard-row flex-row">
                                    <IssuanceSpreadWidget withSlider />
                                    <TopArrangersWidget />
                                </div>
                                <div className="dashboard-row flex-row">
                                    <UsBslEsgNewIssueWidget />
                                    <TopTrusteesWidget />
                                </div>
                                <div className="dashboard-row flex-row">
                                    <UsBslEuCompliantDealsWidget />
                                    <TopStaticDeals onListItemClick={redirectToIssuanceMonitor} />
                                </div>
                                <div className="dashboard-row flex-row">
                                    <ShortLongDatedDealsWidget
                                        startDate={pipelineRequestCriteria.shortLongDatedDeals.startDateDashboard}
                                    />
                                    <TopDebutDeals onListItemClick={redirectToIssuanceMonitor} />
                                </div>
                                <div className="dashboard-row flex-row">
                                    <UsBslNewIssueWeeklySpreads />
                                </div>
                            </ShowFor>

                            <h1 ref={setAnchorRef('my-portfolio')}>My Portfolio</h1>
                            <div className="dashboard-row flex-row">
                                <PortfolioWidget />
                                <MyScheduledBwicsWidget />
                                <BoxPxTalkAndColorWidget />
                            </div>
                        </IntersectionObserverProvider>
                    }
                </Preloader>
            </div>
        </div>
    )
}

export const Dashboard = withRouter(DashboardComponent);
