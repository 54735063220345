import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Popup } from '../../controls';
import { user } from '../../../user';
import { inviteToJoinActions } from '../../../actions';
import { errorMessages } from '../../../constants';

export function InviteToJoinPopup() {
    const messageMaxLength = 1024;
    const currentUser = user.current();
    const defaultMessageText = `${currentUser.firstName} ${currentUser.lastName} invites you to join the KTX ATS Platform.`;
    const [message, setMessage] = useState(defaultMessageText);
    const [messageError, setMessageError] = useState('');
    const dispatch = useDispatch();
    const visible = useSelector(s => s.inviteToJoin.inviteToJoinPopupVisible);

    useEffect(() => {
        if (!visible) {
            setMessage(defaultMessageText);
            setMessageError('');
        }
    }, [visible, defaultMessageText]);

    if (!visible) {
        return null;
    }

    const handleClose = () => {
        dispatch(inviteToJoinActions.setVisiblePopup(false));
    };

    const handleSend = () => {
        if (message.length && !messageError) {
            dispatch(inviteToJoinActions.sendInviteToJoin(message))
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (value.length > messageMaxLength) {
            setMessageError(errorMessages.maxLength(messageMaxLength))
        } else {
            setMessageError('')
        }
        setMessage(value);
    };

    const disabledSendButton = !message.length || messageError;

    return (
        <Popup
            title={<>Invite broker-dealers to join<br />the KTX ATS Platform</>}
            onClose={handleClose}
            modalClass="modal-invite-broker"
        >
            <div className="modal-body">
                <div className="form-row">
                    <label className="form-label">Message</label>
                    <div>
                        <textarea
                            className={classNames("form-control", { "grey": !message.length })}
                            value={message}
                            onChange={handleChange}
                            maxLength={messageMaxLength + 1}
                        />
                        {messageError && <div className="form-error">{messageError}</div>}
                    </div>
                </div>
             </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-ghost" onClick={handleClose}>Cancel</button>
                <button
                    type="button"
                    className="btn btn-main"
                    disabled={disabledSendButton}
                    onClick={handleSend}
                >
                    Send
                </button>
            </div>
        </Popup>
    )
}
