import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAllToAllAgreementBlocked } from '../../../effects/useAllToAllAgreementBlocked';
import { ScheduledBwic } from '../../../types/dashboard/ScheduledBwic';
import { DashboardSellBuyTabs } from '../../../types/dashboard/SellBuyTabs';
import { Tabs } from './Tabs';
import { MyBwics } from './MyBwics';
import { BidAsDealerRequests } from './BidAsDealerRequests';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { AppState } from '../../../types/state/AppState';
import { RequestState } from '../../../constants/request-state';
import { DashboardWidgetLoadContentError } from '../widget/DashboardWidgetLoadContentError';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RequiredFeature } from '../../access/RequiredFeature';
import { useMyBidRequests } from './useMyBidRequests';
import { useScheduledBwics } from '../hooks/useScheduledBwics';

export function MyScheduledBwicsWidget() {
    const [activeTab, setActiveTab] = useState(DashboardSellBuyTabs.Sell);
    const requestStateBidAsDealerRequests = useSelector((state: AppState) => state.dashboard.requestStateBidAsDealerRequests);
    const { myBwics, requestStateFetchBwics } = useScheduledBwics();

    const blocked = useAllToAllAgreementBlocked();

    const isBd = user.hasRoles(...roles.bd());
    const isBdTrader = user.hasRoles(roles.BrokerDealerTrader);
    const isSeller = user.hasRoles(...roles.seller());
    const currentUser = user.current();

    useMyBidRequests(!((isBdTrader && !blocked) || isSeller));

    const renderBidAsDealerRequests = () =>
        requestStateBidAsDealerRequests === RequestState.failure
            ? <DashboardWidgetLoadContentError />
            : (
                <RequiredFeature feature={SubscriptionFeature.getBidAsDealerRequests}>
                    <BidAsDealerRequests />
                </RequiredFeature>
            );

    const renderMyBwics = (myBwics: ScheduledBwic[], hideCreateNewBwic: boolean) =>
        requestStateFetchBwics === RequestState.failure
            ? <DashboardWidgetLoadContentError />
            : <MyBwics myBwics={myBwics} requestStateFetchBwics={requestStateFetchBwics} hideCreateNewBwic={hideCreateNewBwic}/>;

    const renderContent = () => {
        if (isSeller) {
            return (
                <>
                    <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
                    {activeTab === DashboardSellBuyTabs.Buy ? renderBidAsDealerRequests() : renderMyBwics(myBwics, false)}
                </>
            )
        }
        if (isBd && !blocked && !currentUser?.isClearingBank) {
            return (
                <>
                    <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
                    {renderMyBwics(activeTab === DashboardSellBuyTabs.Buy
                        ? myBwics.filter(b => b.isParticipant)
                        : myBwics.filter(b => b.isMy),
                        activeTab === DashboardSellBuyTabs.Buy
                    )}
                </>
            )
        }
        return renderMyBwics(myBwics, true);
    };

    const chartName = "My Scheduled BWICs";

    return (
        <DashboardWidget className="container-flex upcoming-bwics">
            <DashboardWidgetHeader>
                <h3>{chartName}</h3>
            </DashboardWidgetHeader>
            <DashboardWidgetContent>
                {renderContent()}
            </DashboardWidgetContent>
        </DashboardWidget>
    )
}
