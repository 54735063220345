import React from 'react';

export function DashboardSkeleton({ inProgress = false, children }) {
    if (inProgress) {
        return (
            <div className="skeleton">
                <div className="skeleton-heading">
                    <div className="skeleton-element skeleton-title"></div>
                    <div className="flex-item-right">
                        <div className="skeleton-element skeleton-title-element"></div>
                        <div className="skeleton-element skeleton-title-element"></div>
                    </div>
                </div>
                <div className="skeleton-element skeleton-cnt"></div>
            </div>
        );
    }

    return children;
}
