import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { TopChartCompany } from "../../../../types/dashboard/TopChartCompany";
import { TopFilterTypes } from "../../../../types/dashboard/RedirectFilterTypes";
import { PipelineWidgetColumn } from "../../../../types/dashboard/PipelineWidgetColumn";
import { moneyUtils } from "../../../../utils";
import { ManagerLink } from "../../../amrPipeline/common/ManagerLink";
import { FilterLinkIcon } from '../../../amrPipeline/common/FilterLinkIcon';
import { ImSubscriptionActionBlocker } from '../../../amrPipeline/subscription/ImSubscriptionActionBlocker';

const buyColumnsDefinitions: IColumnDefinition<TopChartCompany>[] = [
    {
        columnKey: PipelineWidgetColumn.company,
        renderColumnHeaderContent: () => "Issuers",
        renderColumnContent: ({ company }) => (
            <ManagerLink
                referenceName={company.referenceName}
                legalName={company.legalName}
            />
        ),
        headerClassName: "data-list-cell-md-flexible",
        bodyClassName: "data-list-cell-md-flexible",
        sortingField: PipelineWidgetColumn.company,
    },
    {
        columnKey: PipelineWidgetColumn.redirectToIm,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ company }, context) =>
            <ImSubscriptionActionBlocker>
                {blocked => (
                    <FilterLinkIcon
                        referenceName={company.referenceName}
                        legalName={company.legalName}
                        code={company.code}
                        filter={TopFilterTypes.issuer}
                        onClick={context.onListItemClick}
                        tooltip="All Manager’s Transactions"
                        disabled={blocked}
                    />
                )}
            </ImSubscriptionActionBlocker>,
        headerClassName: "data-list-cell-xxxs",
        bodyClassName: "data-list-cell-xxxs",
    },
    {
        columnKey: PipelineWidgetColumn.dealBalanceTotal,
        renderColumnHeaderContent: () => "Size",
        renderColumnContent: ({ dealBalanceTotal }, context) =>
            `${context.currency} ${moneyUtils.amountToRoundedMM(dealBalanceTotal, true)}`,
        headerClassName: "data-list-cell-sm-01 text-right",
        bodyClassName: "data-list-cell-sm-01 text-right",
        sortingField: PipelineWidgetColumn.dealBalanceTotal,
    },
    {
        columnKey: PipelineWidgetColumn.emptySize, // Empty column if there is no "Size" column
        renderColumnHeaderContent: () => "",
        renderColumnContent: () => "",
        headerClassName: "data-list-cell-sm-01 text-right",
        bodyClassName: "data-list-cell-sm-01 text-right",
    },
    {
        columnKey: PipelineWidgetColumn.numberOfTransactions,
        renderColumnHeaderContent: () => "Trans",
        renderColumnContent: ({ numberOfTransactions }) => numberOfTransactions,
        headerClassName: "data-list-cell-xs text-right",
        bodyClassName: "data-list-cell-xs text-right",
        sortingField: PipelineWidgetColumn.numberOfTransactions,
    },
];
export const topIssuersColumns = buyColumnsDefinitions.map((c) => {
    return new ColumnBuilder(c);
});
