import { News } from "../../../types/news/News";
import {
    NewsCategory,
    newsCategoryTitles,
} from "../../../types/news/enums/NewsCategory";
import {
    NewsLabel,
    newsLabelTitles,
} from "../../../types/news/enums/NewsLabel";
import { NewsProvider, newsProviderLogos, newsProviderTitles } from "../../../types/news/enums/NewsProvider";
//import cloImage from "../../../images/clo.png";
import { Link } from "react-router-dom";
import { routes } from "../../../constants";
import { OnHoverTooltip } from "../../common";
import { NewsAdditionalSection } from "../../news/news-list/NewsItem";
import classNames from 'classnames';
import { ActionBlocker } from "../../access/ActionBlocker";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

interface Props {
    newsItem: News;
}

export const KWatchNewsListItem = ({ newsItem }: Props) => {
    const { category, label, provider, title, referenceName } = newsItem;

    const renderTopSection = () => (
        <div className="flex-row news-controls">
            {category !== NewsCategory.None && (
                <div className="news-controls-item">
                    <div className={classNames("label-status label-status-news-category", `label-status-${newsCategoryTitles[category].toLowerCase().replace(' ', '-')}`)}>
                        {newsCategoryTitles[category]}
                    </div>
                </div>
            )}

            {label !== NewsLabel.None && (
                <div className="news-controls-item">
                    <div className="news-label text-sm">
                        {newsLabelTitles[label]}
                    </div>
                </div>
            )}

            {provider && provider !== NewsProvider.None && (
                <div className="news-controls-item">
                    <div className="news-provider-clo">
                        <div className={classNames({
                            "news-provider-london-stock-exchange-group": NewsProvider.LondonStockExchangeGroup === provider,
                            "news-provider-clo-img": NewsProvider.CLOResearchGroup === provider
                        })}>
                            <img src={newsProviderLogos[provider]} alt={newsProviderTitles[provider]} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="news-item">
            {renderTopSection()}
            <div className="news-item-title">
                <OnHoverTooltip overlay={title}>
                    <ActionBlocker feature={SubscriptionFeature.KWatchNews} noTooltip>
                        {blocked => (
                            <Link
                                to={routes.newsUrl(referenceName)}
                                target="_blank"
                                className={classNames('btn-link', { disabled: blocked })}
                            >
                                <span className="text-ellipsis">{title}</span>
                            </Link>
                        )}
                    </ActionBlocker>
                </OnHoverTooltip>
            </div>
            <NewsAdditionalSection newsItem={newsItem} />
        </div>
    );
};
