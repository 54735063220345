import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealersActions } from '../../actions';
import { AppState } from '../../types/state/AppState';
import { Preloader, SearchInput, OnHoverTooltip } from '../common';
import { isRequestSuccess } from '../../utils';
import { dealersSelector, isRequestedInitialData } from '../../selectors/dealers.selector';
import { DealerList } from '../dealer-list/DealerList';
import { InviteToJoinAllButton } from './inviteToJoin/InviteToJoinAllButton';
import { companyStatuses, roles, routes } from '../../constants';
import { FeatureButton } from '../access/FeatureButton';
import { useDealersFilter } from '../../effects/useDealersFilter';
import { FeatureLink } from '../access/FeatureLink';
import IconSVG from '../../styles/svg-icons';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { Stats } from '../allBWICS/Stats';
import { DealerListFilter } from '../dealer-list/DealerListFilter';
import { DealerListFilterOption } from '../../types/state/DealerListState';
import { useBrokerDealerContacts } from '../../effects/useBrokerDealerContacts';
import { useBrokerDealerUsers } from '../../effects/useBrokerDealerUsers';
import { useBrokerDealers } from '../../effects/useBrokerDealers';
import { CompanyStatus } from '../../types/company/CompanyStatus';
import { useSettlementAgentAgreements } from '../../effects/useSettlementAgentAgreements';
import { useFavorites } from '../../effects/useFavorites';
import { ConfirmRequestToBid } from './ConfirmRequestToBidPopup';
import { TooltipPlacement } from '../common/Tooltip';
import { user } from '../../user/user';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

export const Dealers: React.FC = () => {
    const dispatch = useDispatch();
    const brokerDealers = useBrokerDealers();
    const brokerDealerContacts = useBrokerDealerContacts().items;
    const brokerDealerUsers = useBrokerDealerUsers().items;
    const favoritesState = useFavorites();
    const favoriteDealers = favoritesState.favorites;
    const agreementsState = useSettlementAgentAgreements();
    const settlementAgentAgreements = agreementsState.items;
    const disabledInviteAllButton = useSelector((s: AppState) => s.inviteToJoin.disabledInviteAllButton);
    const searchTerm = useSelector((state: AppState) => state.dealers.filter.searchTerm);
    const filterOptions = useSelector((state: AppState) => state.dealers.filter.filterOptions);
    const dealerList = useSelector(dealersSelector);
    const isRequestingBrokerDealers = useSelector(isRequestedInitialData);
    const isSellerTrader = user.hasRoles(roles.SellerTrader);
    const isLoading = !(isSellerTrader
        ? isRequestingBrokerDealers && isRequestSuccess(
            agreementsState.requestState,
            favoritesState.requestState,
            brokerDealers.requestState
        ) : isRequestingBrokerDealers && isRequestSuccess(brokerDealers.requestState));

    useEffect(() => {
        return () => {
            dispatch(dealersActions.reset());
            dispatch(dealerListActions.reset());
        };
    }, [dispatch]);

    const { dealers } = useDealersFilter(dealerList, brokerDealerContacts, brokerDealerUsers, searchTerm, filterOptions, favoriteDealers);

    const inviteCompanyButtonDisabledState = useMemo(() => (disabledInviteAllButton || !dealers.length || !dealers.some(dealer => {
        const dealerContacts = brokerDealerContacts.filter(c => c.companyId === dealer.id);
        const isOffPlatform = dealer.status !== companyStatuses.active.statusCode;
        const hasNotInvitedParticipant = dealerContacts && dealerContacts.some(c => !c.invitationSent);
        const distributionListInvitationNotSent = isOffPlatform && dealer.distributionList && !dealer.distributionListInvitationSent;
        const headOfTradingInvitationNotSent = isOffPlatform && dealer.headOfTrading && !dealer.headOfTradingInvitationSent;
        return hasNotInvitedParticipant || distributionListInvitationNotSent || headOfTradingInvitationNotSent;
    })), [disabledInviteAllButton, dealers, brokerDealerContacts]);

    const canSendRequestToBid = useMemo(() =>
        dealers.some(c =>
            c.isClearingBank &&
            c.status === CompanyStatus.active &&
            !settlementAgentAgreements.some(d => d.settlementAgent.id === c.id)
        ),
        [dealers, settlementAgentAgreements]
    );

    const handleRequestAllDealers = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(dealersActions.showBidRequestConfirmPopup(null))
    };

    const handleAddContact = () => {
        dispatch(dealerListActions.setEditableContact(
            {
                id: 0,
                companyId: 0,
                firstName: '',
                lastName: '',
                role: '',
                email: '',
                phone: '',
                readonly: false,
                deleted: false,
                invitationSent: false
            }, '')
        )
    };

    const handleFilterChange = (option: DealerListFilterOption) => {
        dispatch(dealersActions.updateFilterOptions(option));
        dispatch(dealerListActions.expandDealers({}, true));
    };

    return (
        <div className="container address-book">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Dealers</h1>
                    <SearchInput
                        placeholder="Search contact or company"
                        value={searchTerm}
                        onChange={(value: string) => dispatch(dealersActions.setSearchTerm(value))}
                        onClear={() => dispatch(dealersActions.setSearchTerm(''))}
                        disabled={!dealerList.length}
                        maxLengthValue={128}
                    />
                    <div className="flex-item-right flex-row controls">
                        <div className="flex-row buttons-with-tooltips">
                            <InviteToJoinAllButton disabled={inviteCompanyButtonDisabledState} />
                            <OnHoverTooltip overlay="Invite all your dealers to join the KTX ATS Platform" placement="bottomRight">
                                <IconSVG name="info" width={16} height={16} />
                            </OnHoverTooltip>
                            <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                                {(blocked: boolean) => (
                                    <OnHoverTooltip
                                        overlay="The DirectBidding request has been successfully submitted to Settlement Agent."
                                        disabled={canSendRequestToBid || blocked || !dealers.length}
                                        placement={TooltipPlacement.BottomRight}
                                    >
                                        <FeatureButton
                                            className="btn btn-main request-all-dealers btn-no-uppercase"
                                            disabled={!canSendRequestToBid}
                                            onClick={handleRequestAllDealers}
                                        >
                                            REQUEST DirrectBidding
                                        </FeatureButton>
                                    </OnHoverTooltip>
                                )}
                            </RoleActionBlocker>
                            <OnHoverTooltip
                                overlay="Request approval from Settlement Agent to receive and submit direct bids."
                                placement="bottomRight"
                            >
                                <IconSVG className="margin-r-0" name="info" width={16} height={16} />
                            </OnHoverTooltip>
                        </div>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row">
                    <div className="filters-area flex-row align-items-flex-end">
                        <DealerListFilter
                            disabled={!dealerList.length}
                            filterOptions={filterOptions}
                            onFilterChange={handleFilterChange}
                        />
                    </div>
                    <div className="flex-item-right controls">
                        <Stats />
                        <FeatureLink className="btn-link" path={routes.editContacts}>
                            <IconSVG name="user-edit" width={16} height={16} /> Edit All
                        </FeatureLink>
                        <FeatureButton
                            id="addContact"
                            className="btn-link"
                            onClick={handleAddContact}
                        >
                            <IconSVG name="addParticipant" width={16} height={16} />
                            Add Contact
                        </FeatureButton>
                    </div>
                </div>
            </div>
            <Preloader inProgress={isLoading}>
                {
                    !isLoading &&
                    <DealerList
                        dealers={dealers}
                        contacts={brokerDealerContacts}
                        users={brokerDealerUsers}
                        enableContactOptions={true}
                        enableRequestToBid={true}
                        noResultsFoundMessage={!dealerList.length ? 'There are no broker-dealer companies.' : undefined}
                    />
                }
            </Preloader>
            <ConfirmRequestToBid />
        </div>
    )
};
