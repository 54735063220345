import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { UsBslNewIssueWeeklySpread } from './UsBslNewIssueWeeklySpread';

export function UsBslNewIssueWarfWidget() {
    return (
        <UsBslNewIssueWeeklySpread
            divId="us-bsl-new-issue-spreads-warf-chart"
            title="US BSL New Issue WARF"
            field="warf"
            feature={SubscriptionFeature.UsBslNewIssueWarfGraph}
        />
    );
}
