import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Rating } from "../../../types/enums/Rating";
import { RatingColorLabel } from "../RatingColorLabel";
import { isRequesting } from '../../../utils';
import { AppState } from '../../../types/state/AppState';
import {
    RatingGroup,
    ratingGroupValues,
    RatingWithGroup, ratingWithGroupValues
} from '../../../types/dashboard/RatingGroup';
import { constants } from '../../../constants';
import { ratingColors } from '../../../constants/dashboard/ratings';
import { ChartBarScheduledBwics } from './ChartBarScheduledBwics';
import { ChartDataYValues } from './ChartBarScheduledBwics';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { useNextBusinessDates } from '../../../effects/data-accessors/useNextBusinessDates';
import { useScheduledBwics } from '../hooks/useScheduledBwics';

export function BarScheduledBwicsWidget() {
    const filter = useSelector((s: AppState) => s.dashboard.filter);
    const { requestStateFetchNextBusinessDates, nextBusinessDates } = useNextBusinessDates(moment());
    const { bwics, requestStateFetchBwics } = useScheduledBwics();
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);

    const { selectedRatings, selectedCurrencies } = filter;
    const getMoney = (number: number) => number / 1000000 > 1 ? (number / 1000000).toFixed(0) : number / 1000000;
    const getDataForBarChart = useMemo(() => {
        let maxValue = 0;
        const ratingGroupOtherValues = ratingGroupValues[RatingGroup.OTHER];
        const x = nextBusinessDates.map(date => moment.utc(date).startOf('day').format(constants.dashboardDateFormat));
        const y = ratingWithGroupValues.reduce((array: ChartDataYValues[], current: RatingWithGroup) => {
            if (selectedRatings.length && selectedRatings.indexOf(current) === -1) {
                return array;
            }
            array.push({
                name: current,
                color: ratingColors[String(current)],
                values: x.map(() => 0),
                text: []
            });
            return array;
        }, []);

        const filteredBwics = bwics.filter(bwic => {
            const date = moment.utc(bwic.bidsDueDateUtc).local();
            const dataIndex = x.indexOf(date.format(constants.dashboardDateFormat));
            return bwic.securities && bwic.securities.length && dataIndex > -1;
        });

        filteredBwics.forEach(bwic => {
            const { securities } = bwic;
            const date = moment.utc(bwic.bidsDueDateUtc).local();
            const dataIndex = x.indexOf(date.format(constants.dashboardDateFormat));
            for (let i = 0; i < securities.length; i++) {
                const security = securities[i];
                if (selectedCurrencies.length && selectedCurrencies.indexOf(security.currency) === -1) {
                    continue;
                }
                for (let k = 0; k < y.length; k++) {
                    const yItem = y[k];
                    if (security.rating === yItem.name || (RatingGroup.OTHER === yItem.name && ratingGroupOtherValues.indexOf(security.rating) !== -1)) {
                        if (yItem.values[dataIndex]) {
                            yItem.values[dataIndex] = yItem.values[dataIndex] + security.size;
                        } else {
                            yItem.values[dataIndex] = security.size || 0;
                        }
                    } else if (!yItem.values[dataIndex]) {
                        yItem.values[dataIndex] = 0;
                    }
                    if (maxValue < yItem.values[dataIndex]) {
                        maxValue = yItem.values[dataIndex];
                    }
                    yItem.text[dataIndex] = (`${yItem.name === RatingGroup.OTHER ? 'Other' : yItem.name} ${getMoney(yItem.values[dataIndex])}MM`);
                }
            }
        });
        return { x, y, maxValue }
    }, [nextBusinessDates, bwics, selectedCurrencies, selectedRatings]);

    const renderMaximized = useMemo(() => (
        <ChartBarScheduledBwics chartData={getDataForBarChart} selectedCurrencies={selectedCurrencies} />
    ), [getDataForBarChart, selectedCurrencies]);

    const title = "Scheduled BWICs";

    return (
        <DashboardWidget filterActive={filterActive} title={title} className="bars">
            <DashboardSkeleton
                inProgress={
                    isRequesting(requestStateFetchBwics) ||
                    isRequesting(requestStateFetchNextBusinessDates)
                }
            >
                <DashboardWidgetHeader>
                    <h3>{title}</h3>
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestStateFetchBwics}>
                    {renderMaximized}
                    <RatingColorLabel ratings={[Rating.AAA, Rating.AA, Rating.A, Rating.BBB, Rating.BB, Rating.B, Rating.NR, 'OTHER']} />
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
