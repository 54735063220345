import React, { useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Pagination } from '../tables/Pagination';
import { Preloader } from '../../common';
import { isRequestFailed, isRequesting, isRequestSuccess, moneyUtils } from '../../../utils';
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { InventoryPosition } from '../../../types/inventory/InventoryPosition';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { constants, routes } from '../../../constants';
import { InventoryPositionStatus } from '../../../types/inventory/InventoryPositionStatus';
import { useHistory } from 'react-router';
import { DashboardNoData } from '../DashboardNoData';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import {
    dashboardGetPortfolioInventorySecuritiesRequest,
    dashboardPortfolioInventorySortParamsChanged,
    dashboardSetPortfolioInventoryCurrentPage
} from '../../../actions';
import { user } from '../../../user';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import {
    InventorySecurityOrderByColumn,
    InventorySecuritySortOrder
} from '../../../types/inventory/InventorySecurityOrderByColumn';
import { RequiredFeature } from '../../access/RequiredFeature';
import { PortfolioAgreementDisclaimerActionBlocker } from '../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerActionBlocker';
import { CreatePortfolioButton } from '../../portfolio-agreement-disclaimer/CreatePortfolioButton';
import { Link } from '../../controls/Link';

const inventorySecuritiesItemsOnPage = 7;

export const DashboardPortfolioInventorySecurities: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const portfolioExist = useSelector((s: AppState) => s.dashboard.portfolioExist);
    const {
        currentPage,
        securities,
        totalRecordNumber,
        requestStateGetSecurities,
        sortField,
        sortOrder,
    } = useSelector((s: AppState) => s.dashboard.portfolioInventory);

    const items = securities[currentPage];

    useEffect(() => {
        if (
            !securities[currentPage] &&
            !isRequesting(requestStateGetSecurities) &&
            !isRequestFailed(requestStateGetSecurities) &&
            user.hasFeatures(SubscriptionFeature.viewIncludedToPortfolioInventory)
        ) {
            dispatch(
                dashboardGetPortfolioInventorySecuritiesRequest(
                    currentPage,
                    inventorySecuritiesItemsOnPage,
                    InventorySecurityOrderByColumn[sortField as keyof typeof InventorySecurityOrderByColumn],
                    InventorySecuritySortOrder[sortOrder as keyof typeof InventorySecuritySortOrder]
                )
            )
        }
    }, [dispatch, currentPage, securities, requestStateGetSecurities, sortField, sortOrder])

    const handlePageChanged = (page: number) => {
        dispatch(dashboardSetPortfolioInventoryCurrentPage(page))
    }

    const getColumns = () => {
        const columnsDefinitions: IColumnDefinition<InventoryPosition>[] = [
            {
                columnKey: 'status',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => <>{entity.status === InventoryPositionStatus.added &&
                    <span className="state-new">NEW</span>}</>,
                headerClassName: 'data-list-status',
                bodyClassName: 'data-list-status',
            }, {
                columnKey: 'date',
                renderColumnHeaderContent: () => 'Last Updated',
                renderColumnContent: entity => entity.lastUpdateDate ? moment.utc(entity.lastUpdateDate).local().format(constants.dashboardDateFormat) : constants.emptyPlaceholder,
                headerClassName: 'data-list-updated sort-column',
                bodyClassName: 'data-list-updated',
                sortingField: 'lastUpdateDate',
            }, {
                columnKey: 'ticker',
                renderColumnHeaderContent: () => 'Ticker',
                renderColumnContent: entity => entity.ticker,
                headerClassName: 'data-list-ticker',
                bodyClassName: 'data-list-ticker',
                sortingField: 'ticker'
            }, {
                columnKey: 'size',
                renderColumnHeaderContent: () => 'Size, MM',
                renderColumnContent: entity => moneyUtils.amountToMM(entity.size),
                headerClassName: 'data-list-size flex-item-left text-right',
                bodyClassName: 'data-list-size flex-item-left text-right',
                sortingField: 'size'
            }, {
                columnKey: 'view',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <Link
                        to={`${routes.inventory}?isinCusipsAndTickers=${entity.ticker}`}
                        className="btn btn-sm btn-ghost"
                        disabled={entity.status === InventoryPositionStatus.traded}
                        target="_blank"
                    >
                        View
                    </Link>
                ),
                headerClassName: 'flex-item-right',
                bodyClassName: 'flex-item-right',
            },
        ]
        return columnsDefinitions.map(c => new ColumnBuilder(c))
    };

    const handleClick = () => history.push(routes.newPortfolio);

    if (isRequestSuccess(requestStateGetSecurities) && !totalRecordNumber) {
        return (
            <div className="emptyBwics">
                <DashboardNoData
                    imgName="no-portfolios-small"
                    imgWidth={164}
                    imgHeight={128}
                    text={portfolioExist ? 'There are no securities in Dealer Inventory from your Portfolio.' : 'There are no portfolios yet.'}
                >
                    {!portfolioExist && (
                        <div>
                            <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                                <CreatePortfolioButton className="btn btn-main" onClick={handleClick}>
                                    Create Portfolio
                                </CreatePortfolioButton>
                            </PortfolioAgreementDisclaimerActionBlocker>
                        </div>
                    )}
                </DashboardNoData>
            </div>
        )
    }

    const createSecurityCustomClassName = (inventory: InventoryPosition) => {
        return classNames({ 'sold-status': inventory.status === InventoryPositionStatus.traded })
    };

    const handleSortChange = (field: string, order: string) => {
        dispatch(dashboardPortfolioInventorySortParamsChanged(field, order))
    }

    return (
        <RequiredFeature feature={SubscriptionFeature.viewIncludedToPortfolioInventory}>
            <Preloader inProgress={!totalRecordNumber && isRequesting(requestStateGetSecurities)}>
                {totalRecordNumber && (
                    <Pagination
                        itemsOnPage={inventorySecuritiesItemsOnPage}
                        total={totalRecordNumber}
                        onPageChanged={handlePageChanged}
                        currentPage={currentPage}
                    >
                        <Preloader inProgress={isRequesting(requestStateGetSecurities)}>
                            <Table
                                dataItems={items}
                                columns={getColumns()}
                                createSecurityCustomClassName={createSecurityCustomClassName}
                                onSort={handleSortChange}
                                defaultSortBy={sortField}
                                defaultSortByDirection={sortOrder}
                            />
                        </Preloader>
                    </Pagination>
                )}
            </Preloader>
        </RequiredFeature>
    )
}
