import * as React from 'react';
import IconSVG from '../../styles/svg-icons';
import { ContextMenu } from '../controls';
import { ListBuilder } from '../../utils/ListBuilder';
import { roles } from '../../constants';

interface Props {
    canEditContact?: boolean;
    sending: boolean;
    disabled?: boolean;
    onSend: () => void;
    onEditContact?: () => void;
    onDeleteContact?: () => void;
    onMenuShow: () => void;
    onMenuHide: () => void;
    invitationSent?: boolean;
}

export const DealerContactOptions: React.FC<Props> = (
    {
        canEditContact = false,
        sending,
        disabled = false,
        onEditContact,
        onDeleteContact,
        onSend,
        onMenuShow,
        onMenuHide,
        invitationSent
    }
) => {
    const handleEditContact = () => {
        onEditContact && onEditContact();
    };

    const handleDeleteContact = () => {
        onDeleteContact && onDeleteContact();
    };

    const handleInviteToJoin = () => {
        if (!sending) {
            onSend();
        }
    };

    return (
        <ContextMenu
            disabled={disabled}
            customTargetComponent={
                <span className="btn-link">
                    <IconSVG name="more" width={16} height={16} />
                </span>
            }
            menuItems={new ListBuilder()
                .addWhen(() => canEditContact, {
                    text: 'Edit Contact',
                    action: handleEditContact
                })
                .addWhen(() => !invitationSent, {
                    text: 'Invite to Join',
                    requiredRoles: [roles.SellerTrader],
                    action: handleInviteToJoin
                })
                .addWhen(() => canEditContact, {
                    text: 'Delete Contact',
                    action: handleDeleteContact
                })
                .result()
            }
            onShow={onMenuShow}
            onHide={onMenuHide}
        />
    );
};
