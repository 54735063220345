import React from 'react';
import { Plot, mainConfig } from '../../common/charts/plotlyConfig';
import { chartBarSameDay } from '../../../constants/dashboard/chartBarSameDay';
import { chartUtils } from '../../../utils';

export function BarSameDayChart({ data, divId }) {
    const { chartLayout, yAxisRange, xAxisRange, tickFont, tickColor, dTick, zeroLineColor, annotationMargin, barColor, hoverlabel } = chartBarSameDay;

    const getChartData = () => [{
        x: data.map(d => d.periodFrom),
        y: data.map(d => d.amountPercentBySameDay),
        type: 'bar',
        hoverinfo: 'text',
        marker: {
            color: barColor,
        },
        text: data.map((d) =>
            d.amountPercentBySameDay > 0
                ? ` Date: ${d.periodToDisplay} <br /> Value %: ${d.amountPercentBySameDay} <br /> Volume: ${d.amountToDisplay}`
                : ''
        ),
        textposition: 'none',
    }];

    const chartData = getChartData();

    const annotations = data.map((d) => {
        return {
            x: d.periodFrom,
            y: Number(d.amountPercentBySameDay) + annotationMargin,
            xref: 'x',
            yref: 'y',
            text: `${d.amountPercentBySameDay} %`,
            arrowhead: 0,
            ax: 0,
            ay: 0
        };
    });

    const layout = {
        ...chartLayout,
        annotations,
        hoverlabel,
        xaxis: {
            tickcolor: tickColor,
            tickfont: tickFont,
            range: xAxisRange
        },
        yaxis: {
            range: yAxisRange,
            dtick: dTick,
            showgrid: true,
            mirror: 'ticks',
            showtickprefix: 'none',
            fixedrange: true,
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
            zerolinecolor: zeroLineColor,
        }
    };

    return (
        <Plot
            divId={divId}
            layout={layout}
            data={chartData}
            config={mainConfig}
            onInitialized={chartUtils.setCursorByDiv(divId, 'crosshair')}
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
        />
    );
}
