import classnames from 'classnames';
import { DashboardSkeleton } from "../DashboardSkeleton";
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from "../widget";
import { isRequesting } from "../../../utils";
import IconSVG from "../../../styles/svg-icons";
import { Link } from "react-router-dom";
import { routes } from "../../../constants";
import { KWatchNewsList } from "./KWatchNewsList";
import { hasKWatchNewsAccess } from "../../../utils/amr-pipeline.utils";
import { News } from '../../../types/news/News';
import { RequestState } from '../../../constants/request-state';

interface KWatchNewsWidgetProps {
    news: News[];
    requestState: RequestState;
    className?: string;
    onAllNewsClick?: () => void;
}

const title = "K-Watch News";

export const KWatchNewsWidget = ({ news, requestState, className = "double-size", onAllNewsClick }: KWatchNewsWidgetProps) => {
    if (!hasKWatchNewsAccess()) {
        return null;
    }

    return (
        <DashboardWidget title={title} className={classnames("k-news", { "k-news-auto": news.length <= 4 }, className)}>
            <DashboardSkeleton inProgress={isRequesting(requestState)}>
                <DashboardWidgetHeader>
                    <h3 className="news-item-title">{title}</h3>
                    <Link to={routes.newsUrl()} onClick={onAllNewsClick} className="btn-link flex-item-right" target="_blank">
                        <IconSVG name="go-to" width={16} height={16} />
                        All News
                    </Link>
                </DashboardWidgetHeader>
                <DashboardWidgetContent requestState={requestState}>
                    <KWatchNewsList data={news} />
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    );
};
