import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { lowerCase } from 'lodash';
import { isRequesting } from '../../../utils';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { AppState } from '../../../types/state/AppState';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { WeeklySpreadsChart } from './WeeklySpreadsChart';
import { DailyIssuanceSpread } from '../../../types/dashboard/IssuanceSpread';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ShowFor } from '../../access';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { DashboardNoData } from '../DashboardNoData';

interface UsBslNewIssueWeeklySpreadProps {
    divId: string;
    title: string;
    field: keyof Pick<DailyIssuanceSpread, 'was' | 'warf' | 'ds'>;
    feature: SubscriptionFeature;
}

export function UsBslNewIssueWeeklySpread({
    divId,
    field,
    title,
    feature,
}: UsBslNewIssueWeeklySpreadProps) {
    const usBslNewIssueSpreads = useSelector((state: AppState) => state.dashboard.usBslNewIssueSpreads);
    const requestStateUsBslNewIssueSpreads = useSelector((state: AppState) => state.dashboard.requestStateUsBslNewIssueSpreads);

    const withData = useMemo(() => usBslNewIssueSpreads?.some(spread => {
        const currentField = spread[field];
        if(!currentField) {
            return false;
        }

        const fieldValues = Object.values(currentField);
        return fieldValues.some(value => !!value);
    }), [usBslNewIssueSpreads, field]);

    const chart = useMemo(() =>
        <WeeklySpreadsChart
            spreads={usBslNewIssueSpreads}
            fieldSelector={(spread: DailyIssuanceSpread) => spread[field]}
            divId={divId}
        />,
        [usBslNewIssueSpreads, divId, field]);

    return (
        <DashboardWidget title={title} className="new-issue-chart-box">
            <DashboardSkeleton inProgress={isRequesting(requestStateUsBslNewIssueSpreads)}>
                <ChartRequiredFeature
                    feature={feature}
                    chartName={title}
                    blockedClassName={"restricted-placeholder-" + lowerCase(title).replace(/\s+/g, "-")}
                >
                    <DashboardWidgetHeader>
                        <h3>{title}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateUsBslNewIssueSpreads}
                        description={withData ?
                            <ShowFor feature={feature}>
                                All statistics are calculated for active deals.
                            </ShowFor>
                            : undefined
                        }
                    >
                        {withData ? (
                            chart
                        ) : (
                            <DashboardNoData
                                imgName="no-data-on-security-found"
                                imgWidth={164}
                                imgHeight={128}
                                text="No data available"
                            />
                        )}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
