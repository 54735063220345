import React from 'react';
import { DashboardNoData } from '../DashboardNoData';

export function DashboardWidgetLoadContentError() {

    return (
        <DashboardNoData
            imgName="difficulties-in-load-data"
            imgWidth={164}
            imgHeight = {128}
            text="There were difficulties loading the data. We are already working on this. Please try again later."
        />
    )
}
