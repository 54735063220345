import * as React from 'react';
import { ReactNode } from 'react';
import ImageSVG from '../../styles/svg';

interface Props {
    imgName?: string;
    imgWidth?: number;
    imgHeight?: number;
    title?: ReactNode;
    text?: ReactNode;
    children?: ReactNode;
}

export const DashboardNoData = ({ imgName, imgWidth, imgHeight, title, text, children }: Props) => (
    <div className="empty-placeholder empty-widget">
        {
            !!imgName &&
            <ImageSVG
                name={imgName}
                width={imgWidth ? String(imgWidth) : undefined}
                height={imgHeight ? String(imgHeight) : undefined}
            />
        }
        {!!title && <h2>{title}</h2>}
        {!!text && <p>{text}</p>}
        {children}
    </div>
);
