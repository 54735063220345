import React from 'react';
import moment from 'moment';
import { mainConfig, Plot } from '../../common/charts'
import { chartUtils } from '../../../utils';
import { aaaPriceLevelConstants } from '../../../constants/dashboard/aaaPriceLevel';
import { AAAPriceLevel } from '../../../types/dashboard/AAAPriceLevel';

interface MedianRatingAAAChartProps {
    chartData: AAAPriceLevel[];
}

export function AAAPriceLevelChart({ chartData }: MedianRatingAAAChartProps) {
    const chartDiv = 'triple-a-chart';

    const momentDates = chartData.map(d => moment(d.startDate));

    const formatDates = momentDates.map(md => md.format(aaaPriceLevelConstants.dateFormat));

    const shapes = chartData.map((d, index) => ({
            type: 'line',
            x0: formatDates[index],
            y0: Number(d.minAvgPriceTalk),
            x1: formatDates[index],
            y1: Number(d.maxAvgPriceTalk),
            line: aaaPriceLevelConstants.shapeLine
        }
    ));

    const trace1 = {
            x: formatDates,
            y: chartData.map(d => d.avgColor),
            text: chartData.map(data => `Color: ${data.avgColor}`),
            hoverinfo: 'text',
            mode: 'lines',
            type: 'scatter',
            name: 'avgColor',
            marker: aaaPriceLevelConstants.avgColorMarker
        };

    const trace2 = {
        x: formatDates,
        y: chartData.map(d => d.avgPriceTalk),
        text: chartData.map(data => `Price Talk: ${data.avgPriceTalk}`),
        hoverinfo: 'text',
        mode: 'lines',
        type: 'scatter',
        name: 'avgPriceTalk',
        marker: aaaPriceLevelConstants.avgPriceTalkMarker
    };

    const layout = {
        font: aaaPriceLevelConstants.layoutFont,
        showlegend: false,
        autosize: true,
        hovermode: 'closest',
        margin: aaaPriceLevelConstants.margin,
        autoscale: false,
        hoverlabel: aaaPriceLevelConstants.hoverlabel,
        xaxis: {
            showgrid: false,
            zeroline: false,
            tickcolor: aaaPriceLevelConstants.tickColor,
            tickfont: aaaPriceLevelConstants.tickFont,
            range: [moment.min(momentDates), moment.max(momentDates)]
        },
        yaxis: {
            titlefont: aaaPriceLevelConstants.titlefont,
            zerolinecolor: aaaPriceLevelConstants.tickColor,
            gridcolor: aaaPriceLevelConstants.tickColor,
            tickcolor: aaaPriceLevelConstants.tickColor,
            tickfont: aaaPriceLevelConstants.tickFont,
            autorange: true
        },
        shapes
    };

    return (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={chartDiv}
            data={[trace1, trace2]}
            layout={layout}
            config={mainConfig}
        />
    )
}
