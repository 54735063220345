import React from 'react';
import moment, { Moment } from 'moment';
import { flatten, sortedUniqBy } from 'lodash';
import { Plot, mainConfig } from '../../common/charts';
import { chartUsBslEsgNewIssue } from '../../../constants';
import { chartUtils, numericUtils } from '../../../utils';
import { arrayUtils } from '../../../utils/array.utils';
import { IssueTransactionVolume } from '../../../types/dashboard/IssueTransactionVolume';
import { ChartGrouping, ChartUnits, ChartView } from '../../../types/dashboard/AmrChart';
import { UsBslEsgNewIssueChartFilter } from './UsBslEsgNewIssueFilter';
import { amrChartUtils } from '../../../utils/amr-chart.utils';

interface UsBslEsgNewIssueChartProps {
    esgLanguageDeals: IssueTransactionVolume[];
    usBslNewIssue: IssueTransactionVolume[];
    filter: UsBslEsgNewIssueChartFilter,
    divId?: string;
}

type GroupedData = {
    [key: string]: number
};

const MaxRangeYears = 12;

const GroupingTickFormat: Partial<Record<ChartGrouping, any>> = {
    [ChartGrouping.M]: {
        tickformat: '%b `%y',
        dtick: 'M1'
    },
    [ChartGrouping.Q]: {
        tickformat: '%qQ `%y',
        dtick: 'M3'
    },
    [ChartGrouping.Y]: {
        tickformat: '%Y',
        dtick: 'M12'
    },
};

const MonthDateFormat = 'YYYY-MM';
const YearDateFormat = 'YYYY';

export function UsBslEsgNewIssueChart({ esgLanguageDeals, usBslNewIssue, filter, divId }: UsBslEsgNewIssueChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        barColors,
        zeroLineColor,
        hoverlabel,
    } = chartUsBslEsgNewIssue;

    const formatByGrouping = (date: Moment) => {
        switch (filter.grouping) {
            case ChartGrouping.Y:
                return date.format(YearDateFormat);
            case ChartGrouping.Q:
                // Group by first month of each quarter
                return `${date.year()}-${date.quarter() * 3 - 2}`;
            default:
                return date.format(MonthDateFormat);
        }
    };

    const getDimension = (row: IssueTransactionVolume) => {
        return moment.utc([row.year, row.month - 1]);
    };

    const getMetric = (row: IssueTransactionVolume) => {
        switch (filter.units) {
            case ChartUnits.Count:
                return row.numberOfTransactions;
            default:
                return row.dealBalanceTotal;
        }
    };

    const getRatioTrace = (uniqDimensions: string[], groupedEsgLanguageDeals: GroupedData, groupedUsBslNewIssue: GroupedData) => {
        const values = uniqDimensions.map(dimension => {
            const esgLanguageBalance = groupedEsgLanguageDeals[dimension] || 0;
            const usBslNewIssueBalance = groupedUsBslNewIssue[dimension] || 0;

            return esgLanguageBalance && usBslNewIssueBalance
                ? numericUtils.round((esgLanguageBalance / usBslNewIssueBalance) * 100)
                : 0;
        });

        return {
            x: uniqDimensions,
            y: values
        };
    };

    const groupData = (data: IssueTransactionVolume[]): GroupedData => {
        const map = arrayUtils.groupBy(
            data,
            (row: IssueTransactionVolume) => `${formatByGrouping(getDimension(row))}`
        );

        return Array.from(map.entries())
            .reduce((acc: GroupedData, [dimension, elements]) => {
                const metric = arrayUtils.sum(elements, (value: IssueTransactionVolume) => getMetric(value));

                return {
                    ...acc,
                    [dimension]: metric,
                };
            }, {});
    };

    const getTraceFromGroupedData = (data: GroupedData) => {
        return Object.entries(data)
            .reduce((acc: any, [dimension, metric]) => ({
                x: [...acc.x, dimension],
                y: [...acc.y, metric],
            }), { x: [], y: [] });
    };

    const getChartRange = (min: Moment, max: Moment) => {
        let rangeMin = min.clone();
        let rangeMax = max.clone();

        switch (filter.grouping) {
            case ChartGrouping.M:
                rangeMin = max.clone()
                    .subtract(11, 'months')
                    .subtract(15, 'days');
                rangeMax.add(15, 'days');
                break;
            case ChartGrouping.Q:
                rangeMin = max.clone()
                    .subtract(11, 'quarters')
                    .subtract(45, 'days');
                rangeMax.startOf('quarter')
                    .add(45, 'days');
                break;
            default: {
                rangeMin = max.clone()
                    .subtract(MaxRangeYears - 1, 'years')
                    .startOf('year')
                    .subtract(6, 'months');

                if (rangeMin.isBefore(min)) {
                    rangeMin = min.clone()
                        .startOf('year')
                        .subtract(6, 'months');
                }

                rangeMax.startOf('year')
                    .add(6, 'months');
            }
        }

        return [
            rangeMin.format(),
            rangeMax.format(),
        ];
    };

    const groupAll = (...args: IssueTransactionVolume[][]): [IssueTransactionVolume[], ...GroupedData[]] => {
        const grouped = args.map(groupData);

        const dimensions = flatten(args)
            .sort((a, b) => a.year - b.year
                ? a.year - b.year
                : a.month - b.month);

        return [
            sortedUniqBy(dimensions, x => `${x.year}-${x.month}`),
            ...grouped,
        ];
    };

    const getChartData = (dataByUniqueDimensions: IssueTransactionVolume[], ...grouped: GroupedData[]) => {
        const [
            groupedEsgLanguageDeals,
            groupedUsBslNewIssue,
        ] = grouped;

        const formattedDimensions = dataByUniqueDimensions.map(x => formatByGrouping(getDimension(x)));

        const ratioTrace = getRatioTrace(
            formattedDimensions,
            groupedEsgLanguageDeals,
            groupedUsBslNewIssue,
        );

        const esgLanguageDealsTrace = getTraceFromGroupedData(groupedEsgLanguageDeals);
        const usBslNewIssueTrace = getTraceFromGroupedData(groupedUsBslNewIssue);

        return [
            {
                ...esgLanguageDealsTrace,
                name: 'Deals with ESG Language',
                type: 'bar',
                hovertemplate: `Deals with ESG Language: %{y}<extra></extra>`,
                marker: {
                    color: barColors[0],
                },
            },
            {
                ...usBslNewIssueTrace,
                name: 'US BSL New Issue',
                type: 'bar',
                hovertemplate: `US BSL New Issue: %{y}<extra></extra>`,
                marker: {
                    color: barColors[1],
                },
            },
            {
                ...ratioTrace,
                type: 'scatter',
                mode: 'lines',
                marker: {
                    color: barColors[2],
                },
                yaxis: 'y2',
                hovertemplate: `% of Issuance with ESG: %{y}<extra></extra>`,
            }
        ];
    };

    const getLayout = (dataByUniqueDimensions: IssueTransactionVolume[]) => {
        const { min, max } = amrChartUtils.getEdgeDimensionValues(dataByUniqueDimensions, getDimension);
        const chartRange = getChartRange(min, max);
        const sliderRange = [min.format(), max.format()];
        const { dtick, tickformat } = GroupingTickFormat[filter.grouping];

        const tickprefix = filter.units === ChartUnits.Volume ? '$' : '';

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'x unified',
            hoverlabel,
            barmode: ChartView.Grouped,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                tickfont: tickFont,
                fixedrange: true,
                dtick,
                tickformat,
                range: chartRange,
                rangeslider: {
                    range: sliderRange,
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                tickprefix,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
            },
            yaxis2: {
                gridcolor: tickColor,
                tickfont: tickFont,
                zeroline: false,
                fixedrange: true,
                autorange: false,
                automargin: true,
                showgrid: false,
                showline: false,
                autotick: true,
                ticks: 'none',
                ticklabelposition: 'outside',
                side: 'right',
                range: [0, 100],
                overlaying: 'y',
                ticksuffix: '%',
            },
        }
    };

    const [
        dimensions,
        groupedEsgLanguageDeals,
        groupedUsBslNewIssue,
    ] = groupAll(esgLanguageDeals, usBslNewIssue);

    const data = getChartData(dimensions, groupedEsgLanguageDeals, groupedUsBslNewIssue);
    const layout = getLayout(dimensions);

    return (
        <>
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                divId={divId}
                data={data}
                layout={layout}
                config={mainConfig}
            />
            <div className="agenda">
                <div className="deals-with-esg-language">Deals with ESG Language</div>
                <div className="us-bsl-new-issue">US BSL New Issue</div>
                <div className="percent-of-issuance-with-esg">% of Issuance with ESG</div>
            </div>
        </>
    );
}

UsBslEsgNewIssueChart.defaultProps = {
    divId: 'us-bsl-esg-new-issue-chart'
};
