import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { lowerCase, capitalize } from 'lodash';
import { IssueTransactionVolumeChart } from './IssueTransactionVolumeChart';
import { IssueTransactionVolumeFilter, ChartFilter } from './IssueTransactionVolumeFilter';
import { isRequesting } from '../../../utils';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { AppState } from '../../../types/state/AppState';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { dashboardInitIssueTransactionVolume, dashboardIssueTransactionVolumeRequest } from '../../../actions';
import { Currency } from '../../../types/enums/Currency';
import { ChartGrouping, ChartUnits, ChartView } from '../../../types/dashboard/AmrChart';
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { constants, roles } from '../../../constants';
import { ShowFor } from '../../access';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';

interface IssueTransactionVolumeWidgetProps {
    defaultFilter?: Partial<ChartFilter>;
    withFilter?: boolean;
}

export function IssueTransactionVolumeWidget({
    defaultFilter = {},
    withFilter = true,
}: IssueTransactionVolumeWidgetProps) {
    const issueTransactionVolume = useSelector((state: AppState) => state.dashboard.issueTransactionVolume);
    const requestStateIssueTransactionVolume = useSelector((state: AppState) => state.dashboard.requestStateIssueTransactionVolume);

    const dispatch = useDispatch();

    const hasAccess = hasWidgetsAccess();

    const [filterState, setFilterState] = useState<ChartFilter>({
        transactionType: constants.allListItem,
        units: ChartUnits.Count,
        grouping: ChartGrouping.Y,
        view: ChartView.Grouped,
        currencyCode: Currency.USD,
        ...defaultFilter,
    });

    useEffect(() => {
        hasAccess && dispatch(dashboardInitIssueTransactionVolume());
    }, [dispatch, hasAccess]);

    const handleFilterChange = (filter: ChartFilter) => {
        setFilterState(filter);

        const { transactionType, currencyCode } = filter;

        hasAccess && dispatch(dashboardIssueTransactionVolumeRequest({
            transactionType,
            currencyCode,
        }));
    }

    const chart = useMemo(() =>
        <IssueTransactionVolumeChart
            data={issueTransactionVolume}
            filter={filterState}
        />,
        [issueTransactionVolume, filterState]);

    const chartName = `${capitalize(lowerCase(filterState.transactionType))} transaction ${filterState.units}`;

    return (
        <DashboardWidget title={chartName} className="double-size im-chart">
            <DashboardSkeleton inProgress={isRequesting(requestStateIssueTransactionVolume)}>
                <DashboardWidgetHeader>
                    <h3 className="text-capitalize">{chartName}</h3>
                    <ShowFor roles={roles.issuanceMonitorAccess()}>
                        {withFilter && <IssueTransactionVolumeFilter onChange={handleFilterChange} {...filterState} />}
                    </ShowFor>
                </DashboardWidgetHeader>
                <DashboardWidgetContent
                    requestState={requestStateIssueTransactionVolume}
                    description={
                        issueTransactionVolume?.length
                            ? 'All statistics are calculated based on the transaction pricing date.'
                            : ''
                    }
                >
                    {!!issueTransactionVolume?.length ? chart : <DashboardNoSelectedFilters />}
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
