import React from 'react';
import moment from 'moment';
import { Plot, mainConfig } from '../../common/charts';
import { constants, chartBarDnt } from '../../../constants';
import { numericUtils } from '../../../utils/numeric.utils';
import { chartUtils, formatUtils } from '../../../utils';
import { RatingWithGroup, ratingWithGroupValues } from '../../../types/dashboard/RatingGroup';
import { DntRate, DntRateData } from '../../../types/dashboard/DntRate';

interface BarDntChartProps {
    data: DntRate[];
    divId?: string;
    selectedCurrencies: string[];
}

interface DataToDisplay {
    percent: number;
    startDate: Date;
    endDate: Date;
    USD: number | null;
    EUR: number | null;
}

export function BarDntChart({ data, divId, selectedCurrencies }: BarDntChartProps) {
    const {
        chartLayout, tickColor, tickFont, barColors, xAxisRange, zeroLineColor, dTick, hoverlabel,
        yAxisLastLineMargin, defaultYRangeMaxValue, defaultDTick
    } = chartBarDnt;
    let maxValue = 0;

    const getTotalAmount = (dataForAmount: DntRateData[]) => dataForAmount.reduce((result, current) => {
        result += current.amount;
        return result;
    }, 0);

    const getPercentValue = (dntRates: DntRateData[], rating: RatingWithGroup) => {
        const ratingData = dntRates.filter(rate => rate.rating === rating);
        const ratingDataWithDnt = ratingData.filter(d => d.dnt);
        const amount = getTotalAmount(ratingData);
        const amountByDnt = getTotalAmount(ratingDataWithDnt);
        const dividedValue = numericUtils.divideSafe(amountByDnt, amount);
        return dividedValue ? dividedValue * 100 : 0;
    };

    const getChartData = () => {
        const x = ratingWithGroupValues;
        const y = data.map((rates, index) => {
            return {
                name: index,
                color: barColors[index],
                values: x.map((rating) => {
                    const percent = getPercentValue(rates.dntRates, rating);
                    if (percent > maxValue) {
                        maxValue = percent;
                    }
                    return percent;
                }),
                text: x.map((rating) => {
                    const ratingDataUSD = rates.dntRates
                        .filter(d => (d.currency === 'USD' && d.dnt && d.rating === rating));
                    const ratingDataEUR = rates.dntRates
                        .filter(d => (d.currency === 'EUR' && d.dnt && d.rating === rating));
                    const percent = getPercentValue(rates.dntRates, rating);
                    const amountUSD = selectedCurrencies.length
                        ? (selectedCurrencies.some(currency => currency === 'USD') ? getTotalAmount(ratingDataUSD) : null)
                        : getTotalAmount(ratingDataUSD);
                    const amountEUR = selectedCurrencies.length
                        ? (selectedCurrencies.some(currency => currency === 'EUR') ? getTotalAmount(ratingDataEUR) : null)
                        : getTotalAmount(ratingDataEUR);
                    return getBarText({
                        percent,
                        startDate: rates.startDate,
                        endDate: rates.endDate,
                        USD: amountUSD,
                        EUR: amountEUR
                    });
                }),
            };
        });

        return y.map(data => ({
            x,
            y: data.values,
            name: data.name,
            type: "bar",
            marker: {
                color: data.color,
            },
            hoverinfo: 'text',
            text: data.text || [],
            textposition: 'none',
        }));
    };

    const getBarText = (data: DataToDisplay) =>
        `DNT %: ${formatUtils.formatDecimal(data.percent, 2)} <br />` +
        `Last day of the range: ${moment.utc(data.endDate).format(constants.dashboardDateFormat)} <br />` +
        `Volume: ${data.USD == null ? '' : chartUtils.getMoneyString(data.USD, '$')}` +
        `${data.USD == null || data.EUR == null ? '' : ' / '}` +
        `${data.EUR == null ? '' : chartUtils.getMoneyString(data.EUR, '€')}`;

    const chartData = getChartData();

    const getYRange = () => {
        const max = chartUtils.roundToNumber(maxValue, dTick) || defaultYRangeMaxValue;
        const maxRange = max + numericUtils.getPercentage(max, yAxisLastLineMargin);
        return [0, maxRange]
    };

    const layout = {
        ...chartLayout,
        showlegend: false,
        barmode: "group",
        autosize: true,
        hovermode: 'closest',
        hoverlabel,
        xaxis: {
            range: xAxisRange,
            tickcolor: tickColor,
            tickfont: tickFont
        },
        yaxis: {
            showgrid: true,
            range: getYRange(),
            mirror: 'ticks',
            showtickprefix: 'none',
            fixedrange: true,
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
            zerolinecolor: zeroLineColor,
            dtick: maxValue ? ( maxValue < dTick ? dTick / defaultDTick : dTick) : defaultDTick
        },
    };

    const renderPoints = () => {
        return data.map((dntRates, index) => (
            <span key={index}>
                <em style={{ background: barColors[index] }}>&bull;</em>
                {moment(dntRates.endDate, 'YYYY-MM-DD').format(constants.dashboardDateFormat)}
            </span>
        ));
    };

    const renderChart = () => (
        <Plot
            onHover={chartUtils.setCursor('pointer')}
            onUnhover={chartUtils.setCursor('crosshair')}
            divId={divId}
            data={chartData}
            layout={layout}
            config={mainConfig}
        />
    );

    return (
        <>
            {renderChart()}
            <div className="dnt-points">{renderPoints()}</div>
        </>
    );
}

BarDntChart.defaultProps = {
    divId: 'bar-dnt-chart'
};
